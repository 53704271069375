import * as React from 'react';

const truncateText = (text: string) => {
  let newText = text.slice(0);
  if (text.length > 200) {
    newText = text.slice(0, 200);
    newText = newText.concat("...");
  }
  return newText;
};

export const TruncatedText = (text: string) => {
  return <span>{truncateText(text)}</span>;
};