import * as React from 'react'
import { HelpPanel, Icon } from '@amzn/awsui-components-react-v3';
export const infoPane = () => {

    return (
        <HelpPanel
            header={<h3>CRIPT Item</h3>}
            footer={
                <div>
                    <h3>
                        Learn more <Icon name="external" />
                    </h3>
                    <ul>
                        <li>
                            <a href="https://w.amazon.com/bin/view/ALTAR/tools/CRIPT/FAQs/" target='_blank'>CRIPT FAQs</a>
                        </li>
                        <li>
                            <a href="https://w.amazon.com/bin/view/CRIPTTool" target='_blank' >Export Compliance</a>
                        </li>
                    </ul>
                </div>
            }
        >
            <p>
                A CRIPT Item is an Amazon product or service, or any other software, hardware, or technology that could cross international borders and/or to foreign nationals within a country
            </p>
        </HelpPanel>
    );
}