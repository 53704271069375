import { SET_DIRTY, SET_DESTINATION } from './../actions/navigation.action';

const initialState = {
    isDirty: false,
    destination: null
};

export default function navigationReducer(state = initialState, action){
    switch (action.type) {
        case SET_DIRTY:
            return Object.assign({}, state, {isDirty: action.payload});
        case SET_DESTINATION: 
            return Object.assign({}, state, {destination: action.payload});
        default:
            return state;
    }
}