import * as React from 'react'
import { useState, useEffect } from 'react'
import { Button, Flashbar, FlashbarProps, Icon, TableProps, Spinner, BreadcrumbGroup } from '@amzn/awsui-components-react-v3';
import { KeywordModal } from "../components/keyword/KeywordModal";
import { getAllKeyword, addKeyword, getKeywordById, updateKeyword } from '../actions/async.action'
import { checkAdminStatus } from '../actions/authorization.action'
import moment from "moment"
import { Builder } from 'builder-pattern';
import { Column } from '../components/common/Table/Column';
import { CollectionTable } from '../components/common/Table/CollectionTable';
import { FlashNotification } from "../components/common/Notification/Notification"

export interface Keyword {
  Id: string;
  Name: string;
  Description: string;
  CreatedBy: string;
  CreationDate: Date;
  LastUpdatedBy: string;
  LastUpdatedDate: Date;
}

const breadcrumbItems = [
  {
    "text": "Home",
    "href": "#"
  },
  {
    "text": "Keywords",
    "href": "",
  },
]

const Keywords = () => {

  const [items, setItems] = useState<Keyword[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [isAltar, setIsAltar] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isVisibileKeywordModal, setIsVisibleKeywordModal] = useState(false)
  const [isVisibileUpdateKeywordModal, setIsVisibleUpdateKeywordModal] = useState(false)
  const [keywordToBeUpdated, setKeywordTobeUpdated] = useState<Keyword>()
  const [tableData, setTableData] = useState<Column<Keyword>[]>([])
  const [flashNotifications, setFlashNotifications] = useState<FlashNotification[]>([])

  useEffect(() => {
    getAllKeyword().then((res) => {
      setItems(res.data)
      setLoading(false)
    })
    checkAdminStatus().then((res) => {
      setIsAdmin(res.data)
    })
  }, [])

  const TableData = (items: Keyword[]) => {
    let columns: Column<Keyword>[] = [
      {
        key: "id",
        columnDefinition: Builder<TableProps.ColumnDefinition<Keyword>>()
          .id("id")
          .header("Id")
          .cell((item: Keyword) => item.Id)
          .sortingField("id")
          .build(),
        values: items.map((item) => item.Id),
        propertyFilter: false,
        visibilityEditable: true
      },
      {
        key: "Name",
        columnDefinition: Builder<TableProps.ColumnDefinition<Keyword>>()
          .id("Name")
          .header("Name")
          .cell((item: Keyword) => {
            return <a onClick={() => {
              getKeywordInfo(item.Id)
            }}>
              {item.Name}<Icon name="edit"/>
            </a>
          })
          .sortingField("Name")
          .build(),
        values: items.map((item) => item.Name),
        propertyFilter: true,
        visibilityEditable: true
      },
      {
        key: "Description",
        columnDefinition: Builder<TableProps.ColumnDefinition<Keyword>>()
          .id("Description")
          .header("Description")
          .cell((item: Keyword) => item.Description)
          .sortingField("Description")
          .build(),
        values: items.map((item) => item.Description),
        propertyFilter: false,
        visibilityEditable: true
      },
      {
        key: "CreatedBy",
        columnDefinition: Builder<TableProps.ColumnDefinition<Keyword>>()
          .id("CreatedBy")
          .header("Created By")
          .cell((item: Keyword) => item.CreatedBy)
          .sortingField("createdBy")
          .build(),
        values: items.map((item) => item.CreatedBy),
        propertyFilter: true,
        visibilityEditable: true
      },
      {
        key: "CreatedAt",
        columnDefinition: Builder<TableProps.ColumnDefinition<Keyword>>()
          .id("CreatedAt")
          .header("Created At")
          .cell((item: Keyword) => moment(item.CreationDate).format("YYYY-MM-DD LTS"))
          .sortingField("CreationDate")
          .build(),
        values: [],
        propertyFilter: false,
        visibilityEditable: true
      },
      {
        key: "LastUpdatedBy",
        columnDefinition: Builder<TableProps.ColumnDefinition<Keyword>>()
          .id("LastUpdatedBy")
          .header("Last Updated By")
          .cell((item: Keyword) => item.LastUpdatedBy)
          .sortingField("LastUpdatedBy")
          .build(),
        values: items.map((item) => item.LastUpdatedBy),
        propertyFilter: true,
        visibilityEditable: true
      },
      {
        key: "LastUpdatedAt",
        columnDefinition: Builder<TableProps.ColumnDefinition<Keyword>>()
          .id("LastUpdatedAt")
          .header("Last Updated At")
          .cell((item: Keyword) => moment(item.LastUpdatedDate).format("YYYY-MM-DD LTS"))
          .sortingField("LastUpdatedDate")
          .build(),
        values: [],
        propertyFilter: false,
        visibilityEditable: true
      }
    ]
    return columns;
  } 

  function reload() {
    getAllKeyword().then((res) => {
      setItems(res.data)
      setLoading(false)
    })
  }


  useEffect(() => {
    const columns = TableData(items);
    setTableData(columns);
    // eslint-disable-next-line 
  }, [items])

  function setNotification(notification: FlashNotification) {
    let notifications = flashNotifications.slice()
    notifications.push(notification)
    setFlashNotifications(notifications)
  }

  function dismissNotification(notification: string) {
    let notifications = flashNotifications.slice()
    let notificationIndex = notifications.findIndex((m) => m.message === notification)
    notifications.splice(notificationIndex, 1)
    setFlashNotifications(notifications)
  }

  function formatErrors(error) {
    if (error.response.data) {
      console.log(error.response.data)
      const errors = error.response.data.errors
      for (let i = 0; i < errors.length; i++) {
        setNotification({type: errors[i].type, message: errors[i].message})
      }
    }
  }

  function getKeywordInfo(keywordId) {
    getKeywordById(keywordId)
    .then((res) => {
      setKeywordTobeUpdated(res.data)
      setIsVisibleUpdateKeywordModal(true)
    })
    .catch((error) => {
      formatErrors(error)
    })
  }

  return (
    <div>
      {!isAdmin &&
        <div><Spinner />
          <p>If you are not a member of CRIPT Leadership, this page is not accessible. Click <a href='/#/'>here</a> to go to the home page</p>
        </div>
      }
      {isAdmin && (
        <div className="awsui-util-m-xl">
          <BreadcrumbGroup ariaLabel="Breadcrumbs" items={breadcrumbItems}></BreadcrumbGroup>
          
          {
            flashNotifications && flashNotifications.length > 0 && (
              <Flashbar 
                items={
                  flashNotifications.map((notification) => 
                    Builder<FlashbarProps.MessageDefinition>()
                    .type(notification.type as FlashbarProps.Type)
                    .content(notification.message)
                    .dismissible(true)
                    .onDismiss(() => { dismissNotification(notification.message) })
                    .build()
                  )
                }          
              />
            )
          }

          {items &&
            <CollectionTable 
              allItems={items} 
              tableData={tableData} 
              headerText={'Keywords'}            
              headerActions={
                <Button 
                  variant="primary"
                  onClick={() => setIsVisibleKeywordModal(true)}
                >
                  Create Keyword
                </Button>
              }
              defaultSortFieldId="LastUpdatedDate"
              defaultSortDescending={true}
              defaultHiddenColumns={["id"]}
              loading={loading}
            />
          }

          <KeywordModal 
            headerText='Create Keyword'
            keyword={null}
            primaryActionButtonText='Create'
            visible={isVisibileKeywordModal}
            onChange={(keyword: Keyword) => {
              addKeyword(keyword)
                .then((res) => {
                  setNotification({type: "success", message: `Successfully created keyword with name: ${res.data.Name}`})
                  reload()
                })
                .catch((error) => {
                  formatErrors(error)
                })
               setIsVisibleKeywordModal(false)
            }}
            onCancel={() => {
              setIsVisibleKeywordModal(false)
            }}
          />

          {
            keywordToBeUpdated && 
              <KeywordModal 
                headerText='Update Keyword'
                keyword={keywordToBeUpdated}
                primaryActionButtonText='Update'
                visible={isVisibileUpdateKeywordModal}
                onChange={(keyword: Keyword) => {
                  updateKeyword(keyword.Id, keyword)
                    .then((res) => {
                      setNotification({type: "success", message: `Successfully updated keyword with id: ${res.data.Id} & name: ${res.data.Name}`})
                      reload()
                    })
                    .catch((error) => {
                      formatErrors(error)
                    })
                  setKeywordTobeUpdated(null)
                  setIsVisibleUpdateKeywordModal(false)
                }}
                onCancel={() => {
                  setKeywordTobeUpdated(null)
                  setIsVisibleUpdateKeywordModal(false)
                }}
              />
          } 
        </div>
      )}
    </div>
  )
}

export default Keywords


