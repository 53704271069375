import { ColumnLayout, Flashbar, FlashbarProps } from "@amzn/awsui-components-react-v3";
import { ItemHistoryModel } from "../models/item-history-model"
import { ItemContainer } from "./ItemDetailComps/ItemContainer";
import * as React from "react";
import { Builder } from "builder-pattern";

export namespace ItemHistoryComps {
  export const SnapShot = (props: {
    match: {
      params: {
        id: string,
        version: number
      }
    },
  }) => {
    const snapShotState = ItemHistoryModel.UI.SnapShotState.use(props.match.params);
    const flashItem = snapShotState.loading ? 
      Builder<FlashbarProps.MessageDefinition>()
        .type("success")
        .content("Loading Item history")
        .loading(true)
        .dismissible(true)
        .build() :
      Builder<FlashbarProps.MessageDefinition>()
        .type("info")
        .content("You are viewing a read-only version of this Item")
        .dismissible(false)
        .build()
    return (
      <div>
        <div className='awsui-util-p-l'>
          <Flashbar items={[flashItem]} />
          <h1>{snapShotState.itemState.value.ItemFullName} </h1>
          <div>
            <ColumnLayout columns={1} >
              <ItemContainer.ItemSummary state={snapShotState.summaryState} />
              <ItemContainer.Contact state={snapShotState.contactState} />
              <ItemContainer.Detail state={snapShotState.detailState} />
              {snapShotState.itemState.value.ItemType === "Software" &&
                <ItemContainer.AmazonDependency state={snapShotState.amazonDependencyState}
              />}
              {snapShotState.itemState.value.ItemType === "Software" &&
                <ItemContainer.ThirdPartyDependency state={snapShotState.thirdPartyDependencyState}
              />}
              <h2>Primary Questionnaire</h2>
              <ItemContainer.Questionnaire state={snapShotState.questionnaireState} />
            </ColumnLayout>
          </div>
        </div>
      </div>)
  }
}