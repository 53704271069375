import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllKeyword } from '../actions/async.action';

const initialState = {
    keywords: [],
    status: 'idle',
    error: null
}

export const fetchKeywords = createAsyncThunk('keywords/fetchKeywords', async () => {
    const response = await getAllKeyword();

    return response.data
})

const keywordsSlice = createSlice({
    name: 'keywords',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
          .addCase(fetchKeywords.pending, (state, action) => {
            state.status = 'loading'
          })
          .addCase(fetchKeywords.fulfilled, (state, action) => {
            state.status = 'succeeded'
            // Add any fetched keywords to the array
            state.keywords = state.keywords.concat(action.payload)
          })
          .addCase(fetchKeywords.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
          })
      }
  })
  
export default keywordsSlice.reducer