import * as React from "react";
import { useState } from "react";
import {
  Button,
  Table,
  TableProps,
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  Box,
  TextFilter,
  Icon
} from "@amzn/awsui-components-react-v3";
import { Builder } from "builder-pattern";
import { QuestionModel } from "../models/question-model";
import { useCollection } from '@amzn/awsui-collection-hooks';

const columnDefinitions: TableProps.ColumnDefinition<QuestionModel.Data>[] = [
  Builder<TableProps.ColumnDefinition<QuestionModel.Data>>()
    .id("Id")
    .header("Id")
    .cell((i) => i.Id)
    .sortingField("Id")
    .build(),
  Builder<TableProps.ColumnDefinition<QuestionModel.Data>>()
    .id("DisplayText")
    .header("Display Text")
    .cell((i) => i.DisplayText)
    .sortingField("DisplayText")
    .build(),
  Builder<TableProps.ColumnDefinition<QuestionModel.Data>>()
    .id("Description")
    .header("Description")
    .cell((i) => i.Description)
    .sortingField("Description")
    .build(),
  Builder<TableProps.ColumnDefinition<QuestionModel.Data>>()
    .id("Version")
    .header("Version")
    .cell((i) => i.Version)
    .sortingField("Version")
    .build(),
  Builder<TableProps.ColumnDefinition<QuestionModel.Data>>()
    .id("LastUpdatedDate")
    .header("Last Updated")
    .cell((i) => i.LastUpdatedDate)
    .sortingField("LastUpdatedDate")
    .build(),
  Builder<TableProps.ColumnDefinition<QuestionModel.Data>>()
    .id("LastUpdatedBy")
    .header("Last Updated By")
    .cell((i) => i.LastUpdatedBy)
    .sortingField("LastUpdatedBy")
    .build(),
  Builder<TableProps.ColumnDefinition<QuestionModel.MalFormedData>>()
    .id("status")
    .header("Status")
    .ariaLabel(() => "Status")
    .cell((item) => <span className="awsui-util-status-negative">
      <Icon variant="error" name="status-negative"/>
      {item.Status}
    </span>)
    .build(),
  Builder<TableProps.ColumnDefinition<QuestionModel.PendingUpdateData>>()
    .id("operation")
    .header("Operation")
    .ariaLabel(() => "Operation")
    .cell((item) => item.Operation)
    .build(),
  Builder<TableProps.ColumnDefinition<QuestionModel.Data>>()
    .id("silentQuestionUpdate")
    .header("Silent Question Update")
    .cell((i) => i.BypassIncrementVersion ? "yes" : "no")
    .sortingField("BypassIncrementVersion")
    .build(),
]

export const malformedStatus = Builder<
TableProps.ColumnDefinition<QuestionModel.MalFormedData>
>()
.id("status")
.header("Status")
.ariaLabel(() => "Status")
.cell((item) => <span className="awsui-util-status-negative">
  <Icon variant="error" name="status-negative"/>
  {item.Status}
</span>)
.build();

export const pendingUpdateOperation = Builder<
TableProps.ColumnDefinition<QuestionModel.PendingUpdateData>
>()
.id("operation")
.header("Operation")
.ariaLabel(() => "Operation")
.cell((item) => item.Operation)
.build()

function EmptyState({ title, subtitle, action }) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
}


export const QuestionTable = (props: {
  items: QuestionModel.Data[];
  sortingColumn?: TableProps.SortingColumn<QuestionModel.Data>;
  header: JSX.Element;
  selectionType?: TableProps.SelectionType;
  selected?: QuestionModel.Data[];
  onSelect?: ((record: QuestionModel.Data[]) => void);
  visibleContent: string[];
}) => {
  const [preferences, setPreferences] = useState({ pageSize: 10, visibleContent: props.visibleContent });
  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    props.items,
    {
      filtering: {
        empty: (
          <EmptyState
            title="No data to display"
            subtitle=""
            action={<></>}
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
    }
  );
  return (
    <Table
      {...collectionProps}
      selectionType={props.selectionType}
      header={
        props.header
      }
      columnDefinitions={columnDefinitions}
      visibleColumns={preferences.visibleContent}
      selectedItems={props.selected}
      items={items}
      wrapLines={true}
      pagination={<Pagination {...paginationProps}
        pagesCount={Math.ceil(props.items.length / preferences.pageSize)}
      />}
      onSelectionChange={(e) => {
        props.onSelect && props.onSelect(e.detail.selectedItems)}
      }
      filter={
        <TextFilter
          {...filterProps}
          countText={`${filteredItemsCount} results`}
          filteringAriaLabel="Filter instances"
        />
      }
      preferences={
        <CollectionPreferences
          title="Title"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          preferences={preferences}
          pageSizePreference={
            Builder<CollectionPreferencesProps.PageSizePreference>().options([
              Builder<CollectionPreferencesProps.PageSizeOption>()
                .label("25 Questions")
                .value(25)
                .build(),
              Builder<CollectionPreferencesProps.PageSizeOption>()
                .label("50 Questions")
                .value(50)
                .build(),
              Builder<CollectionPreferencesProps.PageSizeOption>()
                .label("100 Questions")
                .value(100)
                .build()
              ]).build()
          }
          onConfirm={({ detail }) => setPreferences(Builder(preferences)
            .pageSize(detail.pageSize)
            .build()
          )}
        />
      }
    />
  );
}