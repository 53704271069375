import * as React from 'react';
import { BreadcrumbGroup } from '@amzn/awsui-components-react-v3';
import { useEffect, useState } from 'react';
import { getExistingItems } from '../../../actions/dashboard.action';
import { formatAndLocalizeDate } from '../../../utils/sharedFunctions';
import { dashboardStorageKey } from '../dashboard.constants'
import '../../../styles/form.scss';
import { tableData } from './Definitions';
import { saveUserActivity, UserActivityAction } from "../../../actions/userActivity.action";
import { CollectionTable } from "./../../../components/common/Table/CollectionTable"

interface ContentSelectOption {
  id: string; visible: boolean; label: string
}

const bcItem = [
  {
    "text": "Home",
    "href": "#"
  },
  {
    "text": "All Items",
    "href": "",
  },
]

const ExistingItems = () => {
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const pagination = parseInt(localStorage.getItem(dashboardStorageKey.AllItemsPagination)) || 20;

  const contentSelectOptions: ContentSelectOption[] = tableData(items).map((definition) => {
    const id = definition.columnDefinition.id
    const visible = definition.visibilityEditable
    const label = definition.columnDefinition.header.toString()
    return {
      id,
      visible,
      label
    }
  })
  
  // Function exists to mitigate https://t.corp.amazon.com/P78872072
  // To be deleted in the future
  const cleanContentSelection = (
    contentSelection: ContentSelectOption[]
  ) => {
    let selections: ContentSelectOption[] = [];
    contentSelection.map((c) => {
      contentSelectOptions.map((o) => {
        if (c.id.replace(/\s+/g, '').toLowerCase() === o.id.replace(/\s+/g, '').toLowerCase()
          || c.id.replace(/\s+/g, '').toLowerCase() === o.label.replace(/\s+/g, '').toLowerCase()
        ) {
          selections.push(Object.assign({}, o, {visible: c.visible}))
        }
      })
    })
    return selections;
  }

  const contentSelection = localStorage.getItem(dashboardStorageKey.AllItemsContentSelection) ?
  cleanContentSelection(JSON.parse(localStorage.getItem(dashboardStorageKey.AllItemsContentSelection))) :
  contentSelectOptions;

  useEffect(() => {
    saveUserActivity({
      action: UserActivityAction.SEARCH_ALL_ITEMS,
      itemId: ""
    });
    getExistingItems()
      .then((res) => {
        res.data.forEach((item) => {
          // Keep LastUpdatedDate as original
          item.FormatDate = item.LastUpdatedDate ? formatAndLocalizeDate(item.LastUpdatedDate) : '';
          item.LastBaselinedDate = item.LastBaselinedDate ? item.LastBaselinedDate : '';
          item.LastBaselinedBy = item.LastBaselinedBy ? item.LastBaselinedBy : '';
        });
        setItems(res.data);
        setLoading(false);
      })
  }, [])

  const paginationOptions = [
    { value: 10, label: "10 items" },
    { value: 20, label: "20 items" },
    { value: 30, label: "30 items" },
    { value: 50, label: "50 items" },
    { value: 100, label: "100 items" },
  ]

  const onChangePagination = (pagination: number) => {
    localStorage.setItem(dashboardStorageKey.AllItemsPagination, '' + pagination)
  }

  const onChangeContentSelection = (contentSelections: string[]) => {
    const deepCopyContentSelectOptions: ContentSelectOption[]
        = JSON.parse(JSON.stringify(contentSelectOptions))
    deepCopyContentSelectOptions.forEach((option) => option.visible = false)
    contentSelections.forEach((selection) => {
        const index = deepCopyContentSelectOptions.findIndex((option) => option.id === selection)
        deepCopyContentSelectOptions[index].visible = true;
    })
    localStorage.setItem(dashboardStorageKey.AllItemsContentSelection, JSON.stringify(deepCopyContentSelectOptions))
  }

  return (
    <div style={{ padding: '2rem' }} >
      <BreadcrumbGroup items={bcItem} />
      <h1 className='cript-page-header'>All Items</h1>
      <CollectionTable
        allItems={items}
        tableData={tableData(items)}
        headerText="All Items"
        onChangeCollectionPreferences={(selected) => {
          onChangeContentSelection(selected)
        }}
        visibleColumnNames={contentSelection.filter((c) => c.visible).map((c) => c.id)}
        loading={loading}
        onChangePagination={(pageSize) => onChangePagination(pageSize)}
        paginationOptions={paginationOptions}
        pageSize={pagination}
        defaultSortFieldId={'ItemFullName'}
        defaultSortDescending={false}
      />
    </div>
  );
}

export default ExistingItems;