import { Icon } from '@amzn/awsui-components-react-v3'
import * as React from 'react'
import { ECCN_STATUS } from '../../models/item-detail-model'

export const Label = (props: { name: string, info: any }) => {
    const { name, info } = props
    return (
        <div>
            <div className="awsui-util-label" > {name} </div>
            <div> {info} </div>
        </div>
    )
}


export const ECCNLabel = ({ info }) => {
    const active = info.ECCNStatus === ECCN_STATUS.ACTIVE
    return (
        <div>
            <div className="awsui-util-label">ECCN</div>
            <div>
                {active && <div style={{ color: 'green' }}><Icon name="status-positive" variant='success' className='awsui-util-pr-s' />{info.ECCN}</div>}
                {!active && <div><Icon name="status-pending" className='awsui-util-pr-s' />{info.ECCNStatus}</div>}

            </div>
        </div>)
}