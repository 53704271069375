
import * as React from "react";
import { ColumnLayout, Icon } from '@amzn/awsui-components-react-v3';

export default class Error extends React.Component {

  constructor() {
      super(null)
  }

  render() {
    const randomNumber = (Math.floor(Math.random() * 43) + 1);

    return (
      <div style={{
        textAlign: 'center',
        backgroundColor: 'white'
      }}>
        <div className="awsui-util-container">
          <ColumnLayout>
            <h1><Icon size="large" variant="error" name="status-negative"></Icon> There’s been a glitch…</h1><p>We’re not quite sure what went wrong, will look into it. You can go to <a href='#/'>Homepage.</a>, or <a href="https://sim.amazon.com" target="_blank">let us know</a> if you need a hand.</p>
          </ColumnLayout>
        </div>

        <a href="https://www.amazon.com/dogsofamazon" target="_blank">
          <img id="not-found-dog" alt="Sorry! We Encountered an error." src={`https://images-na.ssl-images-amazon.com/images/G/01/error/${randomNumber}._TTD_.jpg"`} />
        </a>
      </div>
    );
  };
}