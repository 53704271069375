import * as React from 'react';
import { Button, FormField } from '@amzn/awsui-components-react-v3';
import ReactDropzone from "react-dropzone";
import { formatStringToDate } from '../../utils/sharedFunctions';

export default class FileUploader extends React.Component<{ handleChange: any, uploadFile: any, readFile: any, deleteFile: any, fileMetaData: any }, {}> {
  constructor(props) {
      super(props);
  }

  render() {
    return (
      <div>
        { this.props.fileMetaData && this.props.fileMetaData.length ?
          <FormField stretch={true}>
              <table >
                  <thead>
                      <tr>
                        <th >Document Name</th>
                        <th >Uploaded by</th>
                        <th >Uploaded</th>
                      </tr>
                  </thead>
                  <tbody>
                      {this.props.fileMetaData.map((file, i) =>
                        <tr key={`${file.filename}${i}`}>
                          <td ><Button onClick={() => this.props.readFile(file.filename)} variant='link' formAction="none">{file.filename}</Button></td>
                          <td >{file.modifiedBy}</td>
                          <td >{formatStringToDate(file.modifiedAt)}</td>
                          <td ><Button onClick={() => this.props.deleteFile(file.filename)} formAction="none">Remove</Button></td>
                        </tr>
                      )}
                  </tbody>
              </table>
          </FormField> : null
        }
        <div className='awsui-util-mt-l'>
          <ReactDropzone
              onDrop={(event) => {
                this.props.uploadFile(event)
              }}
          >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Button formAction="none">Add document</Button>
                </div>
              )}
          </ReactDropzone>
          <p style={{ color: '#687078', fontSize: '0.875em' }}>The following file types are accepted: .jpeg, .png, .doc, .docx, .xlsx, .xls, .csv, .ppt, .pptm, .txt, .pdf</p>
        </div>
      </div>
    )
  }
}