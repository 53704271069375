import * as React from 'react';
import './styles/timeline.css'
import './styles/question.css'
import {
  Route,
  Switch,
  HashRouter,
} from "react-router-dom";
import { 
  Alert,
  AppLayout,
  Button,
  Modal
} from '@amzn/awsui-components-react-v3';
import { Navigation } from './components/common';
import { ItemHistoryComps } from "./components/item-history-comps"
import { connect } from "react-redux";

import './styles/landing-page.scss';

import Error from './components/shared/Error';
import NotFound from './components/shared/NotFound';
import UnAuthorized from './components/shared/UnAuthorized';
import { UserInfo } from "./models/user-model";
import { getAuthenticationObj, setUserInfo } from "./actions/authentication.action";
import { setAppConfig } from "./actions/config.action";
import { checkAdminStatus } from './actions/authorization.action';
import { setDirty, setDestination } from './actions/navigation.action';
import { getAppPoolConfig } from "./utils/config";
import { activityWatcher } from "./utils/sharedFunctions";
import { store } from "./main";
import UploadQuestions from './content/upload-question'
import ItemDetail2 from "./content/item-detail";
import useHelpPane, { HelpPaneContext } from './components/shared/HelpPane/HelpPane'
import { Landing } from './content/Landing'
import ItemCreate from './content/item-create'
import UpdateBaselinePeriods from './content/update-baseline-period'
import ExistingItems from './content/Dashboards/ExistingItems/ExistingItems';
import AdminComplianceDashboard from './content/Dashboards/AdminComplianceDashboard/AdminComplianceDashboard';
import { QuestionBankComps } from './components/question-bank-comps';
import Keywords from './content/keyword-management'
import { fetchKeywords } from './reducers/KeywordSlice';

interface AppProps {
  authObj: any;
  setAppConfig: (configObj: any) => void;
  getAuthenticationObject: () => void;
  setUserInfoObject: (authProps: any) => void;
  setDirty: (dirty: boolean) => void;
  setDestination: (destination: string) => void;
  userDetails: UserInfo;
  isDirty: boolean;
  destination: string;
}

interface state {
  isDesktop: boolean,
  isAdmin: boolean,
  modalVisible: boolean,
  sidebarExpanded: boolean;
}

function WithAddOns({ children }) {
  const helpPane = useHelpPane();

  return (
    <HelpPaneContext.Provider value={helpPane}>
      {children(helpPane)}
    </HelpPaneContext.Provider>
  )
}

const Content = ({ props }) => {
  const { helpPane, toggl } = props
  return (<HashRouter>
    <Switch>
      <Route exact path='/' component={Landing} />
      <Route exact path='/dashboard/admin-compliance' component={AdminComplianceDashboard} />
      <Route exact path='/error' component={Error} />
      <Route exact path='/notfound' component={NotFound} />
      <Route exact path='/access-denied' component={UnAuthorized} />
      <Route exact path='/uploadQuestions/' component={UploadQuestions} />
      <Route exact path='/updateBaselinePeriods/' component={UpdateBaselinePeriods} />
      <Route exact path='/setting/' render={() => toggl.ui} />
      <Route exact path='/items/create' component={ItemCreate} />
      <Route exact path='/items' component={ExistingItems} />
      <Route exact path='/items/:id/' render={(p) => {
        return <ItemDetail2 {...p} helpPane={helpPane} ></ItemDetail2>
      }} />
      <Route exact path='/history/:id/:version' render={(p) => {
        return <ItemHistoryComps.SnapShot {...p} />
      }} />
      <Route exact path='/questions/' render={(p) => {
        return <QuestionBankComps.QuestionPage />
      }} />
      <Route exact path='/keywords/' component={Keywords}/>
    </Switch>
  </HashRouter>)
};

export class App extends React.Component<AppProps, state>{
  constructor(props: Readonly<AppProps>) {
    super(props);
    this.state = {
      isDesktop: false,
      isAdmin: null,
      modalVisible: false,
      sidebarExpanded: false
    };

    this.onBeforeUnload = this.onBeforeUnload.bind(this);
    this.onNavigate = this.onNavigate.bind(this);
  }

  onNavigate(event) {
    this.props.setDestination(event.detail.href)
    if (this.props.isDirty) {

      // prevent navigation
      event.preventDefault();
      this.setState({ modalVisible: true });
    }
  }

  componentDidMount() {
    this.setState({
      isDesktop: window.innerWidth > 1000,
      sidebarExpanded: window.innerWidth > 1000
    });

    getAppPoolConfig().then(data => {
      this.props.setAppConfig(data);
      this.props.getAuthenticationObject();
    }).catch(error => console.log(error));

    // Used for detecting browser url changes to trigger 'Are you sure' modal
    window.addEventListener('beforeunload', this.onBeforeUnload);

    // Refresh cognito session if any user activity is performed 
    // when cognito session length less than 5 minutes
    activityWatcher(2000, () => {
      let authObj = store.getState().authenticationObj.authObj;
      const now = Math.floor(new Date().getTime() / 1000);
      let remainingTime = authObj.signInUserSession.accessToken.getExpiration() - now;

      // Refresh session if remaining session is less than 5 minutes
      if (remainingTime < 300) {
        authObj.refreshSession(authObj.signInUserSession.getRefreshToken().getToken())
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onBeforeUnload);
  }

  onBeforeUnload(evt) {
    if (this.props.isDirty) {

      // Cancel the event as stated by the standard.
      evt.preventDefault();

      // Chrome requires returnValue to be set.
      evt.returnValue = '';
    }
  }

  render() {
    if (!this.props.authObj) {
      return null;
    }
    let display: boolean = false;
    let curUrl = window.location.href;
    if (curUrl.indexOf("code") > -1 && curUrl.indexOf("state") > -1) {
      this.props.authObj.parseCognitoWebResponse(curUrl);

      let destinationUrl = localStorage.getItem('destinationUrl');
      if (destinationUrl) {

        // if desintaionUrl exists, redirect to it after authorization
        // why save destinationUrl in localStorage and redirect to it?
        // doing so enables user whom have not logged in, to maintain the url that they have linked to
        // without this process, a non-logged in user trying to go to /#/item/<id> would always redirect to /# (homepage)
        window.history.replaceState({}, document.title, destinationUrl);
        localStorage.removeItem('destinationUrl');
      } else {
        // if no destinationUrl exists in client local storage, default to homepage
        let clean_uri = curUrl.substring(0, curUrl.indexOf("?"));
        window.history.replaceState({}, document.title, clean_uri);
      }
    }
    if (!this.props.authObj.isUserSignedIn()) {
      if (curUrl.indexOf("code") > -1 && curUrl.indexOf("state") > -1) {
        return null;
      }
      // set destination url in local storage
      // this enables us to redirect back to destination url after cognito authorization redirect
      localStorage.setItem('destinationUrl', curUrl);
      this.props.authObj.getSession();
    }
    else {
      if (!this.props.userDetails) {
        this.props.setUserInfoObject(this.props.authObj.getSignInUserSession());
      }
      else {
        display = true;
        if (this.state.isAdmin === null) {
          checkAdminStatus().then((res) => {
            this.setState({ isAdmin: res.data })
          });
        }
      }
    }
    return (
      display && (<div className='awsui' >
        <WithAddOns>
          {(helpPane, toggl) => {
            const isAdmin = this.state.isAdmin
            return <AppLayout
              className='awsui-util-no-gutters'
              content={<Content props={{ ...this.props, helpPane, toggl, isAdmin }} />}
              navigation={<Navigation isAdmin={this.state.isAdmin} isDirty={this.props.isDirty} onFollowHandler={this.onNavigate} toggl={toggl} />}
              navigationOpen={this.state.sidebarExpanded}
              navigationWidth={250}
              toolsHide={helpPane.hide}
              tools={helpPane.pane}
              toolsOpen={helpPane.open}
              onNavigationChange={(e) => {
                this.setState({ sidebarExpanded: e.detail.open });
              }}
              onToolsChange={(e) => {
                if (!e.detail.open) {
                  helpPane.clear();
                }
              }}
            />
          }}
        </WithAddOns>
        <Modal
          visible={this.state.modalVisible}
          header="You have unsaved changes"
          footer={
            <span className="awsui-util-f-r">
              <Button
                variant="link"
                onClick={() => {
                  this.setState({ modalVisible: false });
                  this.props.setDestination(null);
                }}
              >Stay</Button>
              <Button
                variant="primary"
                onClick={() => {
                  this.props.setDirty(false);
                  this.setState({ modalVisible: false });
                  window.location.assign(this.props.destination);
                }}
              >Leave</Button>
            </span>
          }
        >
          <Alert type="warning">
            Are you sure that you want to leave the current page? The changes that you made won't be saved.
          </Alert>
        </Modal>
      </div>)
    );
  }
}

function mapStateToProps(state) {
  return {
    authObj: state.authenticationObj.authObj,
    userDetails: state.authenticationObj.userDetails,
    isDirty: state.dirtyObj.isDirty,
    destination: state.dirtyObj.destination,
    keywords: state.keywordObj.keywords
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setAppConfig: (configObj: any) => {
      dispatch(setAppConfig(configObj))
    },
    getAuthenticationObject: () => {
      dispatch(getAuthenticationObj())
    },
    setUserInfoObject: (authProps: any) => {
      dispatch(setUserInfo(authProps));
    },
    setDirty: (dirty: boolean) => {
      dispatch(setDirty(dirty));
    },
    setDestination: (destination: string) => {
      dispatch(setDestination(destination));
    },
    setKeywords: () => {
      dispatch(fetchKeywords());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);