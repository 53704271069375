import * as AWS from "aws-sdk";
import { CognitoAuth } from 'amazon-cognito-auth-js'
import { UserInfo } from "../models/user-model";

export const Initialize_CognitoAuth = "Initialize_CognitoAuth";
export const Set_UserInfo = "Set_UserInfo";

export function getAuthenticationObj() {
    return (dispatch, getState) => {
        {
            // gets react state
            const state = getState();
            let auth = new CognitoAuth(state.configObj.configObj.CognitoAppClientSettings);
            auth.userhandler = {
                onSuccess: function (result) {
                    dispatch(setUserInfo(result));
                },
                onFailure: function (err) {
                    auth.clearCachedTokensScopes();
                    auth.getSession();
                }
            };
            auth.useCodeGrantFlow();
            dispatch(intializeAuthObj(auth));
        }
    }
}

export function setUserInfo(result: any) {
    let payload: any = result.getIdToken().decodePayload();
    let userInfo: UserInfo = {
        jwtToken: result.getIdToken().getJwtToken(),
        email: payload.email,
        employeeId: payload["custom:EMPLOYEE_NUMBER"],
        alias: removeEmailFromId(payload.identities[0].userId),
        location: payload["custom:Location"],
        accessGroups: payload['custom:UserGroups'],
        refreshToken: result.getRefreshToken().refreshToken
    };
    AWS.config.region = 'us-west-2';
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'us-west-2:4af66103-cf8d-4858-a5b3-292b39ee54df',
        Logins: {
            'cognito-idp.us-west-2.amazonaws.com/us-west-2_XPYJdcTaz': userInfo.jwtToken
        }
    });
    return {
        type: Set_UserInfo,
        payload: userInfo
    }
}

export function intializeAuthObj(authObj: any) {
    return {
        type: Initialize_CognitoAuth,
        payload: authObj
    }
}

// duplicated from edit product
function removeEmailFromId(id): string {
    if (!id.includes('@')) {
        return id;
    }

    return id.replace(/(\@.*?.com)/, '')
}
