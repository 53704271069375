import * as React from "react";
import {
  AttributeEditor,
  AttributeEditorProps,
  Autosuggest,
  Button,
  ButtonDropdown,
  ButtonDropdownProps,
  ColumnLayout,
  FormField,
  Input,
  Modal,
  Textarea,
  Toggle,
} from "@amzn/awsui-components-react-v3";
import { Builder } from "builder-pattern";
import { QuestionModel, ReferenceType, QuestionType } from "../models/question-model";
import { UIModel } from "../models/ui-model";
import { Container } from "./shared/Container";
import { TableHeader } from "./shared/TableHeader"
import { QuestionTable } from "./question-bank-tables";
export namespace QuestionBankComps {
  const questionTypeSelectDropDownItems = [
    Builder<ButtonDropdownProps.Item>()
      .id("SINGLE_SELECTION")
      .text("Single Select Question")
      .build(),
    Builder<ButtonDropdownProps.Item>()
      .id("MULTI_SELECTION")
      .text("Multiple Select Question")
      .build(),
    Builder<ButtonDropdownProps.Item>()
      .id("TEXT")
      .text("Text Question")
      .build(),
  ];

  const notValidType = "Not valid reference type";

  const referenceTypeSelectDropDownItems = [
    Builder<ButtonDropdownProps.Item>()
      .id("ECCN")
      .text("ECCN")
      .build(),
    Builder<ButtonDropdownProps.Item>()
      .id("QUESTION")
      .text("Question")
      .build(),
    Builder<ButtonDropdownProps.Item>()
      .id("MANUAL")
      .text("Request GTPC review")
      .build(),
  ];

  const CreateBasicInfo = (
    props: { state: QuestionModel.UI.BasicInfoDataState }
  ) => {
    return (
      <Container.Comp
        title="Question basic information"
      >
        <React.Fragment>
          <ColumnLayout columns={1}>
            <FormField
              label={<span>Question Id</span>}
              errorText={props.state.idFieldState.errorText}
            >
              <Input
                value={props.state.idFieldState.value}
                onChange={(e) =>
                  props.state.idFieldState.setValue(e.detail.value)
                }
              />
            </FormField>
            <FormField
              label={<span>Question Text</span>}
              errorText={props.state.textFieldState.errorText}
            >
              <Textarea
                value={props.state.textFieldState.value}
                onChange={(e) =>
                  props.state.textFieldState.setValue(e.detail.value)
                }
              />
            </FormField>
            <FormField
              label={<span>Question Description</span>}
            >
              <Textarea
                value={props.state.descriptionFieldState.value}
                onChange={(e) =>
                  props.state.descriptionFieldState.setValue(e.detail.value)
                }
              />
            </FormField>
            <FormField
              label={<span>Question Type</span>}
              errorText={props.state.typeFieldState.errorText}
            >
              <ButtonDropdown
                items={
                  questionTypeSelectDropDownItems
                }
                onItemClick={(e) =>
                  props.state.typeFieldState.setValue(e.detail.id as QuestionType)
                }>
                {questionTypeSelectDropDownItems.find(e => e.id === props.state.typeFieldState.value) ?
                  questionTypeSelectDropDownItems.find(e => e.id === props.state.typeFieldState.value).text :
                  "Select question type"
                }
              </ButtonDropdown>
            </FormField>
          </ColumnLayout>
        </React.Fragment>
      </Container.Comp >
    )
  }

  const BasicInfoEdit = (
    props: { state?: QuestionModel.UI.BasicInfoDataState }
  ) => {
    return (
      <Container.Comp
        title="Question basic information"
      >
        <React.Fragment>
          <ColumnLayout columns={1}>
            <FormField
              label={<span>Question Id</span>}
            >
              {props.state.idFieldState.value}
            </FormField>
            <FormField
              label={<span>Question Text</span>}
              errorText={props.state.textFieldState.errorText}
            >
              <Textarea
                value={props.state.textFieldState.value}
                onChange={(e) =>
                  props.state.textFieldState.setValue(e.detail.value)
                }
              />
            </FormField>
            <FormField
              label={<span>Question Description</span>}
              errorText={props.state.descriptionFieldState.errorText}
            >
              <Textarea
                value={props.state.descriptionFieldState.value}
                onChange={(e) =>
                  props.state.descriptionFieldState.setValue(e.detail.value)
                }
              />
            </FormField>
            <FormField
              label={<span>Question Type</span>}
              errorText={props.state.typeFieldState.errorText}
            >
              <ButtonDropdown
                items={
                  questionTypeSelectDropDownItems
                }
                onItemClick={(e) =>
                  props.state.typeFieldState.setValue(e.detail.id as QuestionType)
                }>
                {questionTypeSelectDropDownItems.find(e => e.id === props.state.typeFieldState.value) ?
                  questionTypeSelectDropDownItems.find(e => e.id === props.state.typeFieldState.value).text :
                  "Select question type"
                }
              </ButtonDropdown>
            </FormField>
            <FormField
              label={<h3>Silent Question Update</h3>}
              description={
                <div>
                  <p>Normally, when a Question is updated, an CRIPT Item that has answered that Question will be forced to re-answer that Question.  As a result of turning on Silent Question Update, any Item that has answered this Question will not be forced to re-answer this Question.</p>
                  <h4>Warning! As a rule of thumb, only toggle this if you're sure this won't affect existing Items' ECCN classifications</h4>
                  <ul>
                    <li>Recommended only to use Silent Question Update for Question text updates, description updates, and/or for adding new answer options</li>
                    <li>Not recommended for updating question type, removing answer options, or changing answer options</li>
                  </ul>
                </div>
              }
              errorText={props.state.bypassIncrementVersionState.errorText}
            >
              <Toggle
                checked={props.state.bypassIncrementVersionState.value}
                onChange={(e) => props.state.bypassIncrementVersionState.setValue(e.detail.checked)}
              >{props.state.bypassIncrementVersionState.value ? "Update Question Silently" : "Updating this Question will require Items to re-answer this Question"}</Toggle>
            </FormField>
          </ColumnLayout>
        </React.Fragment>
      </Container.Comp>
    )
  }

  const BasicInfoReadOnly = (
    props: { state?: QuestionModel.UI.BasicInfoDataState }
  ) => {
    return (
      <Container.Comp
        title="Question basic information"
      >
        <React.Fragment>
          <ColumnLayout columns={1}>
            <FormField
              label={<span>Question Id</span>}
            >
              {props.state.idFieldState.value}
            </FormField>
            <FormField
              label={<span>Question Text</span>}
            >
              {props.state.textFieldState.value}
            </FormField>
            <FormField
              label={<span>Question Description</span>}
            >
              {props.state.descriptionFieldState.value}
            </FormField>
            <FormField
              label={<span>Question Type</span>}
            >
              {questionTypeSelectDropDownItems.find(e => e.id === props.state.typeFieldState.value) ?
                questionTypeSelectDropDownItems.find(e => e.id === props.state.typeFieldState.value).text :
                notValidType
              }
            </FormField>
          </ColumnLayout>
        </React.Fragment>
      </Container.Comp>
    )
  }

  const SingleSelectQuestionEdit = (props: {
    state: QuestionModel.UI.SingleSelectQuestionState
  }) => {
    const attributeDefinitions = [
      Builder<AttributeEditorProps.FieldDefinition<QuestionModel.AnswerOption>>()
        .label("Option text")
        .control((item, index) => <Textarea
          value={item.Criteria.Value}
          onChange={(e) => {
            const newOptions = props.state.options.value.slice();
            newOptions.splice(
              index, 1,
              Builder(newOptions[index])
                .Criteria(
                  Builder(item.Criteria)
                    .Value(e.detail.value)
                    .build()
                ).build()
            );
            props.state.options.setValue(newOptions);
          }}
        />)
        .build(),
      Builder<AttributeEditorProps.FieldDefinition<QuestionModel.AnswerOption>>()
        .label("Reference type")
        .control((item, index) => {
          return <ButtonDropdown
            items={referenceTypeSelectDropDownItems}
            onItemClick={(e) => {
              const newOptions = props.state.options.value;
              newOptions.splice(
                index, 1,
                Builder(newOptions[index])
                  .RefType(
                    e.detail.id as ReferenceType
                  )
                  .RefIdentifier("")
                  .build()
              );
              props.state.options.setValue(newOptions);
            }}
          >
            {referenceTypeSelectDropDownItems.find(e => e.id === item.RefType).text ||
            "Select reference type"}
          </ButtonDropdown>
        })
        .build(),
      Builder<AttributeEditorProps.FieldDefinition<QuestionModel.AnswerOption>>()
        .label("Reference identifier")
        .control((item, index) => {
          if (item.RefType === "MANUAL") {
            return "Identifier not required"
          }
          if (item.RefType === "QUESTION") {
            return <QuestionSelectFromDraftQuestions
              onChange={(question) => {
                const newOptions = props.state.options.value.slice();
                newOptions.splice(
                  index, 1,
                  Builder(newOptions[index])
                    .RefIdentifier(question.Id)
                    .build()
                );
                props.state.options.setValue(newOptions);
              }}
              value={props.state.options.value[index].RefIdentifier}
            />
          }
          if (item.RefType === "ECCN") {
            return <Input
              value={item.RefIdentifier}
              onChange={(e) => {
                const newOptions = props.state.options.value.slice();
                newOptions.splice(
                  index, 1,
                  Builder(newOptions[index])
                    .RefIdentifier(e.detail.value)
                    .build()
                );
                props.state.options.setValue(newOptions);
              }}
            />;
          }
        })
        .build()
    ]

    return (
      <Container.Comp
        title="Single select question information"
      >
        <AttributeEditor
          items={props.state.options.value}
          empty="Please add options for single select question"
          removeButtonText="remove"
          definition={attributeDefinitions}
          addButtonText="Add Option"
          onAddButtonClick={(event) => {
            props.state.options.setValue([
              ...props.state.options.value,
              Builder<QuestionModel.AnswerOption>()
                .Criteria(
                  {
                    Operator: "equals",
                    Value: ""
                  }
                )
                .RefType("QUESTION")
                .RefIdentifier("")
                .build()])
          }}
          onRemoveButtonClick={(event) => {
            const newOptions = props.state.options.value.slice();
            newOptions.splice(event.detail.itemIndex, 1);
            props.state.options.setValue(newOptions);
          }}
        />
      </Container.Comp>
    )
  }

  const SingleSelectQuestionReadOnly = (props: {
    state: QuestionModel.UI.SingleSelectQuestionState
  }) => {

    const options = props.state.options.value.map(value => {
      return [value.Criteria.Value, value.RefType, value.RefIdentifier];
    })

    return (
      <Container.Comp
        title="Single select question information"
      >
        <ColumnLayout columns={3}>
          <span>Option text</span>
          <span>Reference Type</span>
          <span>Reference Identifier</span>
          {
            props.state.options.value.map(value => {
              return [<span>{value.Criteria.Value}</span>,
              <span>{referenceTypeSelectDropDownItems.find(e => e.id === value.RefType).text || notValidType}</span>,
              <span>{value.RefIdentifier}</span>
              ];
            })
          }</ColumnLayout>
      </Container.Comp>
    )
  }

  const MultiSelectQuestionEdit = (props: {
    state: QuestionModel.UI.MultipleSelectQuestionState
  }) => {
    const optionValuesDefinitions = [
      Builder<AttributeEditorProps.FieldDefinition<string>>()
        .label("Option text")
        .control((item, index) => <Textarea
          value={item}
          onChange={(e) => {
            const newValue = props.state.optionValues.value.slice();
            newValue.splice(
              index,
              1,
              e.detail.value
            );
            console.log(`newValue=${JSON.stringify(newValue)}`);
            props.state.optionValues.setValue(newValue);
          }}
        />)
        .build(),
    ];

    const multipleOptionsForm = (
      <React.Fragment>
        <ColumnLayout columns={1}>
          <AttributeEditor
            items={props.state.optionValues.value}
            empty="Please add options for single select question"
            removeButtonText="remove"
            definition={optionValuesDefinitions}
            addButtonText="Add Option"
            onAddButtonClick={(event) => {
              props.state.optionValues.setValue([...props.state.optionValues.value, ""])
            }}
            onRemoveButtonClick={(event) => {
              const newValue = props.state.optionValues.value.slice();
              newValue.splice(event.detail.itemIndex, 1);
              props.state.optionValues.setValue(newValue);
            }}
          />
          <React.Fragment>
            <ColumnLayout columns={2}>
              <FormField
                label={<span>Reference type</span>}
              >
                <ButtonDropdown
                  items={referenceTypeSelectDropDownItems}
                  onItemClick={(e) => {
                    props.state.selectAnyOption.setValue(
                      Builder(props.state.selectAnyOption.value)
                        .RefType(
                          e.detail.id as ReferenceType
                        )
                        .RefIdentifier("")
                        .build());
                  }}
                >
                  {
                    referenceTypeSelectDropDownItems.find((e) =>
                      e.id === props.state.selectAnyOption.value.RefType
                    ).text ||
                    "Select reference type"
                  }
                </ButtonDropdown>
              </FormField>
              <FormField
                label={<span>Reference Identifier</span>}
              >{
                  props.state.selectAnyOption.value.RefType === "ECCN" ?
                    <Input
                      value={props.state.selectAnyOption.value.RefIdentifier}
                      onChange={(e) =>
                        props.state.selectAnyOption.setValue(
                          Builder<QuestionModel.AnswerOption>(props.state.selectAnyOption.value)
                            .RefIdentifier(e.detail.value)
                            .build()
                        )
                      }
                    /> :
                    props.state.selectAnyOption.value.RefType === "MANUAL" ?
                      <div>No identifier required</div> :

                      props.state.selectAnyOption.value.RefType === "QUESTION" ?
                        <QuestionSelectFromDraftQuestions
                          onChange={(question) =>
                            props.state.selectAnyOption.setValue(
                              Builder<QuestionModel.AnswerOption>(props.state.selectAnyOption.value)
                                .RefIdentifier(question.Id)
                                .build()
                            )
                          }
                          value={props.state.selectAnyOption.value.RefIdentifier}
                        /> :
                        <></>
                }
              </FormField>
            </ColumnLayout>
          </React.Fragment>
        </ColumnLayout>
      </React.Fragment>
    )

    const notAboveOptionForm = (
      <React.Fragment>
        <ColumnLayout columns={3}>
          <FormField
            label={<span> None of the above text </span>}
          >
            <Textarea
              value={props.state.notAboveOption.value.Criteria.Value}
              onChange={(e) =>
                props.state.notAboveOption.setValue(
                  Builder<QuestionModel.AnswerOption>(props.state.notAboveOption.value)
                    .Criteria(
                      Builder(props.state.notAboveOption.value.Criteria)
                        .Value(e.detail.value)
                        .build()
                    )
                    .build()
                )
              }
            />
          </FormField>
          <FormField
            label={<span>Reference Type</span>}
          >
            <ButtonDropdown
              items={referenceTypeSelectDropDownItems}
              onItemClick={(e) => {
                props.state.notAboveOption.setValue(
                  Builder(props.state.notAboveOption.value)
                    .RefType(
                      e.detail.id as ReferenceType
                    )
                    .RefIdentifier("")
                    .build());
              }}
            >
              {
                referenceTypeSelectDropDownItems.find((e) =>
                  e.id === props.state.notAboveOption.value.RefType
                ).text ||
                "Select reference type"
              }
            </ButtonDropdown>
          </FormField>
          <FormField
            label={<span>Reference Identifier</span>}
          >
            {
              props.state.notAboveOption.value.RefType === "ECCN" ?
                <Input
                  value={props.state.notAboveOption.value.RefIdentifier}
                  onChange={(e) =>
                    props.state.notAboveOption.setValue(
                      Builder<QuestionModel.AnswerOption>(props.state.notAboveOption.value)
                        .RefIdentifier(e.detail.value)
                        .build()
                    )
                  }
                /> :
                props.state.notAboveOption.value.RefType === "MANUAL" ?
                  <div>No identifier required</div> :

                  props.state.notAboveOption.value.RefType === "QUESTION" ?
                    <QuestionSelectFromDraftQuestions
                      onChange={(question) =>
                        props.state.notAboveOption.setValue(
                          Builder<QuestionModel.AnswerOption>(props.state.notAboveOption.value)
                            .RefIdentifier(question.Id)
                            .build()
                        )
                      }
                      value={props.state.notAboveOption.value.RefIdentifier}
                    /> :
                    <></>
            }
          </FormField>
        </ColumnLayout>
      </React.Fragment>
    )

    return (
      <Container.Comp
        title="Multiple select question information"
      >
        <ColumnLayout columns={1} borders="horizontal">
          {multipleOptionsForm}
          {notAboveOptionForm}
        </ColumnLayout>
      </Container.Comp>
    )
  }

  const MultiSelectQuestionReadOnly = (props: {
    state: QuestionModel.UI.MultipleSelectQuestionState
  }) => {

    const multipleOptionsForm = (
      <React.Fragment>
        <ColumnLayout columns={1}>
          <span>Option Text</span>
          {props.state.optionValues.value.map(v => <span>{v} </span>)}
          <React.Fragment>
            <ColumnLayout columns={2}>
              <FormField
                label={<span>Reference type</span>}
              >
                {
                  referenceTypeSelectDropDownItems.find((e) =>
                    e.id === props.state.selectAnyOption.value.RefType
                  ).text || notValidType
                }
              </FormField>
              <FormField
                label={<span>Reference Identifier</span>}
              >
                {props.state.selectAnyOption.value.RefIdentifier}
              </FormField>
            </ColumnLayout>
          </React.Fragment>
        </ColumnLayout>
      </React.Fragment>
    )

    const notAboveOptionForm = (
      <React.Fragment>
        <ColumnLayout columns={3}>
          <FormField
            label={<span> None of the above text </span>}
          >
            {props.state.notAboveOption.value.Criteria.Value}
          </FormField>
          <FormField
            label={<span>Reference Type</span>}
          >
            {
              referenceTypeSelectDropDownItems.find((e) =>
                e.id === props.state.notAboveOption.value.RefType
              ).text || notValidType
            }
          </FormField>
          <FormField
            label={<span>Reference Identifier</span>}
          >
            {props.state.notAboveOption.value.RefIdentifier}
          </FormField>
        </ColumnLayout>
      </React.Fragment>
    )

    return (
      <Container.Comp
        title="Multiple select question information"
      >
        <ColumnLayout columns={1} borders="horizontal">
          {multipleOptionsForm}
          {notAboveOptionForm}
        </ColumnLayout>
      </Container.Comp>
    )
  }

  const TextQuestionEdit = (props: {
    state: QuestionModel.UI.TextQuestionState
  }) => {

    const optionForm = (
      <React.Fragment>
        <ColumnLayout columns={3}>
          <FormField
            label={<span>Reference Type</span>}
          >
            <ButtonDropdown
              items={referenceTypeSelectDropDownItems}
              onItemClick={(e) => {
                props.state.option.setValue(
                  Builder(props.state.option.value)
                    .RefType(
                      e.detail.id as ReferenceType
                    )
                    .RefIdentifier("")
                    .build());
              }}
            >
              {
                referenceTypeSelectDropDownItems.find((e) =>
                  e.id === props.state.option.value.RefType
                ).text ||
                "Select reference type"
              }
            </ButtonDropdown>
          </FormField>
          <FormField
            label={<span>Reference Identifier</span>}
          >
            {
              props.state.option.value.RefType === "ECCN" ?
                <Input
                  value={props.state.option.value.RefIdentifier}
                  onChange={(e) =>
                    props.state.option.setValue(
                      Builder<QuestionModel.AnswerOption>(props.state.option.value)
                        .RefIdentifier(e.detail.value)
                        .build()
                    )
                  }
                /> :
                props.state.option.value.RefType === "MANUAL" ?
                  <div>No identifier required</div> :

                  props.state.option.value.RefType === "QUESTION" ?
                    <QuestionSelectFromDraftQuestions
                      onChange={(question) =>
                        props.state.option.setValue(
                          Builder<QuestionModel.AnswerOption>(props.state.option.value)
                            .RefIdentifier(question.Id)
                            .build()
                        )
                      }
                      value={props.state.option.value.RefIdentifier}
                    /> :
                    <></>
            }
          </FormField>
        </ColumnLayout>
      </React.Fragment>
    )
    return (
      <Container.Comp
        title="Text question information"
      >
        <ColumnLayout columns={1} borders="horizontal">
          {optionForm}
        </ColumnLayout>
      </Container.Comp>
    )
  }
  const TextQuestionReadOnly = (props: {
    state: QuestionModel.UI.TextQuestionState
  }) => {

    const optionForm = (
      <React.Fragment>
        <ColumnLayout columns={3}>
          <FormField
            label={<span>Reference Type</span>}
          >
            {
              referenceTypeSelectDropDownItems.find((e) =>
                e.id === props.state.option.value.RefType
              ).text || notValidType
            }
          </FormField>
          <FormField
            label={<span>Reference Identifier</span>}
          >
            {props.state.option.value.RefIdentifier}
          </FormField>
        </ColumnLayout>
      </React.Fragment>
    )
    return (
      <Container.Comp
        title="Text question information"
      >
        <ColumnLayout columns={1} borders="horizontal">
          {optionForm}
        </ColumnLayout>
      </Container.Comp>
    )
  }

  const CreateQuestionModal = (props: {
    state: QuestionModel.UI.EditQuestionModalState,
    visible: UIModel.FieldState<boolean>,
  }) => {
    return (
      <Modal
        size="large"
        visible={true}
        onDismiss={() => props.visible.setValue(false)}
        header={"Create a question"}
        footer={
          <>
            <Button
              disabled={!props.state.isComplete.value}
              variant={"primary"}
              onClick={() => {
                let builder = QuestionModel.UI.BasicInfoDataState.toBuilder(props.state.basicInfoState);
                if (props.state.basicInfoState.typeFieldState.value === "SINGLE_SELECTION") {
                  builder = QuestionModel.UI.SingleSelectQuestionState.toBuilder(
                    props.state.singleSelectQuestionState,
                    builder
                  );
                }
                if (props.state.basicInfoState.typeFieldState.value === "MULTI_SELECTION") {
                  builder = QuestionModel.UI.MultipleSelectQuestionState.toBuilder(
                    props.state.multipleSelectQuestionState,
                    builder
                  );
                }
                if (props.state.basicInfoState.typeFieldState.value === "TEXT") {
                  builder = QuestionModel.UI.TextQuestionState.toBuilder(
                    props.state.textSelectQuestionState,
                    builder
                  );
                }
                console.log(`result=${JSON.stringify(builder.build(), null, 2)}`);
                props.state.onSubmit(builder.build());
                props.state.init(new QuestionModel.Data());
              }}
            >Submit</Button>
            <Button
              variant={"link"}
              onClick={() => props.visible.setValue(false)}
            >Cancel</Button>
          </>
        }
      >
        <CreateBasicInfo state={props.state.basicInfoState} />
        {props.state.basicInfoState.typeFieldState.value === "SINGLE_SELECTION" ?
          <SingleSelectQuestionEdit state={props.state.singleSelectQuestionState} /> :
          props.state.basicInfoState.typeFieldState.value === "MULTI_SELECTION" ?
            <MultiSelectQuestionEdit state={props.state.multipleSelectQuestionState} /> :
            props.state.basicInfoState.typeFieldState.value === "TEXT" ?
              <TextQuestionEdit state={props.state.textSelectQuestionState} /> :
              <></>
        }
      </Modal >
    )
  }

  const UpdateQuestionModal = (props: {
    state: QuestionModel.UI.EditQuestionModalState,
    visible: UIModel.FieldState<boolean>,
  }) => {
    return (
      <Modal
        size="large"
        visible={true}
        onDismiss={() => props.visible.setValue(false)}
        header={"Update a question"}
        footer={
          <>
            <Button
              variant={"primary"}
              onClick={() => {
                let builder = QuestionModel.UI.BasicInfoDataState.toBuilder(props.state.basicInfoState);
                if (props.state.basicInfoState.typeFieldState.value === "SINGLE_SELECTION") {
                  builder = QuestionModel.UI.SingleSelectQuestionState.toBuilder(
                    props.state.singleSelectQuestionState,
                    builder
                  );
                }
                if (props.state.basicInfoState.typeFieldState.value === "MULTI_SELECTION") {
                  builder = QuestionModel.UI.MultipleSelectQuestionState.toBuilder(
                    props.state.multipleSelectQuestionState,
                    builder
                  );
                }
                if (props.state.basicInfoState.typeFieldState.value === "TEXT") {
                  builder = QuestionModel.UI.TextQuestionState.toBuilder(
                    props.state.textSelectQuestionState,
                    builder
                  );
                }
                console.log(`result=${JSON.stringify(builder.build(), null, 2)}`);
                props.state.onSubmit(builder.build());
              }}
            >Submit</Button>
            <Button
              variant={"link"}
              onClick={() => props.visible.setValue(false)}
            >Cancel</Button>
          </>
        }
      >
        <BasicInfoEdit state={props.state.basicInfoState} />
        {props.state.basicInfoState.typeFieldState.value === "SINGLE_SELECTION" ?
          <SingleSelectQuestionEdit state={props.state.singleSelectQuestionState} /> :
          props.state.basicInfoState.typeFieldState.value === "MULTI_SELECTION" ?
            <MultiSelectQuestionEdit state={props.state.multipleSelectQuestionState} /> :
            props.state.basicInfoState.typeFieldState.value === "TEXT" ?
              <TextQuestionEdit state={props.state.textSelectQuestionState} /> :
              <></>
        }
      </Modal >
    )
  }

  const ReadOnlyQuestionModal = (props: {
    state: QuestionModel.UI.EditQuestionModalState,
    visible: UIModel.FieldState<boolean>,
    onEdit?: (question: QuestionModel.Data) => void;
  }) => {
    return (
      <Modal
        size="large"
        visible={true}
        onDismiss={() => props.visible.setValue(false)}
        header={`Question: ${props.state.basicInfoState.idFieldState.value}`}
        footer={
          <>
            <Button
              variant={"primary"}
              onClick={() => {
                props.visible.setValue(false)
                if (props.onEdit) {
                  props.onEdit(
                    QuestionModel.UI.EditQuestionModalState.toQuestion(props.state)
                  );
                }
              }}
            >Edit</Button>
            <Button
              variant={"link"}
              onClick={() => props.visible.setValue(false)}
            >Close</Button>
          </>
        }
      >
        <BasicInfoReadOnly state={props.state.basicInfoState} />
        {props.state.basicInfoState.typeFieldState.value === "SINGLE_SELECTION" ?
          <SingleSelectQuestionReadOnly state={props.state.singleSelectQuestionState} /> :
          props.state.basicInfoState.typeFieldState.value === "MULTI_SELECTION" ?
            <MultiSelectQuestionReadOnly state={props.state.multipleSelectQuestionState} /> :
            props.state.basicInfoState.typeFieldState.value === "TEXT" ?
              <TextQuestionReadOnly state={props.state.textSelectQuestionState} /> :
              <></>
        }
      </Modal >
    )
  }

  const SubmitConfirmModal = (props: {
    onSubmit: () => Promise<void>,
    visible: UIModel.FieldState<boolean>,
  }) => {
    const loading = UIModel.FieldState.use({ initialValue: false });
    return (
      <Modal
        visible={true}
        onDismiss={() => props.visible.setValue(false)}
        header={"Are you sure?"}
        footer={
          <>
            <Button
              loading={loading.value}
              variant="primary"
              onClick={async () => {
                loading.setValue(true);
                await props.onSubmit();
                loading.setValue(false);
                props.visible.setValue(false);
              }}
            >Confirm</Button>
            <Button
              variant={"link"}
              onClick={() => props.visible.setValue(false)}
            >Cancel</Button>
          </>
        }
      >
        <p>Please review all changes for accuracy once changes are submitted the action cannot be un-done.</p>
      </Modal >
    )
  }

  export const ReadOnlyQuestionModalById = (props: {
    item: QuestionModel.Data
  }) => {

    const visible = UIModel.FieldState.use({ initialValue: false });
    const state = QuestionModel.UI.EditQuestionModalState.use({});

    React.useEffect(() => {
      if (props.item) state.init(props.item);
    }, [])

    return (
      <React.Fragment>
        <a target="_blank" onClick={() => visible.setValue(true)}>{props.item.Id}</a>
        {visible.value && <Modal
          size="large"
          visible={true}
          onDismiss={() => visible.setValue(false)}
          header={`Question: ${state.basicInfoState.idFieldState.value}`}
          footer={
            <>
              <Button
                variant={"link"}
                onClick={() => visible.setValue(false)}
              >Close</Button>
            </>
          }
        >
          <BasicInfoReadOnly state={state.basicInfoState} />
          {state.basicInfoState.typeFieldState.value === "SINGLE_SELECTION" ?
            <SingleSelectQuestionReadOnly state={state.singleSelectQuestionState} /> :
            state.basicInfoState.typeFieldState.value === "MULTI_SELECTION" ?
              <MultiSelectQuestionReadOnly state={state.multipleSelectQuestionState} /> :
              state.basicInfoState.typeFieldState.value === "TEXT" ?
                <TextQuestionReadOnly state={state.textSelectQuestionState} /> :
                <></>
          }
        </Modal >}
      </React.Fragment>
    )
  }

  export const QuestionPage = () => {
    const questionPageState = QuestionModel.QuestionPageState.use({});
    const onEdit = (question: QuestionModel.Data) => {
      questionPageState.editQuestionModalState.init(question);
      questionPageState.editQuestionVisible.setValue(true);
    }

    const questionTable = <QuestionTable
      items={questionPageState.questionTableState.items.value}
      selectionType="single"
      onSelect={(selected) => questionPageState.questionTableState.selectedItems.setValue(selected)}
      selected={questionPageState.questionTableState.selectedItems.value}
      visibleContent={['Id', 'DisplayText', 'Description', 'LastUpdatedDate', 'LastUpdatedBy', 'Version']}
      header={<TableHeader
        title="Question table"
        counter={`${questionPageState.questionTableState.items.value.length}`}
        actionButtons={[
          <Button
            disabled={questionPageState.questionTableState.selectedItems.value.length === 0}
            variant="normal"
            onClick={() => {
              questionPageState.readOnlyQuestionModalState.init(
                questionPageState.questionTableState.selectedItems.value[0] as QuestionModel.Data
              )
              questionPageState.readOnlyQuestionVisible.setValue(true)
            }}
          >
            View detail
          </Button>,
          <Button
            variant="primary"
            onClick={() => questionPageState.createQuestionVisible.setValue(true)}
          >
            Create
          </Button>,
          <Button
            disabled={questionPageState.questionTableState.selectedItems.value.length === 0}
            onClick={() => {
              questionPageState.editQuestionModalState.init(
                questionPageState.questionTableState.selectedItems.value[0] as QuestionModel.Data
              )
              questionPageState.editQuestionVisible.setValue(true)
            }}
          >Edit</Button>,
          <Button
            disabled={questionPageState.questionTableState.selectedItems.value.length === 0}
            onClick={() => {
              const targetQuestion = questionPageState.questionTableState.selectedItems.value[0] as QuestionModel.Data;
              const newDraftQuestions = questionPageState.draftQuestions.value.filter(q => q.Id !== targetQuestion.Id);
              questionPageState.draftQuestions.setValue(
                newDraftQuestions
              )
            }}
          >Delete</Button>
        ]} />}
    />

    const orphanTable = <QuestionTable
      items={questionPageState.orphanQuestionsTableState.items.value}
      header={<TableHeader
        title="Orphan Questions table"
        counter={`${questionPageState.orphanQuestionsTableState.items.value.length}`}
        actionButtons={[]}
      />}
      visibleContent={['Id', 'DisplayText', 'Description', 'status']}
    />

    const summaryTable = <QuestionTable
      items={questionPageState.updateSummaryTableState.items.value}
      header={
        <TableHeader
          title="Update summary table"
          actionButtons={[<Button
            variant="primary"
            disabled={
              !(questionPageState.updateSummaryTableState.items.value.length > 0)
            }
            onClick={() => questionPageState.submitConfirmModalVisible.setValue(true)}
          >
            Submit</Button>
          ]} />
      }
      visibleContent={['Id', 'DisplayText', 'Description', 'operation', 'silentQuestionUpdate']}
    />

    return (
      <React.Fragment>
        {questionPageState.createQuestionVisible.value &&
          <CreateQuestionModal
            state={questionPageState.createQuestionModalState}
            visible={questionPageState.createQuestionVisible}
          />}
        {questionPageState.editQuestionVisible.value &&
          <UpdateQuestionModal
            state={questionPageState.editQuestionModalState}
            visible={questionPageState.editQuestionVisible}
          />}
        {questionPageState.readOnlyQuestionVisible.value &&
          <ReadOnlyQuestionModal
            state={questionPageState.readOnlyQuestionModalState}
            visible={questionPageState.readOnlyQuestionVisible}
            onEdit={onEdit}
          />}
        {questionPageState.submitConfirmModalVisible.value &&
          <SubmitConfirmModal visible={questionPageState.submitConfirmModalVisible} onSubmit={
            async () => await questionPageState.updateSummaryTableState.onSubmit(
              questionPageState.updateSummaryTableState.items.value
            )} />}
        <div style={{ padding: '2rem' }} >
          <ColumnLayout columns={1}>
            {questionTable}
            {orphanTable}
            {summaryTable}
          </ColumnLayout>
        </div>
      </React.Fragment >
    )
  }

  const QuestionSelectFromDraftQuestions = (props: { onChange: (question: QuestionModel.Data) => void, value: string }) => {
    const draftQuestionList = UIModel.FieldState.use<QuestionModel.Data[]>({
      initialValue: []
    });
    const [autosuggestValue, setAutosuggestValue] = React.useState("");

    React.useEffect(() => {
      draftQuestionList.setValue(QuestionModel.QuestionPageState.draftQuestions);
    }, [QuestionModel.QuestionPageState.draftQuestions])

    return <Autosuggest
      enteredTextLabel={value => `Search by ${value}`}
      value={autosuggestValue || props.value}
      onChange={(event) => {
        setAutosuggestValue(event.detail.value);
      }}
      onSelect={(event) => {
        props.onChange(QuestionModel.QuestionPageState.draftQuestions.filter(q => q.Id === event.detail.value)[0]);
      }}
      placeholder="Enter a question id"
      empty="No matching id found"
      options={draftQuestionList.value.map(q => { return { "value": q.Id, "label": `[${q.Id}] : ${q.DisplayText.slice(0, 60)}...` } })}
    ></Autosuggest>
  }
}