import * as React from 'react';

function validURL(input: string) {  
  let url;
  
  try {
    url = new URL(input);
  } catch (e) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export const TextLink = (text: string) => {
  if (validURL(text)) {
    return (
      <a href={text} target="_blank">{text}</a>
    )
  } else {
    return <span>{text}</span>
  }
  
}