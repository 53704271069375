import * as React from 'react'
import { useState, useEffect } from 'react'
import { Button, ColumnLayout, DatePicker, Header, Input, Spinner, Table } from '@amzn/awsui-components-react-v3';
import { getTimezoneIndependentDate } from '../utils/sharedFunctions';
import { getAllBaselinePeriods, addBaselinePeriods } from '../actions/async.action'
import { checkIsALTAR } from '../actions/authorization.action'
import { DateTimeFormatter } from '../components/shared/DateTimeFormatter';

interface BaselinePeriod {
  Year: number;
  Quarter: number;
  StartDate: Date;
  EndDate: Date;
  EscalationDateManager: Date;
  ReminderEscalationDateManager: Date;
  EscalationDateVP: Date;
  ReminderEscalationDateVP: Date;
  KickoffEmailSent: boolean;
  TenBusinessDaysBeforeStartDate: Date;
}

const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
const formatDate = (utcDate: Date) => {
  if (!utcDate) {
    return "-"
  }
  return new Date(getTimezoneIndependentDate(utcDate)).toLocaleDateString('en-US', options);
}

const columnDefinitions = [
  {
    id: 'Year',
    header: 'Year',
    cell: (period: BaselinePeriod) => period.Year
  },
  {
    id: 'Quarter',
    header: 'Quarter',
    cell: (period: BaselinePeriod) => period.Quarter
  },
  {
    id: 'StartDate',
    header: 'StartDate',
    cell: (period: BaselinePeriod) => formatDate(period.StartDate)
  },
  {
    id: 'EscalationDateManager',
    header: 'Escalation to manager date',
    cell: (period: BaselinePeriod) => formatDate(period.EscalationDateManager)
  },
  {
    id: 'ReminderEscalationDateManager',
    header: 'Escalation to manager reminder date',
    cell: (period: BaselinePeriod) => formatDate(period.ReminderEscalationDateManager)
  },
  {
    id: 'EscalationDateVP',
    header: 'Escalation to VP date',
    cell: (period: BaselinePeriod) => formatDate(period.EscalationDateVP)
  },
  {
    id: 'ReminderEscalationDateVP',
    header: 'Escalation to VP reminder date',
    cell: (period: BaselinePeriod) => formatDate(period.ReminderEscalationDateVP)
  },
  {
    id: 'EndDate',
    header: 'End date',
    cell: (period: BaselinePeriod) => formatDate(period.EndDate)
  },
  {
    id: 'TenBusinessDaysBeforeStartDate',
    header: '10 business days before start date',
    cell: (period: BaselinePeriod) => formatDate(period.TenBusinessDaysBeforeStartDate)
  },
]

const commonDatePickerProps = {
  nextMonthAriaLabel: "Next month",
  placeholder: "YYYY/MM/DD",
  previousMonthAriaLabel: "Previous month",
  todayAriaLabel: "Today",
  openCalendarAriaLabel: selectedDate =>
    "Choose certificate expiry date" +
    (selectedDate
      ? `, selected date is ${selectedDate}`
      : "")
}

const UpdateBaselinePeriods = () => {
  const [items, setItems] = useState<BaselinePeriod[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [isAltar, setIsAltar] = useState(false)
  const [newPeriod, setNewPeriod] = useState<BaselinePeriod>({} as BaselinePeriod)

  useEffect(() => {
    getAllBaselinePeriods().then((res) => {
      setItems(res.data)
      setLoading(false)
    })
    checkIsALTAR().then((res) => {
      setIsAltar(res.data)
    })
  }, [])

  const onPeriodChange = (date) => {
    setNewPeriod({ ...newPeriod, ...date })
  }

  const onSave = async () => {
    addBaselinePeriods([{ ...newPeriod }])
      .then((res) => setItems(res.data))
      .catch((error) => console.log(error))
  }

  return (
    <div>
      {!isAltar &&
        <div><Spinner />
          <p>If you are not a member of ALTAR, this page is not accessible. Click <a href='/#/'>here</a> to go to the home page</p>
        </div>
      }
      {isAltar &&
        <div className="awsui-util-m-xl">
          <Table
            columnDefinitions={columnDefinitions}
            items={items}
            loading={loading}
            loadingText="Loading baseline periods"
            header={
              <Header variant="h2">
                Baseline periods
              </Header>
            }
            empty={<div className="awsui-util-t-c">
              <b>No baseline period</b>
            </div>}
          />
          <div className="awsui-util-container awsui-util-mt-l">
            <div className="awsui-util-container-header">
              <Header variant="h2"
                actions={
                  <Button onClick={() => onSave()} variant="primary">Save</Button>
                }
              >
                Baseline periods
              </Header>
            </div>
            <ColumnLayout columns={4}>
              <div>
                <p>Year</p>
                <Input
                  value={`${newPeriod.Year}`}
                  onChange={(event) => onPeriodChange({ Year: parseInt(event.detail.value) })}
                  type='number'
                />
              </div>
              <div>
                <p>Quarter</p>
                <Input
                  value={`${newPeriod.Quarter}`}
                  onChange={(event) => onPeriodChange({ Quarter: parseInt(event.detail.value) })}
                  type='number'
                />
              </div>
              <div>
                <p>Start date</p>
                <DatePicker
                  {...commonDatePickerProps}
                  value={DateTimeFormatter.toDatePickerValueString(newPeriod.StartDate)}
                  onChange={(event) => onPeriodChange({ StartDate: DateTimeFormatter.parseDatePickerValue(event.detail.value) })}
                />
              </div>
              <div>
                <p>Escalation to manager date</p>
                <DatePicker
                  {...commonDatePickerProps}
                  value={DateTimeFormatter.toDatePickerValueString(newPeriod.EscalationDateManager)}
                  onChange={(event) => onPeriodChange({ EscalationDateManager: DateTimeFormatter.parseDatePickerValue(event.detail.value) })}
                />
              </div>
              <div>
                <p>Escalation to manager reminder date</p>
                <DatePicker
                  {...commonDatePickerProps}
                  value={DateTimeFormatter.toDatePickerValueString(newPeriod.ReminderEscalationDateManager)}
                  onChange={(event) => onPeriodChange({ ReminderEscalationDateManager: DateTimeFormatter.parseDatePickerValue(event.detail.value) })}
                />
              </div>
              <div>
                <p>Escalation to VP date</p>
                <DatePicker
                  {...commonDatePickerProps}
                  value={DateTimeFormatter.toDatePickerValueString(newPeriod.EscalationDateVP)}
                  onChange={(event) => onPeriodChange({ EscalationDateVP: DateTimeFormatter.parseDatePickerValue(event.detail.value) })}
                />
              </div>
              <div>
                <p>Escalation to VP reminder date</p>
                <DatePicker
                  {...commonDatePickerProps}
                  value={DateTimeFormatter.toDatePickerValueString(newPeriod.ReminderEscalationDateVP)}
                  onChange={(event) => onPeriodChange({ ReminderEscalationDateVP: DateTimeFormatter.parseDatePickerValue(event.detail.value) })}
                />
              </div>
              <div>
                <p>End date</p>
                <DatePicker
                  {...commonDatePickerProps}
                  value={DateTimeFormatter.toDatePickerValueString(newPeriod.EndDate)}
                  onChange={(event) => onPeriodChange({ EndDate: DateTimeFormatter.parseDatePickerValue(event.detail.value) })}
                />
              </div>
              <div>
                <p>10 business days before start date</p>
                <DatePicker
                  {...commonDatePickerProps}
                  value={DateTimeFormatter.toDatePickerValueString(newPeriod.TenBusinessDaysBeforeStartDate)}
                  onChange={(event) => onPeriodChange({ TenBusinessDaysBeforeStartDate: DateTimeFormatter.parseDatePickerValue(event.detail.value) })}
                />
              </div>
            </ColumnLayout>
          </div>
        </div>
      }
    </div>
  )
}

export default UpdateBaselinePeriods