import * as React from 'react';
import { Box, ColumnLayout, Container, FormField, Grid, Icon } from '@amzn/awsui-components-react-v3';
import '../styles/landing-page.scss';
import PmItemsTable from './pm-item-table'
import Timeline from '../components/timeline/timeline';
import { getFormattedQuarterYear } from "../utils/sharedFunctions";
import { saveUserActivity, UserActivityAction } from "../actions/userActivity.action";
import { ItemAutoSuggest } from "../components/shared/ItemAutoSuggest";
import { getBaselinePeriod } from '../actions/async.action'

/*
  Duplicating type definition form
  https://code.amazon.com/packages/CRIPTAppLambda/blobs/970f528bc3e0fb1f2f8ef9a39add2dd5a1f8f9dd/--/src/criptapplambda/baselinePeriods/BaselinePeriod.Mapper.ts#L11-L22
  Remove if CRIPT types module becomes availalble
*/
export interface BaselinePeriod {
  Year: number;
  Quarter: number;
  StartDate: Date;
  EndDate: Date;
  EscalationDateManager: Date;
  ReminderEscalationDateManager: Date;
  EscalationDateVP: Date;
  ReminderEscalationDateVP: Date;
  KickoffEmailSent: boolean;
  TenBusinessDaysBeforeStartDate: Date;
}

export const Landing = () => {
  const [baselinePeriod, setBaselinePeriod] = React.useState<BaselinePeriod>();
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    setLoading(true);
    getBaselinePeriod().then((res) => {
        setBaselinePeriod(res.data);
        setLoading(false);
    }).then(() => {
      setLoading(false);
    })
  }, [])
  
  const displayHeaderText = ():string => {
    if (loading) {
      return ""
    } else if (baselinePeriod) {
      return `Current quarterly baseline period: ${getFormattedQuarterYear(new Date(baselinePeriod.StartDate))}`;
    } else {
      return `Next quarterly baseline period: ${getFormattedQuarterYear(new Date())}`;
    }
  }

  return (
    <>
      <Box
        padding={{bottom: "xxl", top: "xl"}}
        margin={{top: "m"}}
        className="landing-header">
        <Grid
          gridDefinition={[{colspan: 5, offset: { default: 2 }}, {colspan: 3}]}
        >
          <Box padding="xs">
            <div className="landing-header-title">
              <h1>CRIPT</h1>
              <h3>AWS Item Classification/Regulation Item and Product Tool</h3>
              <p>
                This tool helps manage our compliance with export control laws, which regulate the physical and electronic transmission of software, hardware, and technology (“Items”) across international borders and/or to foreign nationals within a country. Items include our company’s items and services.
              </p>
            </div>
            </Box>
            <Box
              padding="l"
              margin={{top: "l"}}
              className="item-search-box"
            >
              <h2>Search for an Item</h2>
              <FormField stretch={true}
                label={<em>Use the box below or <a href="/#/items">All Items</a> to search for an existing CRIPT Item. Click on "Create Item" to start a new CRIPT Item form.</em>}
              >
                <ItemAutoSuggest
                  onSelect={(selectedId) => {
                    window.location.assign('/#/items/' + selectedId)
                  }}
                  onFocus={() => {
                    saveUserActivity({
                      action: UserActivityAction.SEARCH_HOMEPAGE,
                      itemId: ""
                    })
                  }}
                />
              </FormField>
            </Box>
        </Grid>
      </Box>
      <Box margin={{top: "xxxl"}}>
        <Grid
          gridDefinition={[{colspan: 5, offset: { default: 2 }}, {colspan: 3}]}
        >
          <Container header={<h3>{displayHeaderText()}</h3>}>
            <ColumnLayout columns={2} >
              <div>
                <Timeline
                  loading={loading}
                  baselinePeriod={baselinePeriod}
                />
              </div>
              <div className='timeline-text'>
                <h4>About the baseline process</h4>
                <p>The baseline process helps Export Compliance ensure that your export classification information accurately reflects the current scope of your Item's technology and usage. You will be notified at the beginning of the baseline period if your Item requires baseline. </p>
                <br />
                <p>To baseline, verify the accuracy of the information in the Overview and Questionnaire tabs and update as needed. Then click on the Baseline button. Your Item questionnaire must be completed prior to baseline.</p>
                <br />
                For more information about the baseline process, see the
                <a href="https://w.amazon.com/bin/view/CRIPTTool/"> FAQs <Icon name='external' /></a>
              </div>
            </ColumnLayout>
          </Container>
          <PmItemsTable />
        </Grid>
      </Box>
    </>
  )
};