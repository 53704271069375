import * as React from 'react';
import { useState } from 'react';
import { Pane } from './HelpPane.constants'
import DetailsPane from './detailsPane/detailsPane'
import HistoryPane from './versionHistoryPane/historyPane'
import QuestionnairePane from './questionnairePane/QuestionnairePane'
import { HelpPanel } from '@amzn/awsui-components-react-v3';
const useHelpPane = () => {

    const emptyPane = <HelpPanel></HelpPanel>
    const [pane, setPane] = useState(emptyPane)
    const [hide, setHide] = useState(true)
    const [open, setOpen] = useState(false)

    const { reducer: questionnairePaneReducer, currentTerms } = QuestionnairePane(setPane, setHide, setOpen)
    const { reducer: detailsPaneReducer } = DetailsPane(setPane, setHide, setOpen)
    const { reducer: historyPaneReducer } = HistoryPane(setPane, setHide, setOpen)

    const clear = () => {
        setHide(true)
        setPane(emptyPane)
        setOpen(false)
    }

    const dispatch = (load: { pane: string, type: string, payload: string }) => {
        const { pane } = load
        switch (pane) {
            case Pane.QuestionPane:
                questionnairePaneReducer(load)
                break;

            case Pane.DetailsPane:
                detailsPaneReducer(load)
                break;

            case Pane.HistoryPane:
                historyPaneReducer(load)
                break;

            default:
                break;
        }
    }

    return {
        clear,
        dispatch,
        pane,
        hide,
        open,
        currentTerms,
    }
}

export default useHelpPane;
export const HelpPaneContext = React.createContext(
    {
        clear: () => { },
        dispatch: (input: {
            pane: string,
            type: string,
            payload: any
        }) => { },
        pane: <div></div>,
        hide: true,
        open: false,
        currentTerms: [],
    }
);