import * as React from "react";
import { Header, SpaceBetween } from '@amzn/awsui-components-react-v3';
export namespace Container {
  export const Comp = (props: {
    title: string;
    onTitleInfoClicked?: () => void;
    buttons?: JSX.Element[];
    children?: React.ReactNode;
  }) => {
    return (
      <div className="awsui-util-container">
        <Header
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              {props.buttons}
            </SpaceBetween>
          }
        >
          <h2 className="awsui-util-d-ib">{props.title}</h2>
          {props.onTitleInfoClicked &&
            <a
              className="awsui-util-help-info-link"
              href="javascript:void(0);"
              onClick={props.onTitleInfoClicked}>
              Info
            </a>
          }
        </Header>
        {props.children}
      </div>
    );
  };
}
