
export const Set_AppConfig = "Set_AppConfig";
export const GET_CONFIG_FAILURE = "GET_CONFIG_FAILURE";

export function getAppConfig() {
}

export function setAppConfig(configObj:any) {
  return dispatch => {
    dispatch(setAppConfigObj(configObj));
  }
}

export function setAppConfigObj(configObj:any) {
  return {
      type: Set_AppConfig,
      payload: configObj
  }
}
