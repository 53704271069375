import * as React from 'react'
import { HelpPanel, Icon } from '@amzn/awsui-components-react-v3';
export const exemptionInfoPane = () => {

    return (
        <HelpPanel
            header={<h3>Baseline exemption rule</h3>}
            footer={
                <div>
                    <h3>
                        Learn more <Icon name="external" />
                    </h3>
                    <ul>
                        <li>
                            <a href="https://w.amazon.com/bin/view/ALTAR/tools/CRIPT/FAQs/" target='_blank'>CRIPT FAQs</a>
                        </li>
                        <li>
                            <a href="https://w.amazon.com/bin/view/CRIPTTool" target='_blank' >Export Compliance</a>
                        </li>
                    </ul>
                </div>
            }
        >
            <ul>
                <li>If Item is created within 10 business days prior to the baseline start date</li>
                <li>If Item is created between baseline start date and baseline end date</li>
                <li>If Item is "deprecated" - user has clicked on Deprecate action button</li>
                <li>If Item has Pending Export Compliance Review as status</li>
                <li>If Item has an ECCN assigned by a member of the Export Compliance team, and the ECCN was assigned within 10 business days prior to the baseline start date.</li>
                <li>Export Compliance determines your Item does not require baseline for some other reason</li>
            </ul>
        </HelpPanel>

)
}