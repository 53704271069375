import * as React from 'react'
import { HelpPanel, Icon } from '@amzn/awsui-components-react-v3';

export const infoPane = ( appearedTerms ) => {
  return (
    <HelpPanel
      header={<h3>Questionnaire</h3>}
      footer={
        <div>
          <h3>
            Learn more <Icon name="external" />
          </h3>
          <ul>
            <li>
              <a href="https://w.amazon.com/bin/view/ALTAR/tools/CRIPT/FAQs/" target='_blank'>CRIPT FAQs</a>
            </li>
            <li>
              <a href="https://w.amazon.com/bin/view/CRIPTTool" target='_blank' >Export Compliance</a>
            </li>
          </ul>
        </div>
      }
    >
      <p>The answers to this questionnaire will help Export Compliance provide an Export Control Classification Number (“ECCN”). In most cases, your ECCN can be automatically derived based on your responses. Terms with additional information appear in blue in the questionnaire. Click on the term to view the additional information.</p>
      <br />
      <h3>Terms</h3>
      <dl>
        {appearedTerms && appearedTerms.length > 0 && appearedTerms.map((term) => {
          return (
            <div>
              <dt>{term.Name}</dt>
              <dd>{term.Description} </dd>
            </div>)
        })}
      </dl>
    </HelpPanel>
  )
}