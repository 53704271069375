import { store } from "../main";
const apigClientFactory = require('aws-api-gateway-client').default;



export const apiGatewayCogntioClient = () => {

    const appState = store.getState();

    const appConfig = appState.configObj.configObj;

    let apiConfig = {
        invokeUrl: appConfig.API.MainAPI, // REQUIRED
        region: 'us-west-2', // REQUIRED: The region where the API is deployed.
        retries: 4,
    };
    return apigClientFactory.newClient(apiConfig);
};