import * as React from 'react';
import { Alert, Flashbar, SideNavigation, Icon } from '@amzn/awsui-components-react-v3';

export const FlashMessage = () => (
  <Flashbar items={[
      {type: "success", content:"Resource created successfully", dismissLabel: "Dismiss message", dismissible: true}
    ]}
  />
);

export class Navigation extends React.Component<{ isAdmin: boolean, isDirty: boolean, onFollowHandler: any, toggl: any }, {}> {
  constructor(props) {
    super(props);
  }

  header = { text: 'CRIPT', href: '#/' };

  itemsv4 = [
    { type: 'link', text: 'Create Item', href: '#/items/create' },
    { type: 'link', text: 'All Items', href: '#/items' },
    { type: "divider" },
    { type: 'link', text: 'About', href: 'https://w.amazon.com/bin/view/ALTAR/tools/CRIPT/', external: true },
    { type: 'link', text: 'CRIPT FAQs', href: 'https://w.amazon.com/bin/view/ALTAR/tools/CRIPT/FAQs/', external: true },
    { type: 'link', text: 'Export Compliance ', href: 'https://w.amazon.com/bin/view/CRIPTTool/', external: true },
    { type: 'link', text: 'Report a Bug', href: 'https://t.corp.amazon.com/create/templates/5c902744-82da-41f1-a7cd-683e995b3ea7', external: true },
    {
      "type": "section",
      "text": "Ask a Question",
      "items": [
        { type: 'link', text: 'Compliance Question', href: 'https://issues.amazon.com/issues/create?template=c3527055-5d0b-45d6-b4d2-43d77a65e345', external: true },
        {
          type: 'link',
          text: 'Accessibility Question',
          href: 'https://t.corp.amazon.com/create/options?category=Legal&type=AWS%20Products%20%26%20Services&item=General%20Questions&group=AWS%20Products%20Legal',
          external: true
        }
      ]
    }
  ];

  itemsAdminv4 = [
    { type: 'link', text: 'Create Item', href: '#/items/create' },
    { type: 'link', text: 'All Items', href: '#/items' },
    { type: 'link', text: 'Item compliance', href: '#/dashboard/admin-compliance' },
    { type: 'link', text: 'Keywords', href: '#/keywords' },
    { type: "divider" },
    { type: 'link', text: 'About', href: 'https://w.amazon.com/bin/view/ALTAR/tools/CRIPT/', external: true },
    { type: 'link', text: 'CRIPT FAQs', href: 'https://w.amazon.com/bin/view/ALTAR/tools/CRIPT/FAQs/', external: true },
    { type: 'link', text: 'Export Compliance ', href: 'https://w.amazon.com/bin/view/CRIPTTool/', external: true },
    { type: 'link', text: 'Report a Bug', href: 'https://t.corp.amazon.com/create/templates/5c902744-82da-41f1-a7cd-683e995b3ea7', external: true },
    {
      "type": "section",
      "text": "Ask a Question",
      "items": [
        { type: 'link', text: 'Compliance Question', href: 'https://issues.amazon.com/issues/create?template=c3527055-5d0b-45d6-b4d2-43d77a65e345', external: true },
        {
          type: 'link',
          text: 'Accessibility Question',
          href: 'https://t.corp.amazon.com/create/options?category=Legal&type=AWS%20Products%20%26%20Services&item=General%20Questions&group=AWS%20Products%20Legal',
          external: true
        }
      ]
    }
  ];


  render() {
    let targetItem: any;
    if (this.props.isAdmin) {
      targetItem = this.itemsAdminv4;
    } else {
      targetItem = this.itemsv4;
    }
    return (
      <SideNavigation
        items={targetItem}
        header={this.header}
        onFollow={this.props.onFollowHandler}
      />
    );
  }
}

export const AssignedQuestionsLink = ({ onClick, id }) => (
  <a id={id} className="awsui-util-help-info-link" href="javascript:void(0);" onClick={onClick}>
    Assigned Questions
  </a>
);

export class DevSameOriginWarning extends React.Component {
  render() {
    const { hostname, port, protocol } = document.location;
    const sameOrigin = !port && protocol == 'https:' && /.amazon.com$/.test(hostname);

    if (!sameOrigin) {
      return <Alert
        className="awsui-util-mb-l"
        header="You need to host this page in compliance with same-origin policy"
        type="error"
      >
        <span>
          The dashboard will not work properly unless the page is hosted:
          <ul>
            <li>over https</li>
            <li>on port 443</li>
            <li>on amazon.com subdomain</li>
          </ul>
            Use startHttps script <code>sudo npm run startHttps</code> from examples package to achieve this
        </span>
      </Alert>
    }
    return null;
  }
}
