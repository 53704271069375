import { useState } from 'react';
import * as React from 'react'
import { Flashbar } from '@amzn/awsui-components-react-v3';
import { Item } from '../models/item-model';
import { Message } from '../models/item-detail-model';
import { submitItem } from '../actions/async.action';

const bcItemCreate = [
    {
        "text": "Home",
        "href": "#"
    },
    {
        "text": "Create Item",
        "href": "",
    },
]

const useItemCreate = () => {

    const [itemInfo, setItemInfo] = useState(Item.Data.getDefaultData());
    const [errorMessages, setErrorMessages] = useState([])
    const [loading, setLoading] = useState(true)
    const [showError, setShowError] = useState(false)
    const [itemId, setItemId] = useState("")

    const onItemInfoChange = async (updateItemInfo) => {
        setItemInfo(
            {
                ...itemInfo,
                ...updateItemInfo,
            }
        );
    }

    const onNewSubmit = () => {
        const newItem = {
            ...itemInfo
        }
        delete newItem.QA
        submitItem(newItem)
            .then((res) => {
                setItemId(res.data.Id)
                setLoading(false)
            })
            .catch((error) => {
                if (error.response.data) {
                    console.log(error.response.data)
                    setShowError(true)
                    setErrorMessages(error.response.data.errors)
                }
            })
    };

    const displayMessages = (error: Message[]) => {
        if (error.length > 0) {
            let bannerErrors = error.filter((e) => e.field === 'banner');
            if (bannerErrors.length) {
                return <Flashbar items={bannerErrors.map((e) => {return {type: e.type, dismissible: true, header: e.message}})} />
            }
        }
    }

    const flashMessages = (
        <div>
            {displayMessages(errorMessages)}
        </div>)


    return {
        itemInfo,
        loading,
        showError,
        flashMessages,
        errorMessages,
        bcItemCreate,
        setErrorMessages,
        setShowError,
        setLoading,
        onItemInfoChange,
        onNewSubmit,
        itemId,
    };
}

export default useItemCreate