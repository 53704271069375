import { useState, useEffect } from 'react';
import { getItem } from '../actions/async.action';
import { Item } from '../models/item-model';
import { GeneralHooks } from './general-hooks';

export function useItemFullName(itemId: string) {
  const [ItemFullName, setItemFullName] = useState(itemId);

  const getItemRunner = GeneralHooks.useAnyRunner<string, {data: {Item: Item.Data}}, any>(getItem);

  useEffect(() => {
    getItemRunner.submitRun(itemId)
  }, [itemId])
  
  useEffect(() => {
    if (getItemRunner.status === "Running" || getItemRunner.status === "New") {
      return;
    }
    if (getItemRunner.status === "Finished") {
      setItemFullName(getItemRunner.data.data.Item.ItemFullName)
    }
  }, [getItemRunner])

  return ItemFullName || itemId;
}