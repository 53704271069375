import { BreadcrumbGroupProps, FlashbarProps } from "@amzn/awsui-components-react-v3"
import { Builder } from "builder-pattern";
import React = require("react");
import { getEmployeeSummary,  getItemHistoryByIdAndVersion } from "../actions/async.action";
import { checkAdminStatus } from "../actions/authorization.action";
import { store } from "../main";
import { ItemDetailModel, Message } from "./item-detail-model";
import { Item } from "./item-model";
import { UIModel } from "./ui-model";

export namespace ItemHistoryModel {
  export namespace UI {
    export class SnapShotState {
      isAdmin: UIModel.FieldState<boolean>;
      isContributor: UIModel.FieldState<boolean>;
      itemState: UIModel.FieldState<Item.Data>;
      loading: UIModel.FieldState<boolean>;
      breadcrumb: UIModel.FieldState<BreadcrumbGroupProps.Item[]>;
      flashbarItems: UIModel.FieldState<Array<FlashbarProps.MessageDefinition>>;

      summaryState: ItemDetailModel.UI.Container.SummaryState;
      contactState: ItemDetailModel.UI.Container.ContactState;
      detailState: ItemDetailModel.UI.Container.DetailState;
      amazonDependencyState: ItemDetailModel.UI.Container.AmazonDependencyState;
      thirdPartyDependencyState: ItemDetailModel.UI.Container.ThirdPartyDependencyState;

      questionnaireState: ItemDetailModel.UI.Container.QuestionnaireState;


      static use(props: { id: string, version: number }): SnapShotState {
        const isAdmin = UIModel.FieldState.use({ initialValue: false });
        const isContributor = UIModel.FieldState.use({ initialValue: false });
        const itemState = UIModel.FieldState.use<Item.Data>({ initialValue: Item.Data.getDefaultData() });
        const errorMessages = UIModel.FieldState.use<Message[]>({ initialValue: [] });
        const loading = UIModel.FieldState.use<boolean>({ initialValue: false });
        const flashbarItems = UIModel.FieldState.use<Array<FlashbarProps.MessageDefinition>>(
          { initialValue: [] }
        );

        const summaryState = ItemDetailModel.UI.Container.SummaryState.use({ onSave: async (p: Partial<Item.Data>) => { }, template: itemState });
        const contactState = ItemDetailModel.UI.Container.ContactState.use({ onSave: async (p: Partial<Item.Data>) => { }, template: itemState });
        const detailState = ItemDetailModel.UI.Container.DetailState.use({ onSave: async (p: Partial<Item.Data>) => { }, template: itemState });
        const amazonDependencyState = ItemDetailModel.UI.Container.AmazonDependencyState.use({ onSave: async (p: Partial<Item.Data>) => { }, template: itemState });
        const thirdPartyDependencyState = ItemDetailModel.UI.Container.ThirdPartyDependencyState.use({ onSave: async (p: Partial<Item.Data>) => { }, template: itemState });
        const relatedDocuemntState = ItemDetailModel.UI.Container.RelatedDocumentState.use({ onSave: async (p: Partial<Item.Data>) => { }, template: itemState });
        const questionnaireState = ItemDetailModel.UI.Container.QuestionnaireState.use({ onSave: async (p: Partial<Item.Data>) => { }, template: itemState });

        const init = async (id: string, version: number) => {
          try {
            loading.setValue(true);
            const res = await getItemHistoryByIdAndVersion(id, version) as { data: Item.Data };
            const itemData = res.data;
            if (itemData.Pm && (!itemData.PmCostCenterId || !itemData.PmName)) {
              const pmSummary = await getEmployeeSummary(itemData.Pm);
              itemData.PmCostCenterId = pmSummary.data.costCenterId;
            }
            itemState.setValue({
              ...itemState.value,
              ...itemData,
            });
            loading.setValue(false);
          } catch (error) {
            loading.setValue(false);
            if (error.response) {
              errorMessages.setValue(error.response.data.errors);
            } else {
              errorMessages.setValue([{ type: "error", message: `${JSON.stringify(error)}`, field: "banner" }]);
            }
          }

          try {
            const res = await checkAdminStatus() as { data: boolean };
            isAdmin.setValue(res.data);
          } catch (error) {
            errorMessages.setValue([{ type: "error", message: `${JSON.stringify(error)}`, field: "banner" }]);
          }
        }

        React.useEffect(() => {
          if (props.id) {
            init(props.id, props.version);
          }
        }, []);

        React.useEffect(() => {
          if (itemState.value && itemState.value.Id) {
            console.log(`itemstate=${JSON.stringify(itemState.value, null, 2)}`)
            summaryState.init(itemState.value);
            contactState.init(itemState.value);
            detailState.init(itemState.value);
            amazonDependencyState.init(itemState.value);
            thirdPartyDependencyState.init(itemState.value);
            relatedDocuemntState.init(itemState.value);
            questionnaireState.init(itemState.value);
          }
        }, [itemState.value]);

        React.useEffect(() => {
          summaryState.errorMessage.setValue(errorMessages.value);
        }, [errorMessages.value]);

        React.useEffect(() => {
          const alias = store.getState().authenticationObj.userDetails.alias;
          isContributor.setValue(
            alias === itemState.value.Pm ||
            alias === itemState.value.BusinessLineAttorney ||
            -1 !== itemState.value.OtherContributors.findIndex(contributor => contributor.Alias === alias)
          );
        }, [
          itemState.value.Pm,
          itemState.value.BusinessLineAttorney,
          itemState.value.OtherContributors
        ]);

        React.useEffect(() => {
          summaryState.status.setValue(ItemDetailModel.UI.Container.Status.ReadOnly);
          contactState.status.setValue(ItemDetailModel.UI.Container.Status.ReadOnly);
          detailState.status.setValue(ItemDetailModel.UI.Container.Status.ReadOnly);
          questionnaireState.status.setValue(ItemDetailModel.UI.Container.Status.ReadOnly);
        }, [isAdmin.value, isContributor.value]);

        React.useEffect(() => {
          flashbarItems.setValue([
            ...flashbarItems.value,
            ...errorMessages.value ? errorMessages.value.filter((message) => message.field === "banner").map((message) => {
              return {
                type: message.type,
                content: message.message,
                dismissible: true
              }
            }) : []
          ])
        }, [errorMessages.value]);

        return Builder(new SnapShotState())
          .isAdmin(isAdmin)
          .isContributor(isContributor)
          .itemState(itemState)
          .summaryState(summaryState)
          .contactState(contactState)
          .detailState(detailState)
          .amazonDependencyState(amazonDependencyState)
          .thirdPartyDependencyState(thirdPartyDependencyState)
          .questionnaireState(questionnaireState)
          .flashbarItems(flashbarItems)
          .loading(loading)
          .build();
      }
    }
  }
}
