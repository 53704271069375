import { apiGatewayCogntioClient } from "../utils/apigatewayHelper";

import { store } from "../main";
import { QuestionModel } from "../models/question-model";

export function cloneItem(cloneInput: any) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };

    return apiClient.invokeApi({}, `/api/item/clone/`, "POST", headers, cloneInput);
}

export function batchGetItems(itemIds: string[]) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };

    return apiClient.invokeApi({}, `/api/item/batchGetItem/`, "POST", headers, {itemIds: itemIds});
}

export function baselineItem(itemId: string) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token,
            LocalDate: `${new Date().getFullYear()}/${new Date().getMonth() + 1}/${new Date().getDate()}`
        }
    };

    return apiClient.invokeApi({}, `/api/baselineactivityV4/baseline/${itemId}`, "PUT", headers);
}

// todo let client specify quarterYear
export function getBaselinePeriod() {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;

    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };
    return apiClient.invokeApi({}, `/api/baselineperiod/current`, "GET", headers);
}

/// question api
export function getFirstQuestion() {
    return getQuestionById("1")
}

export function getQuestionById(questionId: string) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token,
        }
    };
    return apiClient.invokeApi({}, `/api/question/${questionId}`, "GET", headers);

}

export function getQuestionByIdVersion(questionId: string, version) {
    if(!version) return getQuestionById(questionId);
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token,
        }
    };
    return apiClient.invokeApi({}, `/api/question/${questionId}/${version}`, "GET", headers);

}

export function addQuestion(newQuestion) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token,
        }
    };
    return apiClient.invokeApi({}, `/api/question/`, "POST", headers, newQuestion);
}


export function updateQuestion(newQuestion) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token,
        }
    };
    return apiClient.invokeApi({}, `/api/question/${newQuestion.Id}`, "PUT", headers, newQuestion);
}

export function getAllQuestions(): { data: QuestionModel.Data[] } {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        headers: {
            Authorization: token
        }
    };

    return apiClient.invokeApi({}, `/api/question/find/all`, "GET", headers,);

}

export function deleteQuestion(id: string) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;

    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };
    return apiClient.invokeApi({}, `/api/question/${id}`, "DELETE", headers);
}
// CRUD item
export function getItem(itemId: string) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;

    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token,
            LocalDate: `${new Date().getFullYear()}/${new Date().getMonth() + 1}/${new Date().getDate()}`
        }
    };
    return apiClient.invokeApi({}, `/api/item/${itemId}`, "GET", headers);
}

export function deleteItem(id: string) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;

    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };
    return apiClient.invokeApi({}, `/api/item/${id}`, "DELETE", headers);
}


export function submitItem(item: any) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {

        headers: {
            Authorization: token
        }
    };

    return apiClient.invokeApi({}, `/api/item`, "POST", headers, item);

}


export function createItem(item: any) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };

    return apiClient.invokeApi({}, `/api/item`, "POST", headers, item);
}

export function updateItem(itemId: string, item: any) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {

        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };

    return apiClient.invokeApi({}, `/api/item/${itemId}`, "PUT", headers, item);
}


export function getAllItem() {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;

    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };
    return apiClient.invokeApi({}, `/api/item/all/search`, "GET", headers, {});
}

export function getItemHistoryVersionsById(itemId: string) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;

    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };
    return apiClient.invokeApi({}, `/api/itemHistory/${itemId}`, "GET", headers, {});
}

export function getItemHistoryByIdAndVersion(itemId: string, version: number) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;

    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };
    return apiClient.invokeApi({}, `/api/itemHistory/${itemId}/${version}`, "GET", headers, {});
}

export async function getEmployeeSummary(alias: string) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;

    let apiClient = apiGatewayCogntioClient();
    let headers = {
        headers: {
            Authorization: token
        }
    };

    try {
        return await apiClient.invokeApi({}, `/api/employee/summary?searchTerm=${alias}`, "GET", headers, {});
    } catch (error) {
        return { data: {} }
    }
}

export function createAction(actionItem: any) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };

    return apiClient.invokeApi({}, `/api/action-item`, "POST", headers, actionItem);
}

export function updateAction(args: {action: any, actionId: string}) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };

    return apiClient.invokeApi({}, `/api/action-item/${args.actionId}`, "PUT", headers, args.action);
}

export function submitComment(args: {commentString: string, actionId: string}) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };

    return apiClient.invokeApi({}, `/api/action-item/comment/${args.actionId}`, "POST", headers, {commentString: args.commentString});
}

export function getActionByItemId(itemId: any) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };

    return apiClient.invokeApi({}, `/api/action-item/withItemId?itemId=${itemId}`, "GET", headers);
}

export function getPendingAssignedActionsForItem(itemId: any) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };

    return apiClient.invokeApi({}, `/api/action-item/getPendingAssignedActionsForItem?itemId=${itemId}`, "POST", headers);
}

export function getPmItems(pm: string) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };

    return apiClient.invokeApi({}, `/api/item/pm/${pm}`, "GET", headers);
}

export function getAllBaselinePeriods() {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };

    return apiClient.invokeApi({}, `/api/baselineperiod/allPeriods`, "GET", headers);
}

export function addBaselinePeriods(periods: any[]) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };

    return apiClient.invokeApi({}, `/api/baselineperiod/`, "Post", headers, periods);
}

export function addKeyword(keyword: any) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };

    return apiClient.invokeApi({}, `/api/keyword/`, "Post", headers, keyword);
}

export function getAllKeyword() {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };

    return apiClient.invokeApi({}, `/api/keyword`, "GET", headers);
}

export function getKeywordById(id: string) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };

    return apiClient.invokeApi({}, `/api/keyword/${id}`, "GET", headers);
}

export function updateKeyword(id: string, keyword: any) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };

    return apiClient.invokeApi({}, `/api/keyword/${id}`, "Put", headers, keyword);
}

export function updateECCNLock(itemID: string, ECCNLocked: boolean) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };

    return apiClient.invokeApi({}, `/api/item/${itemID}/updateECCNLock`, "Put", headers, { "ECCNLocked": ECCNLocked });
}

export function promoteECCN(itemID: string) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };

    return apiClient.invokeApi({}, `/api/item/${itemID}/promoteECCN`, "Put", headers);
}

export function createAnnualReport(annualReport: any) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };
    
    return apiClient.invokeApi({}, `/api/annual-report`, "Post", headers, annualReport);
}

export function updateAnnualReportDate(itemId: string, annualReportDate: string) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };
    const requestBody = {
        annualReportDate: annualReportDate
    };

    return apiClient.invokeApi({}, `/api/item/${itemId}/updateAnnualReportDate`, "PUT", headers, requestBody);

}

export function listProcessedAnnualReportByItemId(itemId: string) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;

    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };
    return apiClient.invokeApi({}, `/api/annual-report/findByItemId?itemId=${itemId}`, "GET", headers, {});
}