
import * as React from 'react'
import { HelpPanel, Icon } from '@amzn/awsui-components-react-v3';
export const termPane = (name: string, definition: string) => {
    return (
        <HelpPanel
            header={<h3>{name}</h3>}
            footer={
                <div>
                    <h3>
                        Learn more <Icon name="external" />
                    </h3>
                    <ul>
                        <li>
                            <a href="https://w.amazon.com/bin/view/ALTAR/tools/CRIPT/FAQs/" target='_blank'>CRIPT FAQs</a>
                        </li>
                        <li>
                            <a href="https://w.amazon.com/bin/view/CRIPTTool" target='_blank' >Export Compliance</a>
                        </li>
                    </ul>
                </div>
            }
        >
            <p>{definition}</p>
        </HelpPanel>
    );
}

