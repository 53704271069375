import {combineReducers} from 'redux';
import authenticationObjReducer from "./authentication.reducer";
import configObjReducer from "./config.reducer";
import navigationReducer from "./navigation.reducer";
import keywordsReducer from './KeywordSlice';

let rootReducer = combineReducers({
    authenticationObj:authenticationObjReducer,
    configObj: configObjReducer,
    dirtyObj: navigationReducer,
    keywordObj: keywordsReducer
});

export default rootReducer;