import * as React from 'react'
import { useState } from 'react'
import { Button, Header, Input } from '@amzn/awsui-components-react-v3';

import { addQuestion, updateQuestion } from '../actions/async.action'
const UploadQuestions = () => {
  const [addQuestions, setAddQuestions] = useState('')
  const [updateQuestions, setUpdateQuestions] = useState('')

  const processTextToQuestion = (json: string, proccessFunction: Function) => {
    const questions = JSON.parse(json)
    for (let question of questions) {
      proccessFunction(question)
    }
  }

  return (
    <div className="awsui-util-m-xl">
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <Header variant="h2">
            Upload questions: Please input the JSON file
          </Header>
        </div>
        <Input value={addQuestions} onChange={(event) => setAddQuestions(event.detail.value)} />
        <Button onClick={() => { processTextToQuestion(addQuestions, addQuestion) }}>Confirm</Button>
      </div>

      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <Header variant="h2">
            Upload questions: Please input the JSON file
          </Header>
        </div>
        <Input value={updateQuestions} onChange={(event) => setUpdateQuestions(event.detail.value)} />
        <Button onClick={() => { processTextToQuestion(updateQuestions, updateQuestion) }}>Confirm</Button>
      </div>
    </div>
  )
}
export default UploadQuestions