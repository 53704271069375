import * as React from 'react'
import { Column } from './../../../components/common/Table/Column';

import { formatStringToDate } from '../../../utils/sharedFunctions'
import { ECNItemTypeDescriptor } from '../../../components/Itemv2/questionOption.constants';
import { getSaasRestrictionsDisplayValue } from '../../../components/ItemDetailComps/ItemContainer';
export const tableData = (items: any[]): Column<any>[] => {
    return [
        {
            key: 'ItemFullName',
            columnDefinition: {
                id: 'ItemFullName',
                header: "Name",
                cell: item => <a href={`#/items/${item.Id}`} > {item.ItemFullName} </a>,
                sortingField: 'ItemFullName',
                width: 365

            },
            values: items.map((item) => item.ItemFullName).sort(),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: "CodeName",
            columnDefinition: {
                id: 'CodeName',
                header: 'Internal code name',
                cell: item => <a href={`#/items/${item.Id}`} > {item.CodeName} </a>,
                sortingField: 'CodeName'
            },
            values: items.map((item) => item.CodeName).sort(),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'Pm',
            columnDefinition: {
                id: 'Pm',
                header: "Product Manager",
                cell: item => <a href={`https://phonetool.amazon.com/users/${item.Pm}`} target="_blank" > {item.Pm} </a>,
                sortingField: 'Pm'
            },
            values: items.map((item) => item.Pm),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'BusinessLineAttorney',
            columnDefinition: {
                id: 'BusinessLineAttorney',
                header: 'Business line lawyer',
                cell: item => <a href={`https://phonetool.amazon.com/users/${item.BusinessLineAttorney}`} target="_blank" > {item.BusinessLineAttorney} </a>,
                sortingField: 'BusinessLineAttorney'
            },
            values: items.map((item) => item.BusinessLineAttorney),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'ItemType',
            columnDefinition: {
                id: 'ItemType',
                header: 'Item type',
                cell: item => item.ItemType,
                sortingField: 'ItemType'
            },
            values: items.map((item) => item.ItemType).sort(),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            
            key: 'ECCN',
            columnDefinition: {
                id: 'ECCN',
                header: 'ECCN',
                cell: item => item.ECCN || item.ECCNStatus || "Pending questionnaire completion",
                sortingField: 'ECCN'
            },
            values: items.map(item => item.ECCN).sort(),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'ECCNStatus',
            columnDefinition: {
                id: 'ECCNStatus',
                header: 'ECCN status',
                cell: item => item.ECCNStatus || "Pending questionnaire completion",
                sortingField: 'ECCNStatus'
            },
            values: items.map(item => item.ECCNStatus || "Pending questionnaire completion").sort(),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'SaaSRestrictions',
            columnDefinition: {
                id: 'SaaSRestrictions',
                header: 'SaaS Restrictions',
                cell: item => getSaasRestrictionsDisplayValue(item.SaaSRestrictions),
                sortingField: 'SaaSRestrictions'
            },
            values: items.map(item => getSaasRestrictionsDisplayValue(item.SaaSRestrictions)),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            //Derive this column during run time and we don't store the value 
            key: 'ENCTypeValue',
            columnDefinition: {
                id: 'ENCTypeValue',
                header: 'ENC Item Types',
                cell: item => {
                    const ecnItem = ECNItemTypeDescriptor.find(option => option.value === item.ENCType);
                    return ecnItem ? ecnItem.label : item.ENCType;
                },
                sortingField: 'ENCTypeValue'
            },
            values: items.map(item => {
                const ecnItem = ECNItemTypeDescriptor.find(option => option.value === item.ENCType);
                return item.ENCTypeValue = ecnItem ? ecnItem.label : item.ENCType;
            }),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'TemporaryECCN',
            columnDefinition: {
                id: 'TemporaryECCN',
                header: 'Temporary ECCN',
                cell: item => item.TemporaryECCN,
                sortingField: 'TemporaryECCN'
            },
            values: items.map(item => item.TemporaryECCN),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'ECCNLocked',
            columnDefinition: {
                id: 'ECCNLocked',
                header: 'ECCN locked',
                cell: item => item.ECCNLocked ? "true" : "false",
                sortingField: 'ECCNLocked'
            },
            values: items.map(item => item.ECCNLocked ? "true" : "false"),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'ReviewStatus',
            columnDefinition: {
                id: 'ReviewStatus',
                header: 'Review status',
                cell: item => item.ReviewStatus || "",
                sortingField: 'ReviewStatus'
            },
            values: items.map(item => item.ReviewStatus || "").sort(),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'CFR740',
            columnDefinition: {
                id: 'CFR740',
                header: '15 CFR § 740.17',
                cell: item => item.CFR740,
                sortingField: 'CF$740'
            },
            values: items.map(item => item.CFR740).sort(),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'SubjectToAnnualReporting',
            columnDefinition: {
                header: 'Subject to annual reporting',
                id: 'SubjectToAnnualReporting',
                cell: item => item.SubjectToAnnualReporting,
                sortingField: 'SubjectToAnnualReporting'
            },
            values: items.map(item => item.SubjectToAnnualReporting),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'AnnualReportDate',
            columnDefinition: {
                id: 'AnnualReportDate',
                header: 'Annual report date',
                cell: item => formatStringToDate(item.AnnualReportDate),
                sortingField: 'AnnualReportDate'
            },
            values: items.map(item => item.AnnualReportDate),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'SubjectToSemiAnnualReporting',
            columnDefinition: {
                id: 'SubjectToSemiAnnualReporting',
                header: 'Subject to semi-annual reporting',
                cell: item => item.SubjectToSemiAnnualReporting,
                sortingField: 'SubjectToSemiAnnualReporting'
            },
            values: items.map(item => item.SubjectToSemiAnnualReporting),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'SemiAnnualReportDate',
            columnDefinition: {
                id: 'SemiAnnualReportDate',
                header: 'Semi-annual report date',
                cell: item => formatStringToDate(item.SemiAnnualReportDate),
                sortingField: 'SemiAnnualReportDate'
            },
            values: items.map(item => item.SemiAnnualReportDate),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'LastUpdatedDate',
            columnDefinition: {
                id: 'LastUpdatedDate',
                header: 'Last modified',
                cell: item => formatStringToDate(item.LastUpdatedDate),
                sortingField: 'LastUpdateDate'
            },
            values: items.map(item => item.LastUpdatedDate),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'LastUpdatedBy',
            columnDefinition: {
                id: 'LastUpdatedBy',
                header: 'Last modified by',
                cell: item => item.LastUpdatedBy,
                sortingField: 'LastUpdatedBy'
            },
            values: items.map(item => item.LastUpdatedBy).sort(),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'CreatedDate',
            columnDefinition: {
                id: 'CreatedDate',
                header: 'Created',
                cell: item => formatStringToDate(item.CreatedDate),
                sortingField: 'CreatedDate'
            },
            values: items.map(item => item.CreatedDate),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'CreatedBy',
            columnDefinition: {
                id: 'CreatedBy',
                header: 'Created by',
                cell: item => item.CreatedBy,
                sortingField: 'CreatedBy'
            },
            values: items.map(item => item.CreatedBy).sort(),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'LastBaselinedDate',
            columnDefinition: {
                id: 'LastBaselinedDate',
                header: 'Last baselined',
                cell: item => formatStringToDate(item.LastBaselinedDate),
                sortingField: 'LastBaselinedDate'
            },
            values: items.map(item => item.LastBaselinedDate),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'LastBaselinedBy',
            columnDefinition: {
                id: 'LastBaselinedBy',
                header: 'Last baselined by',
                cell: item => item.LastBaselinedBy,
                sortingField: 'LastBaselinedBy'
            },
            values: items.map(item => item.LastBaselinedBy).sort(),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'ExemptFromBaseline',
            columnDefinition: {
                id: 'ExemptFromBaseline',
                header: 'Exempt from baselining',
                cell: item => item.ExemptFromBaseline,
                sortingField: 'ExemptFromBaseline'
            },
            values: items.map(item => item.ExemptFromBaseline),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'ExemptFromEscalations',
            columnDefinition: {
                id: 'ExemptFromEscalations',
                header: 'Exempt from escalations',
                cell: item => item.ExemptFromEscalations,
                sortingField: 'ExemptFromEscalations'
            },
            values: items.map(item => item.ExemptFromEscalations),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'ManagerAlias',
            columnDefinition: {
                id: 'ManagerAlias',
                header: 'Manager escalation recipient',
                cell: item => item.ManagerAlias ? <a href={`https://phonetool.amazon.com/users/${item.ManagerAlias}`} target="_blank" > {item.ManagerAlias} </a> : '',
                sortingField: 'ManagerAlias'
            },
            values: items.map(item => item.ManagerAlias),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'VPAlias',
            columnDefinition: {
                id: 'VPAlias',
                header: 'VP escalation recipient',       
                cell: item => item.VPAlias ? <a href={`https://phonetool.amazon.com/users/${item.VPAlias}`} target="_blank" > {item.VPAlias} </a> : '',         
                sortingField: 'VPAlias'
            },
            values: items.map(item => item.VPAlias),
            propertyFilter: true,
            visibilityEditable: true
        },
    ]
}