import { apiGatewayCogntioClient } from "../utils/apigatewayHelper";
import { store } from "../main";

export function uploadItemFile(productId: string, files: any, section: string) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };
    let reqBody = { data: files[0], productId: productId, section: section };
    return apiClient.invokeApi({}, `/api/fileupload/v4/${productId}`, "PUT", headers, reqBody)
}

export function readFile(productId: string, filename: string, section: string) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };
    return apiClient.invokeApi({}, `/api/fileupload/${productId}?section=${section}&filename=${filename}`, "GET", headers);
}

export function deleteFileV4(productId: string, key: string, section: string) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };
    return apiClient.invokeApi({}, `/api/fileupload/v4/${productId}?section=${section}&filename=${key}`, "DELETE", headers);
}