import { Link } from "@amzn/awsui-components-react-v3";
import * as React from "react";

export const EmployeeLabel = (props: { title: string, alias: string, content?: any, }) => <div>
  <div className="awsui-util-label">{props.title}</div>
  <Link
    external
    href={`https://phonetool.amazon.com/users/${props.alias}`}
  >
    {props.content}
  </Link>
</div>