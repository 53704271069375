import { apiGatewayCogntioClient } from "../utils/apigatewayHelper";
import { store } from "../main";

export function getExistingItems() {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;

    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };
    return apiClient.invokeApi({}, '/api/dashboard/existing-items/', "GET", headers);
}

export function getAdminComplianceDashboard() {
    const appState = store.getState();

    const token = appState.authenticationObj.userDetails.jwtToken;

    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };

    return apiClient.invokeApi({}, `/api/dashboard/admin-compliance`, "GET", headers, {})
}