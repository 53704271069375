import { UIModel } from "./ui-model";
import { Builder, IBuilder } from "builder-pattern";
import { useEffect } from "react";
import { getAllQuestions } from "../actions/async.action";
import { addQuestion, updateQuestion, deleteQuestion } from '../actions/async.action'

import * as React from 'react'

export enum Question_Status {
    READ_ONLY = 'READ_ONLY',
    EDIT_FOCUS = 'EDIT_FOCUS',
    EXPANDED = 'EXPANDED',
    CLOSED = 'CLOSED',
    ARCHIVED = "ARCHIVED"
}

export enum SpecialQuetion {
    ItemType = '2',
    Performance = '32-1'
}

export type QuestionType = "SINGLE_SELECTION" | "MULTI_SELECTION" | "TEXT";
export type ReferenceType = "QUESTION" | "ECCN" | "MANUAL" | "ACCESSIBILITYSTATUS";

export namespace QuestionModel {
    export class AnswerOption {
        Criteria: {
            Operator: "equals" | "contains" | "not" | "text",
            Value: string,
        }
        RefType: ReferenceType;
        RefIdentifier: string;

        static isValid(o: AnswerOption) {
            if (
                !o.Criteria.Operator ||
                !o.Criteria.Value ||
                !o.RefType
            ) {
                return false;
            }
            if (o.RefType !== "MANUAL" && !o.RefIdentifier) {
                return false;
            }
            return true;
        }
    }



    const excludedLowerCaseTerms = new Set(["use"]);

    export const highlightKeywords = (text: string, allKeywords: any, showTermPanel) => {
        if (!!!text) return { result: [], keys: [] };
        let result = [text]
        let keys = []

        for (let i = 0; i < allKeywords.length; i++) {
            let keywordName = allKeywords[i].Name
            let keywordId = allKeywords[i].Id

            let nextResult = [];
            
            let lowerCaseKeyword = keywordName.toLowerCase();
            
            for (let element of result) {
                if (typeof element !== 'string') {
                    nextResult.push(element)
                    continue
                }
                if (element.toLowerCase().indexOf(keywordName) !== -1) {
                    const elementList = generateHyperLink(element, keywordName, keywordId, showTermPanel)
                    nextResult.push(...elementList)
                    keys.push({"Id": allKeywords[i].Id, "Name": allKeywords[i].Name, "Description": allKeywords[i].Description })
                } else if (!excludedLowerCaseTerms.has(lowerCaseKeyword) && element.indexOf(lowerCaseKeyword) !== -1) {
                    const elementList = generateHyperLink(element, keywordName, keywordId, showTermPanel, lowerCaseKeyword)
                    nextResult.push(...elementList)
                    keys.push({"Id": allKeywords[i].Id, "Name": allKeywords[i].Name, "Description": allKeywords[i].Description })
                }
                else {
                    nextResult.push(element)
                }
            }

            result = nextResult
        }

        return { result, keys };
    }

    const generateHyperLink = (element: string, keywordName: string, keywordId: string, showTermPanel, replaceString?: string) => {
        if (!element.toLowerCase().includes(keywordName.toLowerCase())) {
            console.info("Attempting to generate hyperlink for unfound keyword")
            return element;
        }
        let keywordNameRegex = new RegExp(keywordName.toLowerCase(), "gi"); 
        replaceString = element.match(keywordNameRegex)[0]
        let result: any[] = element.split(keywordNameRegex);

        for (let i = 1; i < result.length; i = i + 2) {
            result.splice(i, 0, <a href="javascript:void(0);"
                onClick={() => showTermPanel(keywordId)}
            >{replaceString}</a>)
        }
        return result
    }

    export class Data {
        Id: string;
        Version: string;
        Type: "SINGLE_SELECTION" | "MULTI_SELECTION" | "TEXT";
        Values: string[];
        CreationDate: Date;
        CreatedBy: string;
        LastUpdatedBy: string;
        LastUpdatedDate: Date;
        DisplayText: any;
        ShortName: string;
        Options: AnswerOption[];
        Description?: string;
        BypassIncrementVersion?: boolean;

        static hasSameContent(q1: Data, q2: Data): boolean {
            if (q1.Id !== q2.Id) {
                throw new Error("Different question");
            }
            if (q1.Type !== q2.Type ||
                q1.DisplayText !== q2.DisplayText ||
                q1.Description !== q2.Description
            ) {
                return false;
            }

            if (q1.Options.length !== q2.Options.length) {
                return false;
            }

            for (const option of q1.Options) {
                const sameOptionIndex = q2.Options.findIndex(o => {
                    return o.Criteria.Operator === option.Criteria.Operator &&
                        o.Criteria.Value === option.Criteria.Value &&
                        o.RefIdentifier === option.RefIdentifier &&
                        o.RefType === option.RefType
                })
                if (sameOptionIndex === -1) {
                    return false;
                }
            }
            return true;
        }

        static getReferenceForAnswer(question: Data, answer: string | string[]): AnswerOption {
            if (question.Type === "SINGLE_SELECTION") {
                const options = question.Options;
                for (let i = 0; i < options.length; i++) {
                    if (options[i].Criteria.Value === (answer as string)) {
                        return options[i];
                    }
                }
            }

            if (question.Type === "MULTI_SELECTION") {
                const options = question.Options;
                for (let i = 0; i < options.length; i++) {
                    if ((answer as string[]).findIndex((v) => v === options[i].Criteria.Value) !== -1) {
                        return options[i];
                    }
                }
            }

            if (question.Type === "TEXT") {
                return question.Options[0];
            }
        }
    }

    export class MalFormedData extends Data {
        Status: "Orphan" | "Dead End";

        static setOrphan(data: Data) {
            return Builder<MalFormedData>(data).Status("Orphan").build();
        }

        static setDeadEnd(data: Data) {
            return Builder<MalFormedData>(data).Status("Dead End").build();
        }
    }

    export class PendingUpdateData extends Data {
        Operation: "Update" | "Delete" | "Create";

        static setUpdate(data: Data) {
            return Builder<PendingUpdateData>(data).Operation("Update").build();
        };

        static setDelete(data: Data) {
            return Builder<PendingUpdateData>(data).Operation("Delete").build();
        };

        static setCreate(data: Data) {
            return Builder<PendingUpdateData>(data).Operation("Create").build();
        };
    }

    export namespace UI {
        export class BasicInfoDataState {
            idFieldState: UIModel.FieldState<string>;
            textFieldState: UIModel.FieldState<string>;
            descriptionFieldState: UIModel.FieldState<string>;
            typeFieldState: UIModel.FieldState<QuestionType>;
            init: (question: Data) => void;
            isComplete: UIModel.FieldState<boolean>;
            bypassIncrementVersionState: UIModel.FieldState<boolean>;

            static toBuilder(
                state: BasicInfoDataState,
                builder: IBuilder<Data> = Builder<Data>()
            ) {
                return builder
                    .Id(state.idFieldState.value)
                    .DisplayText(state.textFieldState.value)
                    .Description(state.descriptionFieldState.value)
                    .Type(state.typeFieldState.value)
                    .BypassIncrementVersion(state.bypassIncrementVersionState.value)
            }

            static use(props: {
                template?: Data
            }) {
                const idFieldState = UIModel.FieldState.useRequired<string>({});
                const textFieldState = UIModel.FieldState.useRequired<string>({});
                const descriptionFieldState = UIModel.FieldState.use<string>({});
                const typeFieldState = UIModel.FieldState.useRequired<QuestionType>({});
                const isComplete = UIModel.FieldState.use<boolean>({ initialValue: false });
                const bypassIncrementVersionState = UIModel.FieldState.use<boolean>({ initialValue: false });
                const init = (template: Data) => {
                    idFieldState.setValue(template.Id);
                    textFieldState.setValue(template.DisplayText);
                    descriptionFieldState.setValue(template.Description);
                    typeFieldState.setValue(template.Type);
                    bypassIncrementVersionState.setValue(template.BypassIncrementVersion)
                }
                useEffect(() => {
                    if (props.template) init(props.template);
                }, [])

                useEffect(() => {
                    if (
                        idFieldState.value &&
                        textFieldState.value &&
                        typeFieldState.value &&
                        bypassIncrementVersionState
                    ) {
                        isComplete.setValue(true);
                        return;
                    }
                    isComplete.setValue(false);
                }, [
                    idFieldState.value,
                    textFieldState.value,
                    descriptionFieldState.value,
                    typeFieldState.value,
                    bypassIncrementVersionState.value
                ]);
                return Builder<BasicInfoDataState>(new BasicInfoDataState())
                    .idFieldState(idFieldState)
                    .textFieldState(textFieldState)
                    .descriptionFieldState(descriptionFieldState)
                    .typeFieldState(typeFieldState)
                    .init(init)
                    .bypassIncrementVersionState(bypassIncrementVersionState)
                    .isComplete(isComplete)
                    .build();
            }
        }

        export class UpdateBasicInfoDataState {
            id: string
            textFieldState: UIModel.FieldState<string>;
            descriptionFieldState: UIModel.FieldState<string>;
            typeFieldState: UIModel.FieldState<QuestionType>;
            bypassIncrementVersionState: UIModel.FieldState<boolean>;

            static toBuilder(
                state: UpdateBasicInfoDataState,
                builder: IBuilder<Data> = Builder<Data>()
            ) {
                return builder
                    .Id(state.id)
                    .DisplayText(state.textFieldState.value)
                    .Description(state.descriptionFieldState.value)
                    .Type(state.typeFieldState.value)
                    .BypassIncrementVersion(state.bypassIncrementVersionState.value)
            }
            static use(props: {
                template: UIModel.FieldState<Data>
            }) {
                const textFieldState = UIModel.FieldState.useRequired<string>({});
                const descriptionFieldState = UIModel.FieldState.useRequired<string>({});
                const typeFieldState = UIModel.FieldState.useRequired<QuestionType>({});
                const bypassIncrementVersionState = UIModel.FieldState.use<boolean>({ initialValue: false });

                const init = (template: UIModel.FieldState<Data>) => {
                    textFieldState.setValue(template.value.DisplayText);
                    descriptionFieldState.setValue(template.value.Description);
                    typeFieldState.setValue(template.value.Type);
                    bypassIncrementVersionState.setValue(template.value.BypassIncrementVersion)
                }

                useEffect(() => {
                    if (props.template) init(props.template);
                }, []);

                return Builder<UpdateBasicInfoDataState>(new UpdateBasicInfoDataState())
                    .id(props.template.value.Id)
                    .textFieldState(textFieldState)
                    .descriptionFieldState(descriptionFieldState)
                    .typeFieldState(typeFieldState)
                    .bypassIncrementVersionState(bypassIncrementVersionState)
                    .build();
            }
        }

        export class SingleSelectQuestionState {
            options: UIModel.FieldState<AnswerOption[]>;
            init: (question: Data) => void;
            isComplete: UIModel.FieldState<boolean>;

            static toBuilder(
                state: SingleSelectQuestionState,
                builder: IBuilder<Data> = Builder<Data>()
            ) {
                return builder
                    .Options(state.options.value)
                    .Values(state.options.value.map(o => o.Criteria.Value));
            }
            static use(props: {
                template?: Data
            }) {
                const options = UIModel.FieldState.useRequiredArray<AnswerOption>([]);
                const isComplete = UIModel.FieldState.use<boolean>({ initialValue: false });

                const init = (template: Data) => {
                    if (template.Type !== "SINGLE_SELECTION") return;
                    options.setValue(template.Options);
                }
                useEffect(() => {
                    if (props.template) init(props.template);
                }, []);

                useEffect(() => {
                    let missingInformation = !options.value || options.value.length === 0;
                    options.value.forEach(o => {
                        if (!AnswerOption.isValid(o)) {
                            missingInformation = true;
                        }
                    })
                    if (!missingInformation) {
                        isComplete.setValue(true);
                        return;
                    }
                    isComplete.setValue(false);
                }, [
                    options.value,
                ]);
                return Builder<SingleSelectQuestionState>()
                    .init(init)
                    .options(options)
                    .isComplete(isComplete)
                    .build();
            }
        }

        export class MultipleSelectQuestionState {
            optionValues: UIModel.FieldState<string[]>;
            selectAnyOption: UIModel.FieldState<AnswerOption>;
            notAboveOption: UIModel.FieldState<AnswerOption>;
            init: (question: Data) => void;
            isComplete: UIModel.FieldState<boolean>;

            static toBuilder(
                state: MultipleSelectQuestionState,
                builder: IBuilder<Data> = Builder<Data>()
            ) {
                return builder
                    .Options([
                        ...state.optionValues.value.map((o) => {
                            return Builder<AnswerOption>(state.selectAnyOption.value)
                                .Criteria(
                                    Builder(state.selectAnyOption.value.Criteria)
                                        .Value(o)
                                        .build()
                                ).build()
                        }),
                        state.notAboveOption.value
                    ])
            }
            static use(props: {
                template?: Data
            }) {
                const isComplete = UIModel.FieldState.use<boolean>({ initialValue: false });
                const optionValues = UIModel.FieldState.use<string[]>({ initialValue: [] });
                const selectAnyOption = UIModel.FieldState.use<AnswerOption>({
                    initialValue: Builder<AnswerOption>()
                        .Criteria({
                            Operator: "contains",
                            Value: "Not defined"
                        })
                        .RefType("QUESTION")
                        .RefIdentifier("")
                        .build()
                });
                const notAboveOption = UIModel.FieldState.use<AnswerOption>({
                    initialValue: Builder<AnswerOption>()
                        .Criteria({
                            Operator: "contains",
                            Value: "None of the options above"
                        })
                        .RefType("QUESTION")
                        .RefIdentifier("")
                        .build()
                });

                const init = (template: Data) => {
                    if (template.Type !== "MULTI_SELECTION") return;
                    optionValues.setValue(
                        template.Options
                            .slice(0, -1)
                            .map((v) => v.Criteria.Value));
                    selectAnyOption.setValue(template.Options[0]);
                    notAboveOption.setValue(template.Options[template.Options.length - 1]);
                }
                useEffect(() => {
                    if (props.template) {
                        init(props.template);
                    }
                }, []);

                useEffect(() => {
                    let hasNullValue = false;
                    optionValues.value.forEach(v => {
                        if (!v) {
                            hasNullValue = true;
                        }
                    });
                    if (
                        !hasNullValue &&
                        AnswerOption.isValid(selectAnyOption.value) &&
                        AnswerOption.isValid(notAboveOption.value)
                    ) {
                        isComplete.setValue(true);
                        return;
                    }
                    isComplete.setValue(false);
                }, [
                    optionValues.value,
                    selectAnyOption.value,
                    notAboveOption.value
                ]);

                return Builder<MultipleSelectQuestionState>()
                    .optionValues(optionValues)
                    .selectAnyOption(selectAnyOption)
                    .notAboveOption(notAboveOption)
                    .init(init)
                    .isComplete(isComplete)
                    .build();
            }
        }

        export class TextQuestionState {
            option: UIModel.FieldState<AnswerOption>;
            init: (question: Data) => void;
            isComplete: UIModel.FieldState<boolean>;

            static toBuilder(
                state: TextQuestionState,
                builder: IBuilder<Data> = Builder<Data>()
            ) {
                return builder
                    .Options([state.option.value])
                    .Values([]);
            }
            static use(props: {
                template?: Data
            }) {
                const isComplete = UIModel.FieldState.use<boolean>({ initialValue: false });
                const option = UIModel.FieldState.useRequired<AnswerOption>({
                    initialValue: Builder<AnswerOption>()
                        .Criteria({
                            Operator: "equals",
                            Value: "Not required"
                        })
                        .RefType("QUESTION")
                        .RefIdentifier("")
                        .build()
                });

                const init = (template: Data) => {
                    if (template.Type !== "TEXT") return;
                    option.setValue(template.Options[0]);
                }
                useEffect(() => {
                    if (props.template) init(props.template);
                }, []);

                useEffect(() => {
                    if (AnswerOption.isValid(option.value)) {
                        isComplete.setValue(true);
                        return;
                    }
                    isComplete.setValue(false);
                }, [option.value])
                return Builder<TextQuestionState>()
                    .init(init)
                    .option(option)
                    .isComplete(isComplete)
                    .build();
            }
        }

        export class EditQuestionModalState {
            basicInfoState: BasicInfoDataState;
            singleSelectQuestionState: SingleSelectQuestionState;
            multipleSelectQuestionState: MultipleSelectQuestionState;
            textSelectQuestionState: TextQuestionState;
            onSubmit: (data: Data) => void;
            init: (question: Data) => void;
            isComplete: UIModel.FieldState<boolean>;

            static toQuestion(
                state: EditQuestionModalState
            ): Data {
                let builder = Builder<Data>(new Data());
                builder = BasicInfoDataState.toBuilder(state.basicInfoState, builder);
                if (state.basicInfoState.typeFieldState.value === "SINGLE_SELECTION") {
                    return SingleSelectQuestionState.toBuilder(
                        state.singleSelectQuestionState,
                        builder
                    ).build();
                }
                if (state.basicInfoState.typeFieldState.value === "MULTI_SELECTION") {
                    return MultipleSelectQuestionState.toBuilder(
                        state.multipleSelectQuestionState,
                        builder
                    ).build();
                }
                if (state.basicInfoState.typeFieldState.value === "TEXT") {
                    return TextQuestionState.toBuilder(
                        state.textSelectQuestionState,
                        builder
                    ).build();
                }
                return builder.build();
            }
            static use(props: {
                onSubmit?: (question: Data) => void,
                template?: Data
            }) {
                const basicInfoState = BasicInfoDataState.use({});
                const singleSelectQuestionState = SingleSelectQuestionState.use({});
                const multipleSelectQuestionState = MultipleSelectQuestionState.use({});
                const textQuestionState = TextQuestionState.use({});
                const isComplete = UIModel.FieldState.use({ initialValue: false });
                const init = (question: Data) => {
                    basicInfoState.init(question);
                    singleSelectQuestionState.init(question);
                    multipleSelectQuestionState.init(question);
                    textQuestionState.init(question);
                }

                useEffect(() => {
                    if (props.template) init(props.template);
                }, [])

                useEffect(() => {
                    if (basicInfoState.typeFieldState.value === "SINGLE_SELECTION") {
                        isComplete.setValue(
                            basicInfoState.isComplete.value &&
                            singleSelectQuestionState.isComplete.value
                        );
                    }

                    if (basicInfoState.typeFieldState.value === "MULTI_SELECTION") {
                        isComplete.setValue(
                            basicInfoState.isComplete.value &&
                            multipleSelectQuestionState.isComplete.value
                        );
                    }

                    if (basicInfoState.typeFieldState.value === "TEXT") {
                        isComplete.setValue(
                            basicInfoState.isComplete.value &&
                            textQuestionState.isComplete.value
                        );
                    }
                }, [
                    basicInfoState.typeFieldState.value,
                    basicInfoState.isComplete.value,
                    singleSelectQuestionState.isComplete.value,
                    multipleSelectQuestionState.isComplete.value,
                    textQuestionState.isComplete.value,
                ])


                return Builder<EditQuestionModalState>(new EditQuestionModalState())
                    .basicInfoState(basicInfoState)
                    .singleSelectQuestionState(singleSelectQuestionState)
                    .multipleSelectQuestionState(multipleSelectQuestionState)
                    .textSelectQuestionState(textQuestionState)
                    .onSubmit(props.onSubmit ? props.onSubmit : () => { })
                    .init(init)
                    .isComplete(isComplete)
                    .build();
            }
        }
    }

    export namespace Table {
        export class QuestionsState {
            items: UIModel.FieldState<Data[]>;
            onRefresh: () => void;
            selectedItems: UIModel.FieldState<Data[]>;

            static use(props: {
                template?: QuestionsState,
                onQuestionOperate?: (question: Data) => void;
            }) {
                const onQuestionOperation = (question: Data) => {
                    if (props.onQuestionOperate) {
                        props.onQuestionOperate(question);
                    }
                }
                const items = UIModel.FieldState.use<Data[]>({ initialValue: [] });
                const selectedItems = UIModel.FieldState.use<Data[]>({ initialValue: [] });

                const init = async () => {
                    const result = await getAllQuestions();
                    items.setValue(result.data);
                }
                useEffect(() => {
                    init();
                }, [])
                return Builder<QuestionsState>()
                    .items(items)
                    .selectedItems(selectedItems)
                    .build()
            }
        }

        export class OrphanQuestionsState {
            items: UIModel.FieldState<MalFormedData[]>;
            onValidate: (draftQuestions: Data[]) => void;

            static use(props: {
                template?: OrphanQuestionsState,
                onQuestionOperate?: (question: Data) => void;
            }) {
                const onQuestionOperation = (question: Data) => {
                    if (props.onQuestionOperate) {
                        props.onQuestionOperate(question);
                    }
                }
                const items = UIModel.FieldState.use<MalFormedData[]>({ initialValue: [] });
                const onValidate = (draftQuestions: Data[]) => {
                    const dataMap = new Map<string, Data>();
                    const parentSet = new Set<string>();
                    const childParentMap = new Map<string, string>();
                    draftQuestions.forEach(q => {
                        dataMap.set(q.Id, q);
                        parentSet.add(q.Id);
                        q.Options.forEach(o => {
                            if (o.RefType === "QUESTION") {
                                childParentMap.set(o.RefIdentifier, q.Id);
                            }
                        })
                    });
                    const malformedData: MalFormedData[] = [];
                    parentSet.forEach(p => {
                        if (!childParentMap.has(p)) { //orphan
                            malformedData.push(
                                Builder<MalFormedData>(dataMap.get(p))
                                    .Status("Orphan")
                                    .build()
                            );
                        }
                    })

                    childParentMap.forEach(p => {
                        if (!parentSet.has(p)) {
                            malformedData.push(
                                Builder<MalFormedData>(dataMap.get(childParentMap.get(p)))
                                    .Status("Dead End")
                                    .build()
                            );
                        }
                    })
                    items.setValue(malformedData);
                }
                return Builder<OrphanQuestionsState>()
                    .onValidate(onValidate)
                    .items(items)
                    .build()
            }
        }

        export class UpdateSummaryState {
            items: UIModel.FieldState<PendingUpdateData[]>;
            onSummarize: (currentQuestions: Data[], draftQuestions: Data[]) => void;
            onSubmit: (questions: PendingUpdateData[]) => Promise<void>;
            loading: UIModel.FieldState<boolean>;

            static use(props: {
                template?: UpdateSummaryState,
                onQuestionOperate?: (question: Data) => void;
            }) {
                const onQuestionOperation = (question: Data) => {
                    if (props.onQuestionOperate) {
                        props.onQuestionOperate(question);
                    }
                }
                const items = UIModel.FieldState.use<PendingUpdateData[]>({ initialValue: [] });
                const loading = UIModel.FieldState.use<boolean>({ initialValue: false });
                const onSummarize = (currentQuestions: Data[], draftQuestions: Data[]) => {
                    const currentQuestionMap = new Map<string, Data>();
                    const draftQuestionsMap = new Map<string, Data>();
                    currentQuestions.forEach((q) => {
                        if (q.Id)
                            currentQuestionMap.set(q.Id, q);
                    });
                    draftQuestions.forEach((q) => {
                        if (q.Id)
                            draftQuestionsMap.set(q.Id, q);
                    });

                    const updatedItems = [];
                    currentQuestionMap.forEach((question, key) => {
                        if (!draftQuestionsMap.has(key)) {
                            updatedItems.push(
                                QuestionModel.PendingUpdateData.setDelete(question)
                            );

                        } else if (!Data.hasSameContent(question, draftQuestionsMap.get(key))) {
                            updatedItems.push(
                                QuestionModel.PendingUpdateData.setUpdate(draftQuestionsMap.get(key))
                            );
                        }
                    })

                    draftQuestionsMap.forEach((question, key) => {
                        if (!currentQuestionMap.has(key)) {
                            updatedItems.push(
                                QuestionModel.PendingUpdateData.setCreate(question)
                            );
                        }
                    })
                    items.setValue(updatedItems);
                }

                const onSubmit = async (questions: PendingUpdateData[]) => {
                    loading.setValue(true);
                    for (const question of questions) {
                        if (question.Operation === "Delete") {
                            await deleteQuestion(question.Id);
                        }

                        if (question.Operation === "Create") {
                            await addQuestion(question);
                        }

                        if (question.Operation === "Update") {
                            await updateQuestion(question);
                        }
                    }
                    loading.setValue(false);
                    window.location.reload();
                }

                return Builder<UpdateSummaryState>()
                    .onSummarize(onSummarize)
                    .items(items)
                    .loading(loading)
                    .onSubmit(onSubmit)
                    .build()
            }
        }
    }

    export class QuestionPageState {
        public static draftQuestions: Data[] = [];

        questionTableState: Table.QuestionsState;
        orphanQuestionsTableState: Table.OrphanQuestionsState;
        updateSummaryTableState: Table.UpdateSummaryState;
        createQuestionModalState: QuestionModel.UI.EditQuestionModalState;
        createQuestionVisible: UIModel.FieldState<boolean>
        editQuestionModalState: QuestionModel.UI.EditQuestionModalState;
        editQuestionVisible: UIModel.FieldState<boolean>;
        draftQuestions: UIModel.FieldState<Data[]>;
        readOnlyQuestionModalState: QuestionModel.UI.EditQuestionModalState;
        readOnlyQuestionVisible: UIModel.FieldState<boolean>;
        submitConfirmModalVisible: UIModel.FieldState<boolean>;

        static use(props: {
            template?: QuestionPageState
        }) {
            const onCreate = (question: Data) => {
                draftQuestions.setValue([...draftQuestions.value, question]);
                createQuestionVisible.setValue(false);
            };

            const onUpdate = (question: Data) => {
                const newDraftQuestions = draftQuestions.value.filter(q => q.Id !== question.Id);
                draftQuestions.setValue([...newDraftQuestions, question]);
                editQuestionVisible.setValue(false);
            };

            const draftQuestions = UIModel.FieldState.use<Data[]>({ initialValue: [] });
            const createQuestionModalState = QuestionModel.UI.EditQuestionModalState.use({ onSubmit: onCreate });
            const createQuestionVisible = UIModel.FieldState.use({ initialValue: false });
            const editQuestionModalState = QuestionModel.UI.EditQuestionModalState.use({ onSubmit: onUpdate });
            const editQuestionVisible = UIModel.FieldState.use({ initialValue: false });
            const readOnlyQuestionModalState = QuestionModel.UI.EditQuestionModalState.use({ onSubmit: onUpdate });
            const readOnlyQuestionVisible = UIModel.FieldState.use({ initialValue: false });
            const submitConfirmModalVisible = UIModel.FieldState.use({ initialValue: false });
            const questionTableState = Table.QuestionsState.use({
                onQuestionOperate: (question: Data) => {
                    readOnlyQuestionModalState.init(question);
                    readOnlyQuestionVisible.setValue(true);
                }
            });
            const orphanQuestionsTableState = Table.OrphanQuestionsState.use({
                onQuestionOperate: (question: Data) => {
                    readOnlyQuestionModalState.init(question);
                    readOnlyQuestionVisible.setValue(true);
                }
            });
            const updateSummaryTableState = Table.UpdateSummaryState.use({
                onQuestionOperate: (question: Data) => {
                    readOnlyQuestionModalState.init(question);
                    readOnlyQuestionVisible.setValue(true);
                }
            });

            useEffect(() => {
                if (
                    questionTableState.items.value &&
                    questionTableState.items.value.length > 0
                ) {
                    draftQuestions.setValue(questionTableState.items.value);
                }
            }, [questionTableState.items.value]);

            useEffect(() => {
                if (
                    draftQuestions.value &&
                    draftQuestions.value.length > 0
                ) {
                    orphanQuestionsTableState.onValidate(
                        draftQuestions.value
                    );
                }
            }, [draftQuestions.value]);

            useEffect(() => {
                if (
                    draftQuestions.value &&
                    draftQuestions.value.length > 0
                ) {
                    updateSummaryTableState.onSummarize(
                        questionTableState.items.value,
                        draftQuestions.value
                    );
                }
            }, [draftQuestions.value]);

            useEffect(() => {
                QuestionPageState.draftQuestions = draftQuestions.value;
            }, [draftQuestions.value])

            return Builder<QuestionPageState>(new QuestionPageState())
                .questionTableState(questionTableState)
                .orphanQuestionsTableState(orphanQuestionsTableState)
                .updateSummaryTableState(updateSummaryTableState)
                .createQuestionModalState(createQuestionModalState)
                .createQuestionVisible(createQuestionVisible)
                .editQuestionModalState(editQuestionModalState)
                .editQuestionVisible(editQuestionVisible)
                .readOnlyQuestionVisible(readOnlyQuestionVisible)
                .readOnlyQuestionModalState(readOnlyQuestionModalState)
                .draftQuestions(draftQuestions)
                .submitConfirmModalVisible(submitConfirmModalVisible)
                .build()
        }
    }
}