import * as React from 'react';
import { Box, Button, Container, Flashbar, FormField, Header, Input, Multiselect, RadioGroup } from '@amzn/awsui-components-react-v3';
import { BreadcrumbGroup, ColumnLayout } from '@amzn/awsui-components-react-v3'
import { EmployeeSuggestions } from '../components/shared/EmployeeSuggestions';
import AdditionalContacts from '../components/shared/AdditionalContacts'
import useItemCreate from './ItemCreate.useItemCreate'
import { Redirect } from "react-router-dom";
import { ValidationField } from '../models/item-detail-model';
import { cetMultiOptions, militaryUseOptions, researchProjectOptions, highlyConfidentialOptions, cetNotApplicableOption, isCNCOptions, isDesignManufacturingOptions, isEnterpriseManagementOptions } from "./../components/Itemv2/questionOption.constants";
import { getSaasDefinition } from '../components/shared/SaaSRestrictionDefinition';

const ItemCreate = () => {
  const {
    errorMessages,
    itemId,
    setErrorMessages,
    itemInfo,
    onItemInfoChange: onChange,
    onNewSubmit: onSubmit,
    bcItemCreate
  } = useItemCreate()

  const getErrorMessage = (field: string): string => {
    if (!errorMessages || errorMessages.length === 0) {
      return '';
    }

    for (const message of errorMessages) {
      if (message.field === field) {
        return message.message
      }
    }
    return ''
  }

  const dissmissErrorMessage = (field: string) => {
    if (!errorMessages || errorMessages.length === 0) {
      return;
    }

    const deepCopyErrorMessages = JSON.parse(JSON.stringify(errorMessages))
    for (const message of deepCopyErrorMessages) {
      if (message.field === field) {
        message.message = ''
      }
    }
    setErrorMessages(deepCopyErrorMessages)
  }

  const itemIdentification = <Container header="Item Identification">
    <ColumnLayout>
      <ColumnLayout columns={1}>
        <FormField id="Item_Full_Name" label={<div><span>Item Full Name</span></div>}
            description="The name of the product, service, or technology. If the Item has not yet been officially named, use the internal code name"
            errorText={getErrorMessage(ValidationField.ItemFullName)}
        >
          <Input
              value={itemInfo.ItemFullName}
              onChange={event => {
                  onChange({ ItemFullName: event.detail.value })
                  dissmissErrorMessage(ValidationField.ItemFullName)
              }
              }
          />
        </FormField>
        <FormField id="Internal_Code_Name" label={<div><span>Internal Code Name</span></div>}
          description="The code name used internally for the product, service, or technology"
          errorText={getErrorMessage(ValidationField.CodeName)}
        >
          <Input
            value={itemInfo.CodeName}
            onChange={event => {
                onChange({ CodeName: event.detail.value })
                dissmissErrorMessage(ValidationField.CodeName)

            }}
          />
        </FormField>
      </ColumnLayout>
      <ColumnLayout columns={1}>
        <FormField id="version" label={<div><span>Item version<i> - optional</i></span></div>}
            description="The current version of the product, service, or technology"
        >
          <Input
              value={itemInfo.CustomerDefinedVersion}
              onChange={(event) => {
                  onChange({ CustomerDefinedVersion: event.detail.value })

              }}
          ></Input>
        </FormField>
        <FormField id="highly_confidential" label={<div><span>Highly Confidential</span></div>}
          description={<span>Clicking "yes" will limit access to this record to employees in this <a href="https://permissions.amazon.com/a/team/cript-leadership" target="_blank">permission group</a>, as well as any users specified in the "Product Manager", "Business Line Lawyer", and "Additional Contributors" fields.</span>}
        >
          <RadioGroup
            items={highlyConfidentialOptions}
            value={itemInfo.Confidential}
            onChange={(event) => {
              onChange({ Confidential: event.detail.value })

            }}
          ></RadioGroup>
        </FormField>
      </ColumnLayout>
    </ColumnLayout>
  </Container>

  const contacts = <Container header="Contacts">
    <ColumnLayout columns={1}>
        <FormField id="product_manager" label={<div><span>Product Manager</span></div>}
            errorText={getErrorMessage(ValidationField.Pm)}
        >
          <EmployeeSuggestions
            key={itemInfo.Pm}
            onSelect={selected => {
              onChange({ Pm: selected.alias, PmName: selected.name })
              dissmissErrorMessage(ValidationField.Pm)
            }}
            value={itemInfo.Pm}
          />
        </FormField>
        <FormField id="business_lawyer" label={<div><span>Business Line Lawyer</span></div>}
          errorText={getErrorMessage(ValidationField.BusinessLineAttorney)}
        >
          <EmployeeSuggestions
            key={itemInfo.BusinessLineAttorney}
            onSelect={(selected) => {
              {
                  onChange({ BusinessLineAttorney: selected.alias, BusinessLineAttorneyName: selected.name })
                  dissmissErrorMessage(ValidationField.BusinessLineAttorney)
              }
            }}
            value={itemInfo.BusinessLineAttorney}
          />
        </FormField>
        <FormField id="additional_contributor" label={<div><span>Additional Contributors<i> - optional</i></span></div>}
          description=' In addition to the Product Manager and Business Line Lawyer for this Item, the following individuals can view all Item details and perform any actions on this Item. '
        >
          <ColumnLayout>
            <AdditionalContacts
              items={itemInfo.OtherContributors}
              customOnChange={(value) => {
                onChange({ OtherContributors: value })
              }}
            />
          </ColumnLayout>
        </FormField>
    </ColumnLayout>
  </Container>

  const details =
    <Container header='Details'>
      <ColumnLayout columns={1}>
        <FormField id="description" label={<div><span>Description</span></div>}
          description='Provide a description of the Item, including what you understand to be the primary use/function.'
          errorText={getErrorMessage(ValidationField.Description)}
        >
          <Input
              value={itemInfo.Description}
              onChange={event => {
                  onChange({ Description: event.detail.value })
                  dissmissErrorMessage(ValidationField.Description)
              }
              }
          />
        </FormField>
        <FormField
          id="online_documentation" 
          label={<div><span>Online Documentation<i> - optional</i></span></div>}
          description="Provide a link to the item's online information"
        >
          <Input
            value={itemInfo.OnlineDocumentation}
            onChange={event => {
              onChange({ OnlineDocumentation: event.detail.value })
            }}
          />
        </FormField>
        <FormField id="military_use" label={<div><span>Military Use</span></div>}
          description={<div>
              Is either of the following true?
              <ol>
                  <li>The Item is designed specifically or primarily for military use, or</li>
                  <li>Likely customers for this Item are primarily military or defense organizations even if the Item is also intended for broader commercial use.</li>
              </ol>
          </div>}
          errorText={getErrorMessage(ValidationField.MilitaryUse)}
        >
          <RadioGroup items={militaryUseOptions}
            value={itemInfo.MilitaryUse}
            onChange={(event) => {
              onChange({ MilitaryUse: event.detail.value })
              dissmissErrorMessage(ValidationField.MilitaryUse)
            }}
          />
        </FormField>

        <FormField id="research_project" 
          label={<div><span>Research Project</span></div>}
          description={<div>Is the Item part of a basic or applied research project intended to be published and shared broadly within the scientific comunity?<br />
              Answers will generally be "No", but may be "Yes". if e.g., you're working with a university on your Item and the results of that work
              will be published in academic journals or elsewhere in the research community.
            </div>}
          errorText={getErrorMessage(ValidationField.ResearchProject)}
        >
          <RadioGroup items={researchProjectOptions}
            value={itemInfo.ResearchProject}
            onChange={(event) => {
                onChange({ ResearchProject: event.detail.value })
                dissmissErrorMessage(ValidationField.ResearchProject)
            }}
          />
        </FormField>
        <FormField id="cet"
          label={<div><span>Please select which category best fits your Item</span></div>}
          errorText={getErrorMessage(ValidationField.CET)}
        >
          <Multiselect
            options={cetMultiOptions.map((o) => { return {value: o.value, label: o.value, description: o.description}})}
            selectedOptions={itemInfo.CET.map((v) => { 
              return {value: v, label: v }
            })}
            onChange={event => {
              let selected = event.detail.selectedOptions.map(o => o.value)
              if (selected[selected.length - 1] === cetNotApplicableOption) {
                onChange({ CET: [cetNotApplicableOption] })
              } else if (selected.includes(cetNotApplicableOption)) {
                let updatedCET = selected.filter(id => id !== cetNotApplicableOption)
                onChange({ CET: updatedCET })
              } else {
                onChange({ CET: selected})
              }
            }}
          />
        </FormField>

        <FormField id="is_enterprise_management_software" label={<div>
              Is your Item one of the below types of Enterprise Management Software?? Please select yes if your item is a Software-as-a-Service product that has all the features of and/or is marketed as one of the below types of software. Applicable examples include, but are not limited to, RedShift (EDW),  QuickSight (BI), and AWS Supply Chain (SCM). 
              <ol type="a">
                <li>{getSaasDefinition("Enterprise Resource Planning")} (ERP)</li>
                <li>{getSaasDefinition("Customer Relationship Management")} (CRM)</li>
                <li>{getSaasDefinition("Business Intelligence")} (BI)</li>
                <li>{getSaasDefinition("Supply Chain Management")} (SCM)</li>
                <li>{getSaasDefinition("Enterprise Data Warehouse")} (EDW)</li>
                <li>{getSaasDefinition("Computerized Maintenance Management System")} (CMMS)</li>
                <li>{getSaasDefinition("Project management software")}</li>
                <li>{getSaasDefinition("Product Lifecycle Management")} (PLM)</li>
              </ol>
            </div>}
            errorText={getErrorMessage(ValidationField.SaaSRestrictions)}
          >
            <RadioGroup items={isEnterpriseManagementOptions}
              value={itemInfo.SaaSRestrictions.isEnterpriseManagementSoftware}
              onChange={(event) => {
                onChange({ SaaSRestrictions: {...itemInfo.SaaSRestrictions, isEnterpriseManagementSoftware: event.detail.value }})
              }} />
          </FormField>
          <FormField id="is_design_and_manufacturing_software" label={<div>
              Is this Item Design and Manufacturing Software used for architecture, engineering, construction, manufacturing, media, education, and entertainment? Please select yes if your item is a SaaS product that has all the features of and/or is marketed as one of the following types of software:
              <ol type="a">
                <li>{getSaasDefinition("Building Information Modeling")} (BIM)</li>
                <li>{getSaasDefinition("Computer Aided Design")} (CAD)</li>
                <li>{getSaasDefinition("Computer-Aided Manufacturing")} (CAM)</li>
                <li>{getSaasDefinition("Engineer to Order")} (ETO)</li>
              </ol>
            </div>}
            errorText={getErrorMessage(ValidationField.SaaSRestrictions)}
          >

            <RadioGroup items={isDesignManufacturingOptions}
              value={itemInfo.SaaSRestrictions.isDesignAndManufacturingSoftware}
              onChange={(event) => {
                onChange({ SaaSRestrictions: {...itemInfo.SaaSRestrictions, isDesignAndManufacturingSoftware: event.detail.value }})
              }} />
          </FormField>
          <FormField id="is_cnc_software" label={<div>
            Is this Item software for the {getSaasDefinition("operation")} of computer numerical control (CNC) machine tools?
            </div>}
            errorText={getErrorMessage(ValidationField.SaaSRestrictions)}
          >
            <RadioGroup items={isCNCOptions}
              value={itemInfo.SaaSRestrictions.isCNCSoftware}
              onChange={(event) => {
                onChange({ SaaSRestrictions: {...itemInfo.SaaSRestrictions, isCNCSoftware: event.detail.value }})
              }} />
          </FormField>
      </ColumnLayout>
    </Container>

  if (itemId) {
    return <Redirect to={{ pathname: `${itemId}`, state: { isNew: true } }} />
  }

  return (
    <div style={{ padding: '2rem' }} >
      <BreadcrumbGroup items={bcItemCreate}/>
      <h1 className='cript-page-header'>Create Item</h1>
      {errorMessages.length > 0 && (
        <div id="error-flash" className="CRIPT-notification">
          <Flashbar items={[{type: "error", content: "Submit failed. Review the error(s) in the form above and try again."}]} />
        </div>
      )}
      {itemIdentification}
      {contacts}
      {details}
      <Header actions={
        <Button variant="primary"
          onClick={() => {
            onSubmit()
          }}
        >Submit</Button>}
      ></Header>
    </div >
  )
}

export default ItemCreate