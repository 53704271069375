import * as React from 'react'
import { useState } from 'react'
import { Box, Button, ColumnLayout, FormField, Icon, Input, Modal, Popover, SpaceBetween, Textarea } from '@amzn/awsui-components-react-v3';
import {Keyword} from '../../content/keyword-management'
import { Builder } from 'builder-pattern';

export interface KeywordModalProps {
    keyword: Keyword;
    headerText: string;
    primaryActionButtonText: string;
    onChange: (keyword: Keyword) => void
    onCancel: () => void
    visible: boolean
}

function createDefaultKeyword() {
    return Builder<Keyword>()
            .Name("")
            .Description("")
            .build();
} 

export const KeywordModal = (props: KeywordModalProps) => {

    const [keyword, setKeyword] = useState<Keyword>(props.keyword ? props.keyword : createDefaultKeyword())

    return (
        <Modal 
            visible={props.visible}
            header={props.headerText}
            footer={
            <Box float="right">
                <SpaceBetween size='xs' direction='horizontal'>
                <Button
                    variant="normal"
                    onClick={() => {props.onCancel()}}
                >
                    Cancel
                </Button>
                <Button 
                    variant="primary"
                    onClick={() => {
                        if (keyword && keyword.Name) {
                            props.onChange(keyword)
                            setKeyword(createDefaultKeyword())
                        }
                    }}
                >
                   {props.primaryActionButtonText}
                </Button>
                </SpaceBetween>
            </Box>
            }
            onDismiss={() => {
                props.onCancel()
            }}
        >
            <div>
            <ColumnLayout columns={1}>
                <FormField
                label="Name"
                description={
                    <SpaceBetween size="xs" direction="horizontal">
                        <span>Enter name of keyword</span>
                        <Popover
                            dismissButton={false}
                            position="top"
                            size="small"
                            triggerType="custom"
                            content={"Keyword name will be stored in lowercase"}
                            >
                            <Icon name="status-info"/>
                        </Popover>
                    </SpaceBetween>
                }
                >
                <Input 
                    ariaLabel='KeywordName'
                    value={keyword.Name}
                    onChange={({detail}) => {
                        setKeyword((prev) => Builder(prev).Name(detail.value).build())
                    }}  
                ></Input>
                </FormField>
                <FormField
                label="Description"
                description="Enter description of keyword"
                >
                <Textarea 
                    ariaLabel="KeywordDescription"
                    value={keyword.Description}
                    onChange={({detail}) => {
                        setKeyword((prev) => Builder(prev).Description(detail.value).build())
                    }}
                />
                </FormField>
            </ColumnLayout>
            </div>
      </Modal>
    )
}