import { Type } from './historyPane.constants'
import { QuestionPane } from './historyPane.QuestionPane'
const HistoryPane = (setPane, setHide, setOpen) => {

    const reducer = (load) => {
        const { type, payload } = load;
        switch (type) {
            case Type.QUESTION:

                setHide(false);
                setOpen(true);
                setPane(QuestionPane(payload))
                break;
            default:
                break;
        }
    }

    return {
        reducer,
    }
}

export default HistoryPane