import * as React from 'react'
import { useState, useEffect } from 'react'
import { BreadcrumbGroup, Button, Modal, DatePicker, Flashbar, SelectProps, Form } from "@amzn/awsui-components-react-v3"
import { dashboardStorageKey } from '../dashboard.constants'
import { getAdminComplianceDashboard } from '../../../actions/dashboard.action'
import { saveUserActivity, UserActivityAction } from "../../../actions/userActivity.action";
import { tableData } from './Definitions';
import { CollectionTable } from "./../../../components/common/Table/CollectionTable"
import { getItem, updateAnnualReportDate } from '../../../actions/async.action'

interface ContentSelectOption {
  id: string; visible: boolean; label: string
}
const bcItem = [
  {
    "text": "Home",
    "href": "#"
  },
  {
    "text": "Item compliance",
    "href": "",
  },
]

const AdminComplianceDashboard = () => {
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [selectedAnnualReportDate, setSelectedAnnualReportDate] = useState("")
  const [annualReportDateValidationError, setAnnualReportDateValidationError] = useState("")
  const [showAnnualReportModal, setShowAnnualReportModal] = useState(false)
  const [notification, setNotification] = useState(null)

  const contentSelectOptions: ContentSelectOption[] = tableData(items).map((definition) => {
    const id = definition.columnDefinition.id
    const visible = definition.visibilityEditable
    const label = definition.columnDefinition.header.toString()
    return {
      id,
      visible,
      label
    }
  })

  // Function exists to mitigate https://t.corp.amazon.com/P78872072
  // To be deleted in the future
  const cleanContentSelection = (
    contentSelection: ContentSelectOption[]
  ) => {
    let selections: ContentSelectOption[] = [];
    contentSelection.map((c) => {
      contentSelectOptions.map((o) => {
        if (c.id.replace(/\s+/g, '').toLowerCase() === o.id.replace(/\s+/g, '').toLowerCase()
          || c.id.replace(/\s+/g, '').toLowerCase() === o.label.replace(/\s+/g, '').toLowerCase()
        ) {
          selections.push(Object.assign({}, o, {visible: c.visible}))
        }
      })
    })
    return selections;
  }

  const pagination = parseInt(localStorage.getItem(dashboardStorageKey.AdminCompliancePagination)) || 20;
  const contentSelection = localStorage.getItem(dashboardStorageKey.AdminComplianceContentSelection) ?
    cleanContentSelection(JSON.parse(localStorage.getItem(dashboardStorageKey.AdminComplianceContentSelection))) :
    contentSelectOptions;

  useEffect(() => {
    saveUserActivity({
      action: UserActivityAction.SEARCH_ITEM_COMPLIANCE,
      itemId: ""
    });
    getAdminComplianceDashboard()
      .then((res) => {
        setItems(res.data)
        setLoading(false)
      })
  }, [])

  const onChangePagination = (pagination: number) => {
    localStorage.setItem(dashboardStorageKey.AdminCompliancePagination, '' + pagination)
  }

  const handleAnnualReportProcess = async (selectedItems, selectedDate) => {
    setLoading(true)
    try {
    const hasItemWithSubjectNoAndEmpty = selectedItems.some(item => !item.SubjectToAnnualReporting || item.SubjectToAnnualReporting === 'No');
      if (hasItemWithSubjectNoAndEmpty) {
        setNotification({
          type: 'error',
          content: 'One or more selected items have "SubjectToAnnualReport" is set to No or Empty . Annual reporting cannot be processed for these items.',
        });
      } else {
        for await (const selectedItem of selectedItems) {
          const itemId = selectedItem.Id;
          let latestItem = await getItem(itemId);
          latestItem.AnnualReportDate = selectedDate;
          await updateAnnualReportDate(itemId, selectedDate);
        }
        const res = await getAdminComplianceDashboard();
        setItems(res.data);
        setShowAnnualReportModal(false);
        setSelectedAnnualReportDate("");
        setNotification({
          type: 'success',
          content: 'Annual report date has successfully processed for Items',
        });
      }
    } catch (error) {
      console.error('Error processing items:', error);
      setNotification({
        type: 'error',
        content: 'Error processing items. Please try again.',
      });
    } finally {
      setLoading(false);
    }
  };
  
  const onChangeContentSelection = (contentSelections: string[]) => {
    const deepCopyContentSelectOptions: ContentSelectOption[]
      = JSON.parse(JSON.stringify(contentSelectOptions))
    deepCopyContentSelectOptions.forEach((option) => option.visible = false)
    contentSelections.forEach((selection) => {
      const index = deepCopyContentSelectOptions.findIndex((option) => option.id === selection)
      deepCopyContentSelectOptions[index].visible = true;
    })
    localStorage.setItem(dashboardStorageKey.AdminComplianceContentSelection, JSON.stringify(deepCopyContentSelectOptions))
  }

  const paginationOptions = [
    { value: 10, label: "10 items" },
    { value: 20, label: "20 items" },
    { value: 30, label: "30 items" },
    { value: 50, label: "50 items" },
    { value: 100, label: "100 items" },
  ]

  const isProcessButtonDisabled = selectedItems.length === 0

  const handleSelectChange = (selected) => {setSelectedItems(selected)};
  
  return (
    <div style={{ padding: '2rem' }} >
      <Modal
         onDismiss={() => {
          setShowAnnualReportModal(false);
          setSelectedAnnualReportDate("");
          setAnnualReportDateValidationError(null);
        }}
        visible={showAnnualReportModal}
        header="Process Annual Report"
        size='medium'
        footer={
          <span className="awsui-util-f-r">
            <Button
              onClick={() => {
                setShowAnnualReportModal(false);
                setSelectedAnnualReportDate("");
                setAnnualReportDateValidationError(null); 
              }}
              variant='link'
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={() => {
                  handleAnnualReportProcess(selectedItems, selectedAnnualReportDate);
                  setShowAnnualReportModal(false);
                  setSelectedAnnualReportDate("");
              }}
            >
              Confirm complete
            </Button>
          </span>
        }
      >
      <p>Select the date for the annual report:</p>
        <Form errorText={annualReportDateValidationError}>
          <DatePicker
            value={selectedAnnualReportDate}
            onChange={(event) => setSelectedAnnualReportDate(event.detail.value)}
            isDateEnabled={(date) => date <= new Date()}
          />
        </Form>
      </Modal>
      {notification && (
        <Flashbar
          items={[
            {
              type: notification.type,
              content: notification.content,
              dismissible: true, 
              onDismiss: () => setNotification(null),
            },
          ]}
        />
      )}
      <BreadcrumbGroup items={bcItem}/>
      <h1 className='cript-page-header'>Item compliance</h1>
      <CollectionTable
        allItems={items}
        tableData={tableData(items)}
        headerText="All Items"
        headerActions={
          <Button
            variant='primary'
            onClick={() => {
              setShowAnnualReportModal(true);
            }}
            disabled={isProcessButtonDisabled}
            >
            Process Annual Reporting
          </Button>
        }
        onChangeCollectionPreferences={(selected) => {
          onChangeContentSelection(selected)
        }}
        visibleColumnNames={contentSelection.filter((c) => c.visible).map((c) => c.id)}
        loading={loading}
        onChangePagination={(pageSize) => onChangePagination(pageSize)}
        selectionType="multi"
        onSelect={handleSelectChange}
        selected={selectedItems}
        paginationOptions={paginationOptions}
        pageSize={pagination}
        defaultSortFieldId={'ItemFullName'}
        defaultSortDescending={false}
      />
    </div>
  );
}

export default AdminComplianceDashboard;