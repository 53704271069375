import * as React from "react";
import {
  Alert,
  ColumnLayout,
  SegmentedControl,
  SegmentedControlProps,
  Spinner,
} from '@amzn/awsui-components-react-v3';
import { ItemDetailModel, UserInfoContext, ECCN_STATUS } from "../../models/item-detail-model";
import { Builder } from "builder-pattern";
import { ItemContainer } from "./ItemContainer"
import { ItemModalComps } from "./ItemModalComps";
import { Question_Status } from "../../models/question-model";

export const REQUIRED_MESSAGE = 'Required';

export namespace ItemTabs {
  export const Detail = (
    props: ItemDetailModel.UI.State
  ) => {

    const adminContainers = (
      <React.Fragment>
        <ItemContainer.Classification state={props.classificationState} />
        <ItemContainer.Approval state={props.approvalState} />
        <ItemContainer.BaselineExemption state={props.baselineExemptionState} />
        <ItemContainer.AdminFiles state={props.adminDocumentState} />
        <ItemModalComps.ConfirmECCNPromotionModal state={props.classificationState.confirmECCNPromotionModalState} />
      </React.Fragment>
    )

    return (
      <UserInfoContext.Consumer>
        {value =>
          <div>
            <ColumnLayout columns={1} >
              <ItemContainer.ItemSummary state={props.summaryState} />
              <ItemContainer.Contact state={props.contactState} />
              <ItemContainer.Detail state={props.detailState} />
              {(value.isAdmin || value.isContributor) && props.itemState.value.ItemType === "Software" &&
                <ItemContainer.AmazonDependency state={props.amazonDependencyState} />}
              {(value.isAdmin || value.isContributor) && props.itemState.value.ItemType === "Software" &&
                <ItemContainer.ThirdPartyDependency state={props.thirdPartyDependencyState} />}
              {(value.isAdmin || value.isContributor) &&
                <ItemContainer.RelatedDocument state={props.relatedDocuemntState} />}
              {value.isAdmin && adminContainers}
            </ColumnLayout>
          </div>
        }
      </UserInfoContext.Consumer>
    )
  }

  export const ConditionalQuestionnaireCompletionAlert = (
    props: {state: ItemDetailModel.UI.State}
  ) => {
    const questionnaireFinished = props.state.questionnaireState.questionnaire.value.filter(
      q => q.status !== Question_Status.CLOSED
    ).length === 0;
    if (!!props.state.loading.value) {
      return (
        <Alert><Spinner /></Alert>
      )
    }
    if (questionnaireFinished) {
      if (props.state.itemState.value.ECCNLocked) {
        return (
          <Alert>
            You have answered all the questions required. The Export Compliance team will review and classify your Item.
          </Alert>
        )
      }
      if (props.state.itemState.value.ECCNStatus === ECCN_STATUS.ACTIVE) {
        return (
          <Alert type='success'>
            You have answered all the questions required. The ECCN for this Item is : {props.state.itemState.value.ECCN}
          </Alert>
        )
      }
      if (`${props.state.itemState.value.ECCNStatus}` !== ECCN_STATUS.ACTIVE) {
        return (
          <Alert>
            You have answered all the questions required. The Export Compliance team will review and classify your Item.
          </Alert>
        )
      }
    }
    return <></>;
  }

  export const Questionnaire = (
    props: ItemDetailModel.UI.State
  ) => {

    const accessibilityQuestionnaireFinished = props.accessibilityQuestionnaireState.questionnaire.value.filter(
      q => q.status !== Question_Status.CLOSED
    ).length === 0;
    const [segmentId, setSegmentId] = React.useState<"Primary Questionnaire" | "Accessibility Questionnaire">("Primary Questionnaire")

    const primaryQuestionnaire = (segmentId === "Primary Questionnaire" &&
      <React.Fragment>
        <ItemModalComps.EditQuestionModal state={props.questionnaireState.editModalState} />
        <ItemModalComps.RefreshQuestionModal state={props.questionnaireState.refreshModalState} />
        <ItemContainer.Questionnaire state={props.questionnaireState} />
        <ConditionalQuestionnaireCompletionAlert state={props} />
        <ItemModalComps.SoftwareDependencyModal state={props.softwareDependenciesModalState} />
      </React.Fragment>
    )

    const accessibilityQuestionniare = (segmentId === "Accessibility Questionnaire" &&
      <React.Fragment>
        <ItemModalComps.EditAccessibilityQuestionModal state={props.accessibilityQuestionnaireState.editModalState} />
        <ItemModalComps.RefreshAccessibilityQuestionModal state={props.accessibilityQuestionnaireState.refreshModalState} />
        <ItemContainer.Questionnaire state={props.accessibilityQuestionnaireState} />
        {accessibilityQuestionnaireFinished &&
          <Alert type='success'>You have answered all the questions required.</Alert>
        }
      </React.Fragment>
    );

    return (
      <UserInfoContext.Consumer>
        {value =>
          <>
            {/* <Box textAlign="center"> */}
              <SegmentedControl
                className="text-align-center"
                options={[Builder<SegmentedControlProps.Option>().id("Primary Questionnaire").text("Primary Questionnaire").build(),
                Builder<SegmentedControlProps.Option>().id("Accessibility Questionnaire").text("Accessibility Questionnaire").build()]}
                selectedId={segmentId}
                onChange={(e) => setSegmentId(e.detail.selectedId as "Primary Questionnaire" | "Accessibility Questionnaire")}
              />
            {/* </Box> */}
            {primaryQuestionnaire}
            {accessibilityQuestionniare}
          </>}
      </UserInfoContext.Consumer>
    )
  }
  export const Supplement = (
    props: ItemDetailModel.UI.State
  ) => {
    return (
      <UserInfoContext.Consumer>
        {value =>
          <ColumnLayout columns={1} >
            {(value.isAdmin || value.isContributor) && <ItemContainer.Supplements state={props.CCATsSupplementState} />}
          </ColumnLayout>
        }
      </UserInfoContext.Consumer>
    )
  }
}