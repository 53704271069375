export function deepCompareObjects(obj1, obj2): boolean {
    let difference = false;
    for (const key in obj1) {
        if (!deepEqual(obj1[key], obj2[key])) {
            difference = true;
        }
    }
    return difference;
}

export function deepEqual(x, y) {
    if (x === y) {
        return true;
    }
    else if ((typeof x == "object" && x != null) && (typeof y == "object" && y != null)) {
        if (Object.keys(x).length != Object.keys(y).length)
            return false;

        for (var prop in x) {
            if (y.hasOwnProperty(prop)) {
                if (!deepEqual(x[prop], y[prop]))
                    return false;
            }
            else
                return false;
        }

        return true;
    }
    else
        return false;
}

// input utcDate
// add timezone offset
export function getTimezoneIndependentDate(utcDate: Date) {
    utcDate = new Date(utcDate);
    const localDate = new Date();
    return utcDate.setMinutes(utcDate.getMinutes() + localDate.getTimezoneOffset())
}

export function formatAndLocalizeDate(utcDate: Date) {
    return new Date(getTimezoneIndependentDate(utcDate)).toLocaleDateString();
}

export function formatAndLocalizeDateTime(utcDate: Date) {
    return new Date(getTimezoneIndependentDate(utcDate)).toLocaleString();
}

/**
 *
 * @param date
 * return current date with format "QX YYYY"
 * example/ Q1 2021
 */
export function getFormattedQuarterYear(date: Date): string {
    const month = date.getMonth() + 1;
    const quarter = Math.ceil(month / 3);
    const year = date.getFullYear();

    return `Q${quarter} ${year}`;
}

export function activityWatcher(debounceRate: number, fn) {
    // An array of DOM events that should be interpreted as user activity.
    const activityEvents = [
        'mousedown', 'mousemove', 'keydown',
        'scroll', 'touchstart'
    ];

    // add these events to the document.
    // register the activity function as the listener parameter.
    activityEvents.forEach(function (eventName) {
        document.addEventListener(eventName, debounced(debounceRate, fn), true);
    });
}

// invokes function at a maximum rate of once per delay amount (in miliseconds)
export function debounced(delay: number, fn) {
    let timerId;
    return function (...args) {
        if (timerId) {
            clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
            fn(...args);
            timerId = null;
        }, delay);
    }
}

let o = new Intl.DateTimeFormat(navigator.language, {
    hour12: true,
    minute: "2-digit",
    hour: "2-digit",
    second: "2-digit",
    day: "numeric",
    year: "numeric",
    month: "numeric",

});
export function formatStringToDate(dateString: string) {
    if (!dateString) return ""
    return o.format(new Date(dateString))
}