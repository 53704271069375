import { Builder } from "builder-pattern";
import { useState } from "react";
export namespace UIModel {


  export function assertNotNull<T>(value?: T) {
    if (value === null || value === undefined) return "value can't be null";
  }

  export function assertNumberRange(low?: number, high?: number) {
    return (value: number, low?: number, high?: number) => {
      if (!value) return "value can't be null";
      if (low && value < low) return `value can't less than ${low}`;
      if (high && value > high) return `value can't greater than ${high}`;
    };
  }

  export class FieldState<T> {
    initialValue?: T;
    value?: T;
    errorText?: string;

    setValue: (t: T) => void;
    reset: () => void;

    static create<T>(props: {
      initialValue?: T;
      value?: T;
      errorText?: string;
      setValue: (t: T) => void;
      reset: () => void;
    }): FieldState<T> {
      let builder = Builder(new FieldState<T>())
        .value(props.value)
        .initialValue(props.initialValue)
        .errorText(props.errorText)
        .setValue(props.setValue)
        .reset(props.reset);

      return builder.build();
    }

    static use<T>(props: {
      name?: string;
      initialValue?: T;
      assert?: (t: T) => string;
    }) {
      const createValueAndErrorText = (v: T) => {
        return {
          data: v,
          errorText: props.assert ? props.assert(v) : null
        };
      };
      const [name] = useState<string>(props.name);
      const [initialValue] = useState<T>(props.initialValue);
      const [valueAndErrorText, setValueAndErrorText] = useState<{
        data?: T;
        errorText: string;
      }>(createValueAndErrorText(props.initialValue));

      const setValue = (v: T) => {
        let newValue = createValueAndErrorText(v);
        setValueAndErrorText(newValue);
      };

      return UIModel.FieldState.create<T>({
        initialValue: initialValue,
        value: valueAndErrorText.data,
        errorText: valueAndErrorText.errorText,
        setValue: setValue,
        reset: () => setValue(initialValue),
      });
    }

    static useRequired<T>(props: { initialValue?: T; name?: string }) {
      const field = FieldState.use({
        initialValue: props.initialValue,
        assert: assertNotNull,
      });

      return UIModel.FieldState.create<T>({
        initialValue: field.initialValue,
        value: field.value,
        errorText: field.errorText,
        setValue: field.setValue,
        reset: () => field.setValue(field.initialValue),
      });
    }

    static useRequiredArray<T>(initialValue?: T[], name?: string) {
      const field = FieldState.useRequired<T[]>({ initialValue: initialValue });

      return UIModel.FieldState.create<T[]>({
        initialValue: field.initialValue,
        value: field.value,
        errorText: field.errorText,
        setValue: field.setValue,
        reset: () => field.reset,
      });
    }

    static useNumber(
      initialValue?: number,
      greaterThan?: number,
      lessThan?: number,
      name?: string
    ): UIModel.FieldState<number> {
      const field = FieldState.use<number>({
        name: name,
        initialValue: initialValue,
        assert: assertNumberRange(greaterThan, lessThan),
      });

      return UIModel.FieldState.create<number>({
        initialValue: field.initialValue,
        value: field.value,
        errorText: field.errorText,
        setValue: field.setValue,
        reset: () => field.reset,
      });
    }
  }
}
