import * as React from 'react';
import { Link } from "@amzn/awsui-components-react-v3";
import { useItemFullName } from "../../hooks/useItemFullName"

export const ItemLink = (props: {itemId: string}) => {
  const itemFullName = useItemFullName(props.itemId);

  return (
    <Link
      external
      href={`#/items/${props.itemId}`}
    >
      {itemFullName || props.itemId}
    </Link>
  )
}