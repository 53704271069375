import { apiGatewayCogntioClient } from "../utils/apigatewayHelper";
import { store } from "../main";


export function getEmployeeSuggestions(searchTerm: string) {
    const appState = store.getState();

    const token = appState.authenticationObj.userDetails.jwtToken;

    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };

    return apiClient.invokeApi({}, `/api/employee/search?searchTerm=` + searchTerm, "GET", headers, {})
}