import * as React from 'react';
import { useEffect, useState } from 'react';
import { TokenGroup, TokenGroupProps } from '@amzn/awsui-components-react-v3';
import { ItemAutoSuggest } from "./ItemAutoSuggest";
import { Builder } from "builder-pattern"
import { batchGetItems } from "../../actions/async.action";
import { GeneralHooks } from '../../hooks/general-hooks';
import { Item } from '../../models/item-model';

interface ItemMultiSelectProps {
  itemRefs: string[];
  onChange?: (itemRefs: string[]) => void;
}

export const ItemMultiSelect = (props: ItemMultiSelectProps) => {
  const [workingItemRefs, setWorkingItemRefs] = useState<string[]>(props.itemRefs)
  const [workingItems, setWorkingItems] = useState<Item.Data[]>([])

  const batchGetItemsRunner = GeneralHooks.useAnyRunner<string[], {data: Item.Data[]}, any>(batchGetItems);

  useEffect(() => {
    if (workingItemRefs.length) {
      batchGetItemsRunner.submitRun(workingItemRefs)
    }
    if (props.onChange) {
      props.onChange(workingItemRefs)
    }
  }, [workingItemRefs.length])

  useEffect(() => {
    setWorkingItemRefs(workingItems.map(i => i.Id))
  }, [workingItems?.length])
  
  useEffect(() => {
    if (batchGetItemsRunner.status === "Running" || batchGetItemsRunner.status === "New") {
      return;
    }
    if (batchGetItemsRunner.status === "Finished") {
      // batchGet response is un-ordered
      // sort Items based on current order
      const sortedItems = batchGetItemsRunner.data.data.sort(
        (a, b) => workingItemRefs.indexOf(a.Id) - workingItemRefs.indexOf(b.Id)
      );
      setWorkingItems(sortedItems);
    }
  }, [batchGetItemsRunner.data])

  return (
    <>
      <ItemAutoSuggest
        onSelect={(id) => {
          if (!workingItemRefs.includes(id)) {
            let updatedWorkingItemRefs = workingItemRefs.slice();
            updatedWorkingItemRefs.push(id)
            setWorkingItemRefs(updatedWorkingItemRefs);
          }
        }}
      />
      <TokenGroup
        onDismiss={(event) => {
          let updatedWorkingItems = workingItems.slice();
          updatedWorkingItems.splice(event.detail.itemIndex, 1)
          setWorkingItems(updatedWorkingItems);
        }}
        items={workingItems.map((i) => {
          return Builder<TokenGroupProps.Item>()
            .label(i.ItemFullName)
            .build()
        })}
      />
    </>
  )
}