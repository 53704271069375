import { Box, CollectionPreferences, CollectionPreferencesProps, Header, Pagination, PropertyFilter, PropertyFilterProps, Table, TableProps } from "@amzn/awsui-components-react-v3";
import React, { useEffect, useState } from "react"
import { useCollection } from "@amzn/awsui-collection-hooks";
import { i18nStringsPropertyFilter } from "./../i18Strings"
import { Builder } from "builder-pattern";

interface TableData {
  key: string;
  columnDefinition: TableProps.ColumnDefinition<any>;
  values: string[];
  propertyFilter: boolean;
  filterOperators?: PropertyFilterProps.ComparisonOperator[];
  visibilityEditable: boolean;
}

interface CollectionTableProps {
    allItems: any[];
    tableData: TableData[];
    headerText: string;
    headerActions?: JSX.Element; 
    defaultSortFieldId?: string;
    defaultSortDescending?: boolean;
    defaultHiddenColumns?: string[];
    selectionType?: TableProps.SelectionType
    selected?: any[];
    onSelect?: (selected: any[]) => void;
    onChangeCollectionPreferences?: ((selections: string[]) => void);
    visibleColumnNames?: string[];
    loading?: boolean;
    onChangePagination?: ((pageSize: number) => void)
    paginationOptions?: CollectionPreferencesProps.PageSizeOption[]
    pageSize?: number;
}

export const CollectionTable = (props: CollectionTableProps) => {
    const [pageSize, setPageSize] = useState<number>(props.pageSize || 10)
    const [visibleColumns, setVisibleColumns] = useState<string[]>([])

    function propertyFilters(): PropertyFilterProps.FilteringProperty[] {
      let propertyFilters : PropertyFilterProps.FilteringProperty[] = []

      props.tableData
        .filter((d) => d.propertyFilter)
        .map((d) => 
          propertyFilters.push(
            Builder<PropertyFilterProps.FilteringProperty>()
            .groupValuesLabel(`${d.columnDefinition.header} Values`)
            .propertyLabel(`${d.columnDefinition.header}`)
            .key(d.key)
            .operators([":", "=", "!="])
            .build()
          )
        )

      return propertyFilters
    }


    useEffect(() => {
      if (props.visibleColumnNames?.length > 0) {
        setVisibleColumns(props.visibleColumnNames);
      } else {
        // if visible columns is empty
        let visible_columns = []
        for (let column of props.tableData) {
          if (props.defaultHiddenColumns) {
            if ((props.defaultHiddenColumns.findIndex((c) => c === column.columnDefinition.id) === -1)) {
              visible_columns.push(column.columnDefinition.id)
            }
          } else {
            visible_columns.push(column.columnDefinition.id)
          }
        }
        setVisibleColumns(visible_columns);
      }
    }, [props.visibleColumnNames])

     // eslint-disable-next-line
    const { items, actions, filteredItemsCount, collectionProps, paginationProps, propertyFilterProps } = useCollection(
      props.allItems,
      {
        propertyFiltering: {
          filteringProperties: propertyFilters(),
        },
        pagination: { pageSize: pageSize },
        sorting: { 
          defaultState: { 
            sortingColumn: { 
              sortingField: props.defaultSortFieldId 
            },
            isDescending: props.defaultSortDescending
          } 
        },
        selection: {}
      }
    );
  
    return (
        <div>
          <Table
            {...collectionProps}
            loadingText={"Loading resources"}
            loading={props.loading}
            columnDefinitions={props.tableData.map((d) => d.columnDefinition)}
            resizableColumns
            items={items}
            wrapLines={false}
            header={
              <Header
              variant="h2"
              counter={`(${filteredItemsCount}/${props.allItems.length})`}
              actions={
                props.headerActions
              }
            >
              {props.headerText}
            </Header>
            }
            empty={
              <Box textAlign="center">
                <b>{"No records found"}</b>
              </Box>
            }
            filter={
              <PropertyFilter
                i18nStrings={i18nStringsPropertyFilter()}
                {...propertyFilterProps}
                expandToViewport={true}
              />
            }
            pagination={
              <Pagination
                {...paginationProps}
                onChange={event => {
                  paginationProps.onChange(event);
                }}
              />
            }
            visibleColumns={visibleColumns}
            preferences={
              <CollectionPreferences
                title="Preferences"
                confirmLabel="Confirm"
                cancelLabel="Cancel"
                pageSizePreference={{
                  title: "Select page size",
                  options: props.paginationOptions?.length ? props.paginationOptions :
                    [
                      { value: 10, label: "10 resources" },
                      { value: 25, label: "25 resources" },
                      { value: 50, label: "50 resources" },
                      { value: 100, label: "100 resources" }
                    ]
                }}
                visibleContentPreference={{
                  title: "Select visible content",
                  options: [{
                    label: "",
                    options: props.tableData.map((d) => {
                      return Builder<CollectionPreferencesProps.VisibleContentOption>()
                        .label(d.columnDefinition?.header as string)
                        .id(d.key)
                        .editable(d.visibilityEditable)
                        .build()
                    })
                  }]
                }}
                onConfirm={(e) => {
                  if (e.detail.pageSize) {
                    setPageSize(e.detail.pageSize)
                    if (props.onChangePagination) {
                      props.onChangePagination(e.detail.pageSize)
                    }
                  }
                  if (e.detail.visibleContent) {
                    setVisibleColumns(e.detail.visibleContent.slice());
                  }
                  if (props.onChangeCollectionPreferences) {
                    props.onChangeCollectionPreferences(
                      e.detail.visibleContent.slice()
                    )
                  }
                }}
                preferences={{
                  pageSize: pageSize,
                  visibleContent: visibleColumns,
                  custom: "table"
                }}
              />
            }
            selectionType={props.selectionType}
            selectedItems={props.selected}
            onSelectionChange={(e) => {
              if (props.onSelect) {
                props.onSelect(e.detail.selectedItems)
              }
            }}
          />
        </div>
    );
}