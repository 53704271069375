import * as React from 'react';
import { useState } from 'react';
import { Autosuggest, FormField } from '@amzn/awsui-components-react-v3';
import { getEmployeeSuggestions } from '../../actions/employee.action';
import { DropdownStatusProps } from '@amzn/awsui-components-react-v3/polaris/internal/components/dropdown-status';

export const EmployeeSuggestions = (props: {
    onSelect: (selected: {alias: string; name: string}) => void;
    value: string;
}) => {
    const [value, setValue] = useState(props.value);
    const [options, setOptions] = useState<any[]>([]);
    const [statusText, setStatusText] = useState("");
    const [statusType, setStatusType] = useState<DropdownStatusProps.StatusType>();
    
    const formatSuggestions = (suggestions: any[]) => {
        return suggestions
            .filter((suggestion) => suggestion["alias"])
            .map((suggestion) => {
                let employeeSuggestionOption = {}
                employeeSuggestionOption["value"] = suggestion["alias"].toLowerCase();
                employeeSuggestionOption["labelTag"] = `${suggestion["lastName"]}, ${suggestion["firstName"]}`;
                employeeSuggestionOption["iconUrl"] = `https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${suggestion["alias"]}`;
                employeeSuggestionOption["iconAlt"] = suggestion["alias"];
                return employeeSuggestionOption;
        });
    }

    return (
        <div style={{ position: 'relative' }}>
            <FormField errorText={statusText}>
                <Autosuggest
                    enteredTextLabel={(value) => `Use ${value}`}
                    value={value}
                    filteringType="manual"
                    statusType={statusType}
                    loadingText={"loading"}
                    placeholder="Search by name or alias"
                    empty="start typing to see list of suggestions"
                    options={options}
                    onSelect={(e) => {
                        if (e.detail.value) {
                            props.onSelect({
                                alias: e.detail.value,
                                name: options.find((o) => o.value === e.detail.value).labelTag
                            })
                        }
                    }}
                    onChange={(e) => {
                        if (e.detail.value) {
                            setValue(e.detail.value)
                            getEmployeeSuggestions(e.detail.value)
                                .then(response => {
                                    setOptions(
                                        formatSuggestions(response.data.EmployeeSearchResult)
                                    );
                                })
                                .catch(() => {
                                    setStatusType("error");
                                    setStatusText("Error fetching employees. Continue typing to try again");
                                })
                        } else {
                            setValue("");
                            setOptions([]);
                        }
                    }}
                    onBlur={() => setValue(props.value)}
                />
            </FormField>
        </div>
    )
}