import {Set_AppConfig} from "../actions/config.action";

const intialState={
    configObj:null
};
export default function configObjReducer(state=intialState,action){
    switch (action.type) {
        case Set_AppConfig:
            console.log('set app config payload', action.payload)
            return Object.assign({},state,{configObj: action.payload});
        default:
            return state;
    }
}