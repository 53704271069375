import moment from "moment"

export class DateTimeFormatter {
  static formatDate(date: Date) {
    const d = new Date(date);
    return d.toLocaleDateString() + ", " + d.toLocaleTimeString();
  }

  static formatDateWithoutTime(date: Date) {
    const d = new Date(date);
    return d.toLocaleDateString();
  }

  static toLocaleString(date: Date) {
    if (!date) return;
    return new Date(date).toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  }

  static toDatePickerValueString(date?: Date) {
    if (!date) return "";
    moment.locale("en-US");
    const str = moment(date).format("YYYY-MM-DD");

    return str;
  }

  static parseDatePickerValue(value: string) {
    if (!value) return;
    const date = moment(value, "YYYY-MM-DD").toDate();

    return date;
  }
}