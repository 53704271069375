import { Builder } from "builder-pattern";
import { UIModel } from "./ui-model";
import { useEffect } from "react";
import { Item } from "./item-model";
import {
  baselineItem,
  cloneItem,
  createAction,
  deleteItem,
  getEmployeeSummary,
  getItem,
  getQuestionById,
  getQuestionByIdVersion,
  updateItem,
  promoteECCN,
  createAnnualReport,
  getActionByItemId
} from "../actions/async.action";
import { BreadcrumbGroupProps, FlashbarProps } from "@amzn/awsui-components-react-v3"
import { GeneralHooks } from "../hooks/general-hooks";
import { deleteFileV4, readFile, uploadItemFile } from "../actions/asset.action";
import { store } from "../main";
import { QuestionModel, Question_Status } from "./question-model";
import React = require("react");
import axios from "axios";
import { useHistory } from "react-router";
import { checkAdminStatus } from "../actions/authorization.action";
import { DateTimeFormatter } from "../components/shared/DateTimeFormatter";
import { useDispatch } from 'react-redux';
import { fetchKeywords } from '../reducers/KeywordSlice';

export interface Message {
  type: 'success' | 'error',
  message: string,
  field: string
}
export const RELATED_DOC = "related";
export const ADMIN_DOC = "admin";


export enum ValidationField {
  ItemFullName = 'ItemFullName',
  CodeName = 'CodeName',
  Pm = 'Pm',
  BusinessLineAttorney = 'BusinessLineAttorney',
  Description = 'Description',
  MilitaryUse = 'MilitaryUse',
  ResearchProject = 'ResearchProject',
  CET = 'CET',
  SaaSRestrictions = 'SaaSRestrictions'
}

export enum ECCN_STATUS {
  PENDING_QUESTIONNAIRE_COMPLETION = 'Pending questionnaire completion',
  PENDING_EXPORT_COMPLIANCE_REVIEW = 'Pending Export Compliance review',
  ACTIVE = 'Active'
}

export enum REVIEW_STATUS {
  PENDING = "Pending",
  IN_REVIEW = 'In review',
  ON_HOLD = 'On hold',
  RESOLVED = 'Resolved',
  ADDITIONAL_INFORMATION_REQUESTED = "Additional information requested",
  NO_STATUS = "",
}

export enum ACTION_STATUS {
  PENDING = "Pending",
  CANCELED = 'Canceled',
  COMPLETED = 'Completed'
}

export enum ECCN_Type {
  AUTO = 'Auto',
  MANUAL_ASSIGNED = 'Manual assigned',
  NO_ECCN = 'No ECCN',
}


const bcItemDetails = [
  {
    "text": "Home",
    "href": "#"
  },
  {
    "text": "All Items",
    "href": "#/items"
  },

]

export const UserInfoContext = React.createContext({
  isAdmin: false,
  isContributor: false,
})

interface BaselineMeta {
  activeBaselinePeriod: boolean,
  currentPeriodBaselined: boolean,
  baselinedBy: { Alias: string, FullName: string },
  baselinedDate: Date,
  autoBaselineExemption: boolean,
}

export const CCATsQuestions = [
  { 'question': 'Description of how encryption is used in the product and the types of encrypted data, e.g. stored data, communications, management data, and internal data.' },
  { 'question': 'Description of all the symmetric and asymmetric encryption algorithms and key lengths and how the algorithms are used, including relevant parameters, inputs and settings. Specify which encryption modes are supported (e.g., cipher feedback mode or cipher block chaining mode)' },
  { 'question': 'Describe how encryption keys are generated or managed by your product, including algorithms and modulus sizes supported.' },
  { 'question': 'Describe whether the products incorporate or use “non-standard cryptography” defined as incorporating or using proprietary, unpublished cryptographic functionality, including encryption algorithms or protocols that have not been adopted or approved by a duly recognized international standards body. Provide a textual description and the source code of the algorithm.' },
  { 'question': 'Describe the pre-processing methods (e.g., data compression or data interleaving) that are applied to the plaintext data prior to encryption.' },
  { 'question': 'Describe the post-processing methods (e.g., packetization, encapsulation) that are applied to the cipher text data after encryption.' },
  { 'question': 'State all communication protocols (e.g., X.25, Telnet, TCP, IEEE 802.11, IEEE 802.16, SIP .  .  .) and cryptographic protocols and methods (e.g., SSL, TLS, SSH, IPSEC, IKE, SRTP, ECC, MD5, SHA, X.509, PKCS standards .  .  .), including application programming interfaces (APIs), that are supported and describe how they are used.' },
  { 'question': 'State how the product is written to preclude user modification of the encryption algorithms, key management and key space.' },
  { 'question': 'Describe the cryptographic functionality that is provided by third-party hardware or software encryption components (if any). Identify the manufacturers of the hardware or software components, including specific part numbers and version information as needed to describe the product. Describe whether the encryption software components (if any) are statically or dynamically linked.' },
  { 'question': 'Identify the version(s) and type(s) of compilers, runtime interpreters or code assemblers used, as applicable.' },
]

export namespace ItemDetailModel {
  export namespace UI {
    export class State {
      isAdmin: UIModel.FieldState<boolean>;
      isContributor: UIModel.FieldState<boolean>;
      itemState: UIModel.FieldState<Item.Data>;
      baselineMeta: UIModel.FieldState<BaselineMeta>;
      loading: UIModel.FieldState<boolean>;
      errorMessages: UIModel.FieldState<Message[]>;
      breadcrumb: UIModel.FieldState<BreadcrumbGroupProps.Item[]>;
      flashbarItems: UIModel.FieldState<Array<FlashbarProps.MessageDefinition>>;

      summaryState: Container.SummaryState;
      contactState: Container.ContactState;
      detailState: Container.DetailState;
      amazonDependencyState: Container.AmazonDependencyState;
      thirdPartyDependencyState: Container.ThirdPartyDependencyState;
      relatedDocuemntState: Container.RelatedDocumentState;
      questionnaireState: Container.QuestionnaireState;
      accessibilityQuestionnaireState: Container.AccessibilityQuestionnaireState;
      classificationState: Container.ClassificationState;
      approvalState: Container.ApprovalState;
      baselineExemptionState: Container.BaselinExemptionState;
      adminDocumentState: Container.AdminDocumentState;
      CCATsSupplementState: Container.CCATsSupplementState;

      softwareDependenciesModalState: Modal.SoftwareDependenciesState;
      editQuestionModalState: Modal.EditQuestionModalState;
      performanceModalState: Modal.PerformanceState;
      assignActionModalState: Modal.AssignActionState;
      deleteModalState: Modal.DeleteState;
      deprecatedState: Modal.DeprecateState;
      cloneModalState: Modal.CloneState;
      onPromoteECCNRunner: GeneralHooks.RunnerState<
        {id: string}, {data: Item.Data}, any
      >;
      onCloneRunner: GeneralHooks.RunnerState<
        {
          targetItemId: string;
          clonedItemTitle: string;
          clonedItemInternalCodeName: string;
        },
        {data: Item.Data},
        any
      >;
      onBaselineRunner: GeneralHooks.RunnerState<string,
        {
          data: {
            BaselineDate: string,
            BaselinedBy: string
          }
        },
        any>;
      onDeleteRunner: GeneralHooks.RunnerState<string, any, any>;

      static use(props: { id: string }): State {
        const isAdmin = UIModel.FieldState.use({ initialValue: false });
        const isContributor = UIModel.FieldState.use({ initialValue: false });
        const breadcrumb = UIModel.FieldState.use<BreadcrumbGroupProps.Item[]>({ initialValue: bcItemDetails })
        const itemState = UIModel.FieldState.use<Item.Data>({ initialValue: Item.Data.getDefaultData() });
        const prevItemState = UIModel.FieldState.use<Item.Data>({ initialValue: Item.Data.getDefaultData() });
        const errorMessages = UIModel.FieldState.use<Message[]>({ initialValue: [] });
        const loading = UIModel.FieldState.use<boolean>({ initialValue: false });
        const baselineMeta = UIModel.FieldState.use<BaselineMeta>({
          initialValue: {
            activeBaselinePeriod: false,
            currentPeriodBaselined: false,
            baselinedBy: { Alias: "", FullName: "string" },
            baselinedDate: null,
            autoBaselineExemption: false,
          }
        });
        const flashbarItems = UIModel.FieldState.use<Array<FlashbarProps.MessageDefinition>>(
          { initialValue: [] }
        );

        const onPromoteECCNRunner = GeneralHooks.useAnyRunner<
          string, {data: Item.Data}, any
        >(promoteECCN);

        const onSave = async (updatePartial: Partial<Item.Data>) => {
          try {
            loading.setValue(true);
            const res = await getItem(props.id) as { data: { Item: Item.Data, baselineMeta: BaselineMeta } };
            const item = res.data.Item;
            prevItemState.setValue(item);
            baselineMeta.setValue(res.data.baselineMeta);
            if (item.Pm && (!item.PmCostCenterId || !item.PmName)) {
              const pmSummary = await getEmployeeSummary(item.Pm);
              item.PmCostCenterId = pmSummary.data.costCenterId;
            }
            const newItemData = await updateItem(props.id, {
              ...item,
              ...updatePartial,
              Version: item.Version + 1
            });
            itemState.setValue({ ...newItemData.data });
            errorMessages.setValue([]);
            loading.setValue(false);
          } catch (error) {
            loading.setValue(false);
            if (error.response) {
              flashbarItems.setValue([
                ...flashbarItems.value,
                ...error.response.data.errors.map((e)=> {
                  return Builder<FlashbarProps.MessageDefinition>()
                    .type("error")
                    .header("Error updating Item")
                    .content(e.message)
                    .dismissible(true)
                    .build()
                  }
                )
              ]);
            } else {
              errorMessages.setValue([{ type: "error", message: `${JSON.stringify(error)}`, field: "banner" }]);
            }
          }
        }

        const summaryState = Container.SummaryState.use({ onSave, template: itemState });
        const contactState = Container.ContactState.use({ onSave, template: itemState });
        const detailState = Container.DetailState.use({ onSave, template: itemState });
        const amazonDependencyState = Container.AmazonDependencyState.use({ onSave, template: itemState });
        const thirdPartyDependencyState = Container.ThirdPartyDependencyState.use({ onSave, template: itemState });
        const relatedDocuemntState = Container.RelatedDocumentState.use({ onSave, template: itemState });
        const questionnaireState = Container.QuestionnaireState.use({ onSave, template: itemState });
        const accessibilityQuestionnaireState = Container.AccessibilityQuestionnaireState.use({ onSave, template: itemState });
        const classificationState = Container.ClassificationState.use({
          onSave,
          template: itemState,
          onCancelPromoteECCN: () => classificationState.confirmECCNPromotionModalState.basicModalState.visible.setValue(false),
          onConfirmPromoteECCN: () => {
            onPromoteECCNRunner.submitRun(
              classificationState.initialItemState.value.Id
            ) 
          },
          loadingPromoteECCN: onPromoteECCNRunner.status === "Running"
        });
        const approvalState = Container.ApprovalState.use({ onSave, template: itemState });
        const baselineExemptionState = Container.BaselinExemptionState.use({ onSave, template: itemState });
        const adminDocumentState = Container.AdminDocumentState.use({ onSave, template: itemState });
        const CCATsSupplementState = Container.CCATsSupplementState.use({ onSave, template: itemState });

        const cloneModalState = Modal.CloneState.use({
          onCancel: () => cloneModalState.basicModalState.visible.setValue(false),
          onConfirm: async () => {
            cloneModalState.basicModalState.visible.setValue(false);
            cloneModalState.basicModalState.loading.setValue(true);
            onCloneRunner.submitRun({
              targetItemId: itemState.value.Id,
              clonedItemTitle: cloneModalState.title.value,
              clonedItemInternalCodeName: cloneModalState.codeName.value
            })
            cloneModalState.basicModalState.loading.setValue(false);
          },
          template: itemState
        })

        const performanceModalState = Modal.PerformanceState.use({
          template: itemState,
          onCancel: () => performanceModalState.basicModalState.visible.setValue(false),
          onConfirm: () => {
            performanceModalState.basicModalState.loading.setValue(true);
            onSave({
              AdjustedPeakPerformance: performanceModalState.adjustedPeakPerformance.value
            });
            performanceModalState.basicModalState.loading.setValue(false);
          }
        });
        const softwareDependenciesModalState = Modal.SoftwareDependenciesState.use({
          onCancel: () => {
            softwareDependenciesModalState.basicModalState.visible.setValue(false);
            softwareDependenciesModalState.init(itemState.value);
          },
          onConfirm: () => {
            softwareDependenciesModalState.basicModalState.visible.setValue(false);
            onSave({
              ThirdPartyDependencies: softwareDependenciesModalState.thirdPartyDependency.value,
              AmazonDependencies: softwareDependenciesModalState.amazonDependency.value,
            });
          },
          template: itemState
        });
        const assignActionModalState = Modal.AssignActionState.use({
          onCancel: () => {
            assignActionModalState.init(itemState.value);
            assignActionModalState.basicModalState.visible.setValue(false);
          },
          onConfirm: async () => {
            assignActionModalState.loading.setValue(true);
            await createAction({
              Action: assignActionModalState.action.value,
              Assignee: assignActionModalState.assginee.value,
              ItemId: itemState.value.Id,
              Message: assignActionModalState.message.value,
            });
            if (assignActionModalState.action.value === "Complete CCATs supplement" || assignActionModalState.action.value === "Update CCATs supplement") {
              await onSave({ AssignedCCATs: true });
            }
            assignActionModalState.basicModalState.visible.setValue(false);
            assignActionModalState.loading.setValue(false);
          },
          template: itemState
        });
        const deprecatedState = Modal.DeprecateState.use({
          onCancel: () => deprecatedState.basicModalState.visible.setValue(false),
          onConfirm: async () => {
            deprecatedState.basicModalState.visible.setValue(false);
            deprecatedState.basicModalState.loading.setValue(true);
            await onSave({ Deprecated: true });
            deprecatedState.basicModalState.loading.setValue(false);
          }
        });
        const deleteState = Modal.DeleteState.use({
          onCancel: () => deleteState.basicModalState.visible.setValue(false),
          onConfirm: async () => {
            deleteState.basicModalState.visible.setValue(false);
            deleteState.basicModalState.loading.setValue(true);
            onDeleteRunner.submitRun(
              itemState.value.Id
            )
            deleteState.basicModalState.loading.setValue(false);
          }
        });

        const onCloneRunner = GeneralHooks.useAnyRunner<
          {
            targetItemId: string;
            clonedItemTitle: string;
            clonedItemInternalCodeName: string;
          },
          {data: Item.Data},
          any
        >(cloneItem);

        const onBaselineRunner = GeneralHooks.useAnyRunner<
          string,
          {
            data: {
              BaselineDate: string,
              BaselinedBy: string
            }
          },
          any
        >(baselineItem);
        const onDeleteRunner = GeneralHooks.useAnyRunner(deleteItem);
        
        const init = async (id: string) => {
          try {
            loading.setValue(true);
            const res = await getItem(id) as { data: { Item: Item.Data, baselineMeta: BaselineMeta } };
            const itemData = res.data.Item;
            baselineMeta.setValue(res.data.baselineMeta);
            if (itemData.Pm && (!itemData.PmCostCenterId || !itemData.PmName)) {
              const pmSummary = await getEmployeeSummary(itemData.Pm);
              itemData.PmCostCenterId = pmSummary.data.costCenterId;
            }
            prevItemState.setValue({ ...prevItemState.value, ...itemData });
            itemState.setValue({
              ...itemState.value,
              ...itemData,
              LastBaselinedBy: res.data.baselineMeta.baselinedBy.Alias,
              LastBaselinedDate: DateTimeFormatter.toLocaleString(res.data.baselineMeta.baselinedDate)
            });
            loading.setValue(false);
          } catch (error) {
            loading.setValue(false);
            if (error.response) {
              errorMessages.setValue(error.response.data.errors);
            } else {
              errorMessages.setValue([{ type: "error", message: `${JSON.stringify(error)}`, field: "banner" }]);
            }
          }

          try {
            const res = await checkAdminStatus() as { data: boolean };
            isAdmin.setValue(res.data);
          } catch (error) {
            errorMessages.setValue([{ type: "error", message: `${JSON.stringify(error)}`, field: "banner" }]);
          }
        }

        const history = useHistory();

        useEffect(() => {
          if (onPromoteECCNRunner.status === "Running" || onPromoteECCNRunner.status === "New") {
            return;
          }
          if (onPromoteECCNRunner.status === "Error") {
            flashbarItems.setValue([
              ...flashbarItems.value,
              Builder<FlashbarProps.MessageDefinition>()
                .type("error")
                .header("Failed to promote ECCN")
                .content(
                  `The  Item: '${itemState.value.ItemFullName}' failed to promote ECCN. Please try again`
                )
                .dismissible(true)
                .build()
            ]);
          }

          if (onPromoteECCNRunner.status === "Finished") {
            let updatedItem = Builder<Item.Data>(itemState.value)
            .ECCN(onPromoteECCNRunner.data.data.ECCN)
            .TemporaryECCN(onPromoteECCNRunner.data.data.TemporaryECCN)
            .ECCNType(onPromoteECCNRunner.data.data.ECCNType)
            .ECCNStatus(onPromoteECCNRunner.data.data.ECCNStatus)
            .hasECCN(onPromoteECCNRunner.data.data.hasECCN)
            .ReviewStatus(onPromoteECCNRunner.data.data.ReviewStatus)
            .build()
            itemState.setValue(updatedItem)
          }
          classificationState.confirmECCNPromotionModalState.basicModalState.visible.setValue(false)
        }, [onPromoteECCNRunner.status])

        useEffect(() => {
          if (onCloneRunner.status === "Running" || onCloneRunner.status === "New") {
            return;
          }
          if (onCloneRunner.status === "Error") {
            flashbarItems.setValue([
              ...flashbarItems.value,
              Builder<FlashbarProps.MessageDefinition>()
                .type("error")
                .header("Failed to clone item")
                .content(
                  `The  Item: '${itemState.value.ItemFullName}' cannot be cloned. Please try again`
                )
                .dismissible(true)
                .build()
            ]);
            loading.setValue(false);
          }
          if (onCloneRunner.status === "Finished") {
            history.push(`/items/${onCloneRunner.data.data.Id}`);
            location.reload();
            loading.setValue(false);
          }
        }, [onCloneRunner.status]);

        useEffect(() => {
          if (onDeleteRunner.status === "Running" || onDeleteRunner.status === "New") {
            return;
          }
          if (onDeleteRunner.status === "Error") {
            flashbarItems.setValue([
              ...flashbarItems.value,
              Builder<FlashbarProps.MessageDefinition>()
                .type("error")
                .header("Failed to delete item")
                .content(
                  `The  Item: '${itemState.value.ItemFullName}' cannot be deleted. Please try again`
                )
                .dismissible(true)
                .build()
            ]);
            loading.setValue(false);
          }
          if (onDeleteRunner.status === "Finished") {
            history.push("/items/")
            loading.setValue(false);
          }
        }, [onDeleteRunner.status]);

        useEffect(() => {
          if (onBaselineRunner.status === "Running" || onBaselineRunner.status === "New") {
            return;
          }
          if (onBaselineRunner.status === "Error") {
            flashbarItems.setValue([
              ...flashbarItems.value,
              Builder<FlashbarProps.MessageDefinition>()
                .type("error")
                .header("Failed to baseline item")
                .content(
                  `The  Item: '${itemState.value.ItemFullName}' cannot be baselined. Please try again`
                )
                .dismissible(true)
                .build()
            ]);
            loading.setValue(false);
          }
          if (onBaselineRunner.status === "Finished") {
            const res = onBaselineRunner.data;
            flashbarItems.setValue([
              ...flashbarItems.value,
              Builder<FlashbarProps.MessageDefinition>()
                .type("success")
                .header("Baseline succeeded")
                .content("This Item has been successfully baselined. ")
                .dismissible(true)
                .build()
            ]);
            itemState.setValue({
              ...itemState.value,
              LastBaselinedBy: res.data.BaselinedBy,
              LastBaselinedDate: res.data.BaselineDate
            })
            loading.setValue(false);
          }
        }, [onBaselineRunner.status])

        useEffect(() => {
          if (props.id) {
            init(props.id);
          }
        }, []);

        useEffect(() => {
          if (itemState.value && itemState.value.Id) {
            summaryState.init(itemState.value);
            contactState.init(itemState.value);
            detailState.init(itemState.value);
            amazonDependencyState.init(itemState.value);
            thirdPartyDependencyState.init(itemState.value);
            relatedDocuemntState.init(itemState.value);
            questionnaireState.init(itemState.value);
            accessibilityQuestionnaireState.init(itemState.value);
            classificationState.init(itemState.value);
            approvalState.init(itemState.value);
            baselineExemptionState.init(itemState.value);
            adminDocumentState.init(itemState.value);
            softwareDependenciesModalState.init(itemState.value);
            CCATsSupplementState.init(itemState.value);
            assignActionModalState.init(itemState.value);
            performanceModalState.init(itemState.value);
            cloneModalState.init(itemState.value);
          }
        }, [itemState.value]);

        useEffect(() => {
          if (itemState.value.ItemFullName) {
            breadcrumb.setValue([{
              "text": "Home",
              "href": "#"
            },
            {
              "text": "All Items",
              "href": "#/items"
            },
            {
              text: itemState.value.ItemFullName,
              href: ""
            }]);
          }
        }, [itemState.value]);

        useEffect(() => {
          summaryState.errorMessage.setValue(errorMessages.value);
        }, [errorMessages.value]);

        useEffect(() => {
          if (!itemState.value.Form) return;
          const itemTypeQuestionArray = itemState.value.Form.filter(q => q.question === "2");
          if (
            itemTypeQuestionArray.length > 0 &&
            (prevItemState.value.ItemType !== itemTypeQuestionArray[0].answer as string)
          ) {
            summaryState.itemType.setValue(itemTypeQuestionArray[0].answer as string);
            onSave({ ItemType: itemTypeQuestionArray[0].answer as string });
          }
        }, [JSON.stringify(itemState.value.Form)]);

        useEffect(() => {
          if (!itemState.value.Form) return;
          const index = itemState.value.Form.findIndex((q) =>
            q.question === "32-1" && q.answer === "C. None of the above"
          );
          const prevIndex = prevItemState.value.Form.findIndex((q) =>
            q.question === "32-1" && q.answer === "C. None of the above"
          );
          if (index !== -1 && prevIndex === -1) {
            performanceModalState.basicModalState.visible.setValue(true);
          }
        }, [itemState.value.Form, prevItemState.value.Form]);

        useEffect(() => {
          if (!itemState.value.Form) return;
          const index = itemState.value.Form.findIndex(q => q.question === "2" && q.answer === "Software");
          const prevIndex = prevItemState.value.Form.findIndex(q => q.question === "2" && q.answer === "Software");
          if (index !== -1 && -1 === prevIndex) {
            softwareDependenciesModalState.basicModalState.visible.setValue(
              true
            );
          }
        }, [itemState.value.Form, prevItemState.value.Form]);

        useEffect(() => {
          const alias = store.getState().authenticationObj.userDetails.alias;
          isContributor.setValue(
            alias === itemState.value.Pm ||
            alias === itemState.value.BusinessLineAttorney ||
            -1 !== itemState.value.OtherContributors.findIndex(contributor => contributor.Alias === alias)
          );
        }, [
          itemState.value.Pm,
          itemState.value.BusinessLineAttorney,
          itemState.value.OtherContributors
        ]);

        useEffect(() => {
          if (!isAdmin.value && !isContributor.value) {
            summaryState.status.setValue(Container.Status.ReadOnly);
            contactState.status.setValue(Container.Status.ReadOnly);
            detailState.status.setValue(Container.Status.ReadOnly);
          } else {
            summaryState.status.setValue(Container.Status.Default);
            contactState.status.setValue(Container.Status.Default);
            detailState.status.setValue(Container.Status.Default);
          }
        }, [isAdmin.value, isContributor.value]);

        useEffect(() => {
          flashbarItems.setValue([
            ...flashbarItems.value,
            ...errorMessages.value ? errorMessages.value.filter((message) => message.field === "banner").map((message) => {
              return {
                type: message.type,
                content: message.message,
                dismissible: true
              }
            }) : []
          ])
        }, [errorMessages.value]);

        useEffect(() => {
          if (
            baselineMeta.value.activeBaselinePeriod
            && !baselineMeta.value.currentPeriodBaselined
            && !baselineMeta.value.autoBaselineExemption
            && itemState.value.ExemptFromBaseline !== 'Yes'
            && !baselineMeta.value.baselinedBy
          ) {
            flashbarItems.setValue([
              ...flashbarItems.value,
              {
                type: "warning",
                header: "Baseline required",
                content: "This Item requires baseline. To baseline, complete the questionnaire if necessary, and then click on the baseline button.",
                dismissible: true,
              }
            ])
          }
        }, [baselineMeta.value]);

        useEffect(() => {
          if (!!questionnaireState.questionnaire.value.find(
              (q) => q.status === Question_Status.ARCHIVED
            ) &&
            baselineMeta.value.activeBaselinePeriod
          ) {
            flashbarItems.setValue([
              ...flashbarItems.value,
              {
                type: "warning",
                header: "Action required",
                content: "Your Item's questionnaire has been updated.  In order to complete baseline, all modified questions need to be addressed.",
                dismissible: true,
              }
            ])
          }
        }, [questionnaireState.questionnaire.value]);

        useEffect(() => {
          if (
            itemState.value.Id &&
            (itemState.value.CET?.length < 1 && baselineMeta.value.activeBaselinePeriod)
          ) {
            flashbarItems.setValue([
              ...flashbarItems.value,
              {
                type: "warning",
                header: "Action required",
                content: "CET (Critical and Emerging Technologies) type is a required field.  In order to complete baseline, CET must be updated",
                dismissible: true,
              }
            ])
          }
          console.log("itemState.value.SaaSRestrictions!!!", itemState.value.SaaSRestrictions)
          if (
            itemState.value.Id && (
              !itemState.value.SaaSRestrictions?.isCNCSoftware ||
              !itemState.value.SaaSRestrictions?.isDesignAndManufacturingSoftware ||
              !itemState.value.SaaSRestrictions?.isEnterpriseManagementSoftware
            ) && baselineMeta.value.activeBaselinePeriod
          ) {
            flashbarItems.setValue([
              ...flashbarItems.value,
              {
                type: "warning",
                header: "Action required",
                content: "SaaS Restrictions is a required field.  In order to complete baseline, SaaS Restrictions must be updated",
                dismissible: true,
              }
            ])
          }
        }, [
          questionnaireState.questionnaire.value,
          itemState.value.CET,
          itemState.value.SaaSRestrictions
        ]);

        useEffect(() => {
          if (baselineMeta.value.autoBaselineExemption || itemState.value.ExemptFromBaseline === 'Yes') {
            flashbarItems.setValue([
              ...flashbarItems.value,
              {
                type: "info",
                header: "Baseline exempted",
                content: "This Item is exempt from this quarter’s baseline requirement for one or more reasons. Review information about baseline exemptions.",
                dismissible: true,
              }
            ])
          }
        }, [baselineMeta.value.autoBaselineExemption, itemState.value.ExemptFromBaseline]);


        return Builder(new State())
          .isAdmin(isAdmin)
          .isContributor(isContributor)
          .breadcrumb(breadcrumb)
          .itemState(itemState)
          .summaryState(summaryState)
          .contactState(contactState)
          .detailState(detailState)
          .amazonDependencyState(amazonDependencyState)
          .thirdPartyDependencyState(thirdPartyDependencyState)
          .relatedDocuemntState(relatedDocuemntState)
          .questionnaireState(questionnaireState)
          .classificationState(classificationState)
          .approvalState(approvalState)
          .baselineExemptionState(baselineExemptionState)
          .adminDocumentState(adminDocumentState)
          .softwareDependenciesModalState(softwareDependenciesModalState)
          .CCATsSupplementState(CCATsSupplementState)
          .assignActionModalState(assignActionModalState)
          .deprecatedState(deprecatedState)
          .deleteModalState(deleteState)
          .cloneModalState(cloneModalState)
          .performanceModalState(performanceModalState)
          .baselineMeta(baselineMeta)
          .onDeleteRunner(onDeleteRunner)
          .onBaselineRunner(onBaselineRunner)
          .flashbarItems(flashbarItems)
          .loading(loading)
          .accessibilityQuestionnaireState(accessibilityQuestionnaireState)
          .build();
      }
    }

    export namespace Container {
      export enum Status {
        ReadOnly, Edit, Default
      }

      export class BasicContainerState<T> {
        onSave: () => void;
        onCancel: () => void;
        onEdit: () => void;
        init: (template: T) => void
        loading: UIModel.FieldState<boolean>;
        status: UIModel.FieldState<Status>;
        errorMessage: UIModel.FieldState<
          Message[]
        >;
        initialItemState: UIModel.FieldState<T>;
        static getErrorMessage = <T extends any>(field: keyof T, state: BasicContainerState<T>) => {
          if (!state.errorMessage || !state.errorMessage.value) return;
          const message = state.errorMessage.value.filter(e => e.field === field)[0];
          return message ? message.message : "";
        }
      }

      export class SummaryState extends BasicContainerState<Item.Data> {
        id: UIModel.FieldState<string>;
        itemFullName: UIModel.FieldState<string>;
        internalCodeName: UIModel.FieldState<string>;
        customerDefinedVersion: UIModel.FieldState<string>;
        highlyConfidential: UIModel.FieldState<"Yes" | "No" | "">;
        itemType: UIModel.FieldState<string>;
        ECCN: UIModel.FieldState<string>;
        lastBaselinedBy: UIModel.FieldState<string>;
        lastBaselinedDate: UIModel.FieldState<string>;
        itemClassificationDetails: UIModel.FieldState<string>;
        itemRefs: UIModel.FieldState<string[]>;

        static use(props: { onSave: (p: Partial<Item.Data>) => Promise<void>, template?: UIModel.FieldState<Item.Data> }) {
          const errorMessage = UIModel.FieldState.use<
            Message[]
          >({ initialValue: [] });
          const id = UIModel.FieldState.use({ initialValue: "", });
          const itemFullName = UIModel.FieldState.use({
            initialValue: ""
          });
          const internalCodeName = UIModel.FieldState.use({
            initialValue: ""
          });
          const customerDefinedVersion = UIModel.FieldState.use({ initialValue: "" });
          const highlyConfidential = UIModel.FieldState.use<"Yes" | "No" | "">({
            initialValue: ""
          });
          const itemType = UIModel.FieldState.use({
            initialValue: ""
          });
          const ECCN = UIModel.FieldState.use({
            initialValue: ""
          });
          const lastBaselinedBy = UIModel.FieldState.use({
            initialValue: ""
          });
          const lastBaselinedDate = UIModel.FieldState.use({
            initialValue: ""
          });
          const itemClassificationDetails = UIModel.FieldState.use({
            initialValue: ""
          });
          const itemRefs = UIModel.FieldState.use({
            initialValue: []
          });

          const loading = UIModel.FieldState.use({ initialValue: false });
          const status = UIModel.FieldState.use({ initialValue: Status.Default });
          const onSaveRunner = GeneralHooks.useAnyRunner(props.onSave);

          const onSave = async () => {
            loading.setValue(true);
            onSaveRunner.submitRun(
              {
                ItemFullName: itemFullName.value,
                CodeName: internalCodeName.value,
                Confidential: highlyConfidential.value,
                CustomerDefinedVersion: customerDefinedVersion.value,
                ItemRefs: itemRefs.value
              }
            )
          }

          useEffect(() => {
            if (onSaveRunner.status === "Running" || onSaveRunner.status === "New") {
              return;
            }
            loading.setValue(false);
            if (errorMessage.value.length > 0) {
              return;
            }
            status.setValue(Status.Default);
          }, [onSaveRunner.status])

          useEffect(() => {
            if (props.template) {
              init(props.template.value);
            }
          }, []);

          const init = (template: Item.Data) => {
            id.setValue(template.Id);
            itemFullName.setValue(template.ItemFullName);
            internalCodeName.setValue(template.CodeName);
            customerDefinedVersion.setValue(template.CustomerDefinedVersion);
            highlyConfidential.setValue(template.Confidential);
            itemType.setValue(template.ItemType);
            ECCN.setValue(template.ECCN);
            lastBaselinedBy.setValue(template.LastBaselinedBy);
            lastBaselinedDate.setValue(template.LastBaselinedDate);
            itemClassificationDetails.setValue(template.ItemClassificationDetails);
            itemRefs.setValue(template.ItemRefs ? template.ItemRefs : []);
          }

          const onCancel = () => {
            init(props.template.value);
            errorMessage.setValue([]);
            status.setValue(Status.Default);
          }

          return Builder<SummaryState>(new SummaryState())
            .id(id)
            .itemFullName(itemFullName)
            .internalCodeName(internalCodeName)
            .customerDefinedVersion(customerDefinedVersion)
            .highlyConfidential(highlyConfidential)
            .itemType(itemType)
            .ECCN(ECCN)
            .lastBaselinedBy(lastBaselinedBy)
            .lastBaselinedDate(lastBaselinedDate)
            .itemClassificationDetails(itemClassificationDetails)
            .itemRefs(itemRefs)
            .loading(loading)
            .status(status)
            .onSave(onSave)
            .onCancel(onCancel)
            .onEdit(() => status.setValue(Status.Edit))
            .init(init)
            .errorMessage(errorMessage)
            .initialItemState(props.template)
            .build()
        }
      }

      export class ContactState extends BasicContainerState<Item.Data> {
        productManager: UIModel.FieldState<{ alias: string, costCenterId: string, name: string }>;
        businessLineLawyer: UIModel.FieldState<{ alias: string, name: string }>;
        otherContributors: UIModel.FieldState<Item.Contribtor[]>;
        ownerTeam: UIModel.FieldState<string>;

        static use(props: { onSave: (p: Partial<Item.Data>) => Promise<void>, template?: UIModel.FieldState<Item.Data> }) {
          const errorMessage = UIModel.FieldState.use<
            { type: string, message: string, field: keyof Item.Data }[]
          >({ initialValue: [] });
          const productManager = UIModel.FieldState.use<{ alias: string, costCenterId: string, name: string }>({
            initialValue: { alias: "", costCenterId: "", name: "" },
          });

          const businessLineLawyer = UIModel.FieldState.use<{ alias: string, name: string }>({
            initialValue: { alias: "", name: "" }
          });
          const otherContributors: UIModel.FieldState<Item.Contribtor[]> = UIModel.FieldState.use({
            initialValue: []
          });
          const ownerTeam: UIModel.FieldState<string> = UIModel.FieldState.use({initialValue: ""})


          const loading = UIModel.FieldState.use({ initialValue: false });
          const status = UIModel.FieldState.use({ initialValue: Status.Default });
          const onSaveRunner = GeneralHooks.useAnyRunner(props.onSave);


          useEffect(() => {
            if (props.template) {
              init(props.template.value);
            }
          }, []);

          const init = (template: Item.Data) => {
            productManager.setValue({
              alias: template.Pm,
              name: template.PmName,
              costCenterId: template.PmCostCenterId,
            });
            businessLineLawyer.setValue(
              {
                alias: template.BusinessLineAttorney, name: template.BusinessLineAttorneyName
              });
            otherContributors.setValue(template.OtherContributors);
            ownerTeam.setValue(template.OwnerTeam)
          }

          const onCancel = () => {
            init(props.template.value);
            errorMessage.setValue([]);
            status.setValue(Status.Default);
          }

          const onSave = async () => {
            loading.setValue(true);
            onSaveRunner.submitRun(
              {
                Pm: productManager.value.alias,
                PmCostCenterId: productManager.value.costCenterId,
                PmName: productManager.value.name,
                BusinessLineAttorney: businessLineLawyer.value.alias,
                BusinessLineAttorneyName: businessLineLawyer.value.name,
                OtherContributors: otherContributors.value,
                OwnerTeam: ownerTeam.value
              }
            )
          }

          useEffect(() => {
            if (onSaveRunner.status === "Running" || onSaveRunner.status === "New") {
              return;
            }
            loading.setValue(false);
            if (errorMessage.value.length > 0) {
              return;
            }
            status.setValue(Status.Default);
          }, [onSaveRunner.status])

          return Builder<ContactState>(new ContactState())
            .productManager(productManager)
            .businessLineLawyer(businessLineLawyer)
            .otherContributors(otherContributors)
            .ownerTeam(ownerTeam)
            .loading(loading)
            .status(status)
            .onSave(onSave)
            .onCancel(onCancel)
            .onEdit(() => status.setValue(Status.Edit))
            .init(init)
            .initialItemState(props.template)
            .build()
        }
      }

      export class DetailState extends BasicContainerState<Item.Data> {
        description: UIModel.FieldState<string>;
        documentation: UIModel.FieldState<string>;
        militaryUse: UIModel.FieldState<"Yes" | "No" | "">;
        researchProject: UIModel.FieldState<"Yes" | "No" | "">;
        cet: UIModel.FieldState<string[]>;
        isEnterpriseManagementSoftware: UIModel.FieldState<"Yes" | "No" | "">;
        isDesignAndManufacturingSoftware: UIModel.FieldState<"Yes" | "No" | "">;
        isCNCSoftware: UIModel.FieldState<"Yes" | "No" | "">;

        static use(props: { onSave: (p: Partial<Item.Data>) => Promise<void>, template?: UIModel.FieldState<Item.Data> }) {
          const errorMessage = UIModel.FieldState.use<
            Message[]
          >({ initialValue: [] });
          const description = UIModel.FieldState.use({ initialValue: "", });
          const documentation = UIModel.FieldState.use({
            initialValue: ""
          });
          const militaryUse = UIModel.FieldState.use<"Yes" | "No" | "">({
            initialValue: ""
          });
          const researchProject = UIModel.FieldState.use<"Yes" | "No" | "">({
            initialValue: ""
          });
          const cet = UIModel.FieldState.use<string[]>({
            initialValue: []
          });
          const isEnterpriseManagementSoftware = UIModel.FieldState.use<"Yes" | "No" | "">({
            initialValue: ""
          });
          const isDesignAndManufacturingSoftware = UIModel.FieldState.use<"Yes" | "No" | "">({
            initialValue: ""
          });
          const isCNCSoftware = UIModel.FieldState.use<"Yes" | "No" | "">({
            initialValue: ""
          });

          const loading = UIModel.FieldState.use({ initialValue: false });
          const status = UIModel.FieldState.use({ initialValue: Status.Default });
          const onSaveRunner = GeneralHooks.useAnyRunner(props.onSave);

          const onSave = async () => {
            loading.setValue(true);
            onSaveRunner.submitRun(
              {
                Description: description.value,
                OnlineDocumentation: documentation.value,
                MilitaryUse: militaryUse.value,
                ResearchProject: researchProject.value,
                CET: cet.value,
                SaaSRestrictions: {
                  isEnterpriseManagementSoftware: isEnterpriseManagementSoftware.value,
                  isDesignAndManufacturingSoftware: isDesignAndManufacturingSoftware.value,
                  isCNCSoftware: isCNCSoftware.value,
                }
              }
            )
          }

          useEffect(() => {
            if (onSaveRunner.status === "Running" || onSaveRunner.status === "New") {
              return;
            }
            loading.setValue(false);
            if (errorMessage.value.length > 0) {
              return;
            }
            status.setValue(Status.Default);
          }, [onSaveRunner.status])

          useEffect(() => {
            if (props.template) {
              init(props.template.value);
            }
          }, []);

          const init = (template: Item.Data) => {
            description.setValue(template.Description);
            documentation.setValue(template.OnlineDocumentation);
            militaryUse.setValue(template.MilitaryUse);
            researchProject.setValue(template.ResearchProject);
            cet.setValue(template.CET ? template.CET : []);
            isEnterpriseManagementSoftware.setValue(template.SaaSRestrictions?.isEnterpriseManagementSoftware);
            isDesignAndManufacturingSoftware.setValue(template.SaaSRestrictions?.isDesignAndManufacturingSoftware);
            isCNCSoftware.setValue(template.SaaSRestrictions?.isCNCSoftware);
          }

          const onCancel = () => {
            init(props.template.value);
            errorMessage.setValue([]);
            status.setValue(Status.Default);
          }

          return Builder<DetailState>(new DetailState())
            .description(description)
            .documentation(documentation)
            .militaryUse(militaryUse)
            .researchProject(researchProject)
            .cet(cet)
            .isEnterpriseManagementSoftware(isEnterpriseManagementSoftware)
            .isDesignAndManufacturingSoftware(isDesignAndManufacturingSoftware)
            .isCNCSoftware(isCNCSoftware)
            .loading(loading)
            .status(status)
            .onSave(onSave)
            .onCancel(onCancel)
            .onEdit(() => status.setValue(Status.Edit))
            .init(init)
            .errorMessage(errorMessage)
            .initialItemState(props.template)
            .build()
        }
      }

      export class AmazonDependencyState extends BasicContainerState<Item.Data>{
        amazonDependency: UIModel.FieldState<Item.AmazonDependency[]>;

        static use(props: { onSave: (p: Partial<Item.Data>) => Promise<void>, template?: UIModel.FieldState<Item.Data> }) {
          const errorMessage = UIModel.FieldState.use<
            Message[]
          >({ initialValue: [] });
          const amazonDependency = UIModel.FieldState.use({ initialValue: [], });
          const loading = UIModel.FieldState.use({ initialValue: false });
          const status = UIModel.FieldState.use({ initialValue: Status.Default });
          const onSaveRunner = GeneralHooks.useAnyRunner(props.onSave);

          const onSave = async () => {
            loading.setValue(true);
            onSaveRunner.submitRun(
              {
                AmazonDependencies: amazonDependency.value,
              }
            )
          }

          useEffect(() => {
            if (onSaveRunner.status === "Running" || onSaveRunner.status === "New") {
              return;
            }
            loading.setValue(false);
            if (errorMessage.value.length > 0) {
              return;
            }
            status.setValue(Status.Default);
          }, [onSaveRunner.status])

          useEffect(() => {
            if (props.template) {
              init(props.template.value);
            }
          }, []);

          const init = (template: Item.Data) => {
            const deepCopy = JSON.parse(
              JSON.stringify(template.AmazonDependencies)
            ); // Avoid accidentally changing external data 
            amazonDependency.setValue(deepCopy);
          };

          const onCancel = () => {
            init(props.template.value);
            errorMessage.setValue([]);
            status.setValue(Status.Default);
          }

          return Builder<AmazonDependencyState>(new AmazonDependencyState())
            .amazonDependency(amazonDependency)
            .loading(loading)
            .status(status)
            .onSave(onSave)
            .onCancel(onCancel)
            .onEdit(() => status.setValue(Status.Edit))
            .init(init)
            .errorMessage(errorMessage)
            .initialItemState(props.template)
            .build()
        }
      }

      export class ThirdPartyDependencyState extends BasicContainerState<Item.Data>{
        thirdPartyDependency: UIModel.FieldState<Item.ThirdPartyDependency[]>;

        static use(props: { onSave: (p: Partial<Item.Data>) => Promise<void>, template?: UIModel.FieldState<Item.Data> }) {
          const errorMessage = UIModel.FieldState.use<
            Message[]
          >({ initialValue: [] });
          const thirdPartyDependency = UIModel.FieldState.use({ initialValue: [], });
          const loading = UIModel.FieldState.use({ initialValue: false });
          const status = UIModel.FieldState.use({ initialValue: Status.Default });
          const onSaveRunner = GeneralHooks.useAnyRunner(props.onSave);
          const onSave = async () => {
            loading.setValue(true);
            onSaveRunner.submitRun(
              {
                ThirdPartyDependencies: thirdPartyDependency.value,
              }
            )
          }

          useEffect(() => {
            if (onSaveRunner.status === "Running" || onSaveRunner.status === "New") {
              return;
            }
            loading.setValue(false);
            if (errorMessage.value.length > 0) {
              return;
            }
            status.setValue(Status.Default);
          }, [onSaveRunner.status])

          useEffect(() => {
            if (props.template) {
              init(props.template.value);
            }
          }, []);

          const init = (template: Item.Data) => {
            const deepCopy = JSON.parse(
              JSON.stringify(template.ThirdPartyDependencies)
            ); // Avoid accidentally changing external data 
            thirdPartyDependency.setValue(deepCopy);
          }

          const onCancel = () => {
            init(props.template.value);
            errorMessage.setValue([]);
            status.setValue(Status.Default);
          }

          return Builder<ThirdPartyDependencyState>(new ThirdPartyDependencyState())
            .thirdPartyDependency(thirdPartyDependency)
            .loading(loading)
            .status(status)
            .onSave(onSave)
            .onCancel(onCancel)
            .onEdit(() => status.setValue(Status.Edit))
            .init(init)
            .errorMessage(errorMessage)
            .initialItemState(props.template)
            .build()
        }
      }

      export class RelatedDocumentState extends BasicContainerState<Item.Data>{
        files: UIModel.FieldState<File[]>;
        uploadAsset: (files: Array<File>) => void;
        readAsset: (fileName: string) => void;
        deleteAsset: (fileName: string) => void;
        static use(props: { onSave: (p: Partial<Item.Data>) => Promise<void>, template?: UIModel.FieldState<Item.Data> }) {
          const errorMessage = UIModel.FieldState.use<
            Message[]
          >({ initialValue: [] });
          const files = UIModel.FieldState.use<File[]>({ initialValue: [], });
          const loading = UIModel.FieldState.use({ initialValue: false });
          const status = UIModel.FieldState.use({ initialValue: Status.Default });
          const onSaveRunner = GeneralHooks.useAnyRunner(props.onSave);

          const appState = store.getState();
          let alias = appState.authenticationObj.userDetails ? appState.authenticationObj.userDetails.alias : '';

          const removeFile = (newFiles, fileName: string) => {
            const index = newFiles.findIndex((file) => file.filename === fileName)
            if (index !== -1) {
              newFiles.splice(index, 1)
            }
          }

          const readAsset = (filename: string) => {
            readFile(props.template.value.Id, filename, RELATED_DOC).then((result) => {
              window.open(result.data, '_blank');
            });
          }

          const deleteAsset = (fileName: string) => {
            loading.setValue(true)
            deleteFileV4(props.template.value.Id, fileName, RELATED_DOC)
              .then(() => {
                let newFiles = JSON.parse(JSON.stringify(props.template.value.Files)) || []
                removeFile(newFiles, fileName)
                onSaveRunner.submitRun({ Files: newFiles });
                files.setValue(newFiles);
              }).catch((e) => {
                let newErrorMessage = []
                newErrorMessage.push({ type: 'error', message: 'File deletion failed', field: 'banner' })
                errorMessage.setValue(newErrorMessage);
              });
          }

          const uploadAsset = (filesToUpload: Array<File>) => {
            loading.setValue(true)
            uploadItemFile(props.template.value.Id, filesToUpload, RELATED_DOC)
              .then((result) => {
                const signedURL = result.data;
                if (!signedURL) {
                  loading.setValue(false)
                  let newErrorMessage = []
                  newErrorMessage.push({ type: 'error', message: `There was an error uploading your file`, field: 'banner' })
                  errorMessage.setValue(newErrorMessage)
                  loading.setValue(false);
                } else {
                  axios(signedURL, {
                    method: 'PUT',
                    data: filesToUpload[0],
                  }).then(res => {
                    if (res.status === 200) {
                      let newFiles = JSON.parse(JSON.stringify(props.template.value.Files)) || []
                      removeFile(newFiles, filesToUpload[0].name)
                      newFiles.push({ filename: filesToUpload[0].name, key: result.data.key, modifiedAt: new Date().toUTCString(), modifiedBy: alias })
                      onSaveRunner.submitRun({ Files: newFiles });
                      files.setValue(newFiles);
                    }
                    loading.setValue(false)
                  }).catch((err) => {
                    let newErrorMessage = []
                    newErrorMessage.push({ type: 'error', message: `File upload failed due to unaccepted file type`, field: 'banner' })
                    errorMessage.setValue(newErrorMessage)
                    loading.setValue(false);
                  });
                }
              }).catch((e) => {
                let newErrorMessage = []
                newErrorMessage.push({ type: 'error', message: `File upload failed'`, field: 'banner' })
                errorMessage.setValue(newErrorMessage)
                loading.setValue(false)
              });
          }


          useEffect(() => {
            if (onSaveRunner.status === "Running" || onSaveRunner.status === "New") {
              return;
            }
            loading.setValue(false);
            if (errorMessage.value.length > 0) {
              return;
            }
            status.setValue(Status.Default);
          }, [onSaveRunner.status])

          useEffect(() => {
            if (props.template) {
              init(props.template.value);
            }
          }, []);

          const init = (template: Item.Data) => {
            files.setValue(template.Files);
          }

          const onCancel = () => {
            init(props.template.value);
            errorMessage.setValue([]);
            status.setValue(Status.Default);
          }

          return Builder<RelatedDocumentState>(new RelatedDocumentState())
            .files(files)
            .uploadAsset(uploadAsset)
            .readAsset(readAsset)
            .deleteAsset(deleteAsset)
            .loading(loading)
            .status(status)
            .onCancel(onCancel)
            .onEdit(() => status.setValue(Status.Edit))
            .init(init)
            .errorMessage(errorMessage)
            .initialItemState(props.template)
            .build()
        }
      }

      export class QuestionTextLabelled {
        DisplayText: (string | JSX.Element)[];
        Options: {
          Criteria: {
            Value: (string | JSX.Element)[]
          }[]
        }
        labelledKeys: string[]

        static createByQuestionData(data: QuestionModel.Data, action: (term: string) => void): QuestionTextLabelled {
          const appState = store.getState()
          const displayTextResult = QuestionModel.highlightKeywords(data.DisplayText, appState.keywordObj.keywords, action);
          const labelledKeys: string[] = [...displayTextResult.keys];
          
          const Criteria: { Value: (string | JSX.Element)[] }[] = [];
          for (const option of data.Options) {
            const criteriaValueResult = QuestionModel.highlightKeywords(option.Criteria.Value, appState.keywordObj.keywords, action);

            Criteria.push({
              Value: criteriaValueResult.result
            })
            labelledKeys.push(...criteriaValueResult.keys);
          }
          return Builder<QuestionTextLabelled>(new QuestionTextLabelled)
            .Options({ Criteria })
            .labelledKeys(labelledKeys)
            .DisplayText(displayTextResult.result)
            .build();
        }
      }

      export class QuestionnaireState extends BasicContainerState<Item.Data>{
        questionnaire: UIModel.FieldState<{
          question: QuestionModel.Data,
          answer: string | string[],
          status: Question_Status
        }[]>;
        onAnswerQuestion: (input: { index: number, answer: string | string[] }) => void;
        onEditQuestion: (index: number) => void;
        onRefreshQuestion: (index: number) => void;
        onCancelOperation: (index: number) => void;
        editModalState: Modal.EditQuestionModalState;
        refreshModalState: Modal.RefreshQuestionModalState;

        static getForm(
          questionnaire: {
            question: QuestionModel.Data,
            answer: string | string[],
            status: Question_Status
          }[]
        ): Item.Data["Form"] {
          if (!questionnaire) return [];
          return questionnaire.map(q => {
            return {
              question: q.question.Id,
              answer: q.answer,
              version: `${q.question.Version}`
            }
          });
        }

        static use(props: { onSave: (p: Partial<Item.Data>) => Promise<void>, template?: UIModel.FieldState<Item.Data> }) {
          const onSaveRunner = GeneralHooks.useAnyRunner(props.onSave);
          const questionnaire = UIModel.FieldState.use<{
            question: QuestionModel.Data,
            answer: string | string[],
            status: Question_Status
          }[]>({ initialValue: [] });
          const loading = UIModel.FieldState.use({ initialValue: false });
          const editModalState = Modal.EditQuestionModalState.use({
            onCancel: () => {
              editModalState.basicModalState.visible.setValue(false);
              editModalState.indexOfTargetQuestion.setValue(-1);
            },
            onConfirm: () => {
              if (editModalState.indexOfTargetQuestion.value === -1) {
                return;
              }
              questionnaire.setValue(questionnaire.value.map((v, i, a) => {
                if (i === editModalState.indexOfTargetQuestion.value) {
                  return { ...v, status: Question_Status.EDIT_FOCUS }
                }
                return { ...v, status: Question_Status.READ_ONLY };
              }));
              editModalState.indexOfTargetQuestion.setValue(-1);
              editModalState.basicModalState.visible.setValue(false);
            }
          });

          const refreshModalState = Modal.RefreshQuestionModalState.use({
            item: props.template.value,
            onCancel: () => {
              refreshModalState.basicModalState.visible.setValue(false);
              refreshModalState.indexOfTargetQuestion.setValue(-1);
            },
            onConfirm: async () => {
              if (refreshModalState.indexOfTargetQuestion.value === -1) return;
              loading.setValue(true);
              const questionData = await getQuestionById(questionnaire.value[
                refreshModalState.indexOfTargetQuestion.value
              ].question.Id) as { data: QuestionModel.Data };
              const question = questionData.data
              questionnaire.setValue(
                [
                  ...questionnaire.value.slice(0, refreshModalState.indexOfTargetQuestion.value),
                  {
                    question: question,
                    answer: question.Type === "MULTI_SELECTION" ?
                      [] : "",
                    status: Question_Status.EXPANDED
                  }
                ]);
              refreshModalState.indexOfTargetQuestion.setValue(-1);
              refreshModalState.basicModalState.visible.setValue(false);
            }
          });
          const status = UIModel.FieldState.use({ initialValue: Status.Default });

          const init = async (template: Item.Data) => {
            if (!template || !template.Form) return;
            loading.setValue(true);
            const currentQuestions = await Promise.all(
              template.Form.map(q => getQuestionByIdVersion(q.question, q.version) as { data: QuestionModel.Data })
            ).then(result => result.map(r => r.data));
            const latestQuestions = await Promise.all(
              template.Form.map(q => getQuestionById(q.question) as { data: QuestionModel.Data })
            ).then(result => result.map(r => r.data));
            let hasOutDatedQuestion = false;
            questionnaire.setValue(currentQuestions.map((q, i, array) => {
              if (hasOutDatedQuestion) {
                return {
                  question: q,
                  answer: template.Form[i].answer,
                  status: Question_Status.READ_ONLY
                }
              }
              if (latestQuestions[i].Version !== q.Version) {
                if (q.Id === "1") {
                  return {
                    question: q,
                    answer: template.Form[i].answer,
                    status: template.Form[i].answer ?
                      Question_Status.CLOSED :
                      Question_Status.EXPANDED
                  };
                }
                hasOutDatedQuestion = true;
                return {
                  question: q,
                  answer: template.Form[i].answer,
                  status: Question_Status.ARCHIVED
                }
              }
              return {
                question: q,
                answer: template.Form[i].answer,
                status: template.Form[i].answer ?
                  Question_Status.CLOSED :
                  Question_Status.EXPANDED
              };
            }));
            loading.setValue(false);
          }

          const onAnswerQuestion = async (input: { index: number, answer: string | string[] }) => {
            const answerOption = QuestionModel.Data.getReferenceForAnswer(
              questionnaire.value[input.index].question,
              input.answer
            );
            console.debug(`answerOption=${JSON.stringify(answerOption, null, 2)}`)
            if (answerOption.RefType === "ECCN") {
              const newQuestionnaire = [...questionnaire.value.slice(0, input.index + 1).map((q, i) => {
                if (i === input.index) {
                  return { ...q, status: Question_Status.CLOSED, answer: input.answer };
                }
                return { ...q, status: Question_Status.CLOSED };
              })]
              questionnaire.setValue(newQuestionnaire);
              if (props.template.value.ECCNLocked) {
                onSaveRunner.submitRun({
                  Form: QuestionnaireState.getForm(newQuestionnaire),
                  TemporaryECCN: answerOption.RefIdentifier,
                  ECCNStatus: ECCN_STATUS.PENDING_EXPORT_COMPLIANCE_REVIEW
                })
              } else {
                onSaveRunner.submitRun({
                  Form: QuestionnaireState.getForm(newQuestionnaire),
                  ECCN: answerOption.RefIdentifier,
                  ECCNCount: props.template.value.ECCNCount + 1,
                  ECCNType: ECCN_Type.AUTO,
                  ECCNStatus: ECCN_STATUS.ACTIVE,
                  TemporaryECCN: ""
                })
              }
            }

            if (answerOption.RefType === "QUESTION") {
              const nextQuestion = await getQuestionById(answerOption.RefIdentifier) as { data: QuestionModel.Data };
              console.debug(`nextQuestion=${JSON.stringify(nextQuestion.data, null, 2)}`)
              loading.setValue(true);
              const newQuestionnaire = [
                ...questionnaire.value.slice(0, input.index + 1).map((q, i) => {
                  if (i < input.index) {
                    return { ...q, status: Question_Status.CLOSED };
                  }
                  if (i === input.index) {
                    return { ...q, status: Question_Status.CLOSED, answer: input.answer };
                  }
                }),
                {
                  question: nextQuestion.data,
                  answer: undefined,
                  status: Question_Status.EXPANDED
                }
              ]
              questionnaire.setValue(newQuestionnaire);
              if (props.template.value.ECCNLocked) {
                onSaveRunner.submitRun({
                  Form: QuestionnaireState.getForm(newQuestionnaire),
                  TemporaryECCN: "",
                  ECCNStatus: ECCN_STATUS.PENDING_QUESTIONNAIRE_COMPLETION
                })
              } else {
                onSaveRunner.submitRun({
                  Form: QuestionnaireState.getForm(newQuestionnaire),
                  ECCN: "",
                  ECCNType: ECCN_Type.NO_ECCN,
                  ECCNStatus: ECCN_STATUS.PENDING_QUESTIONNAIRE_COMPLETION,
                  TemporaryECCN: ""
                });
              }
              loading.setValue(false);
            }
            if (answerOption.RefType === "MANUAL") {
              const newQuestionnaire = [
                ...questionnaire.value.slice(0, input.index + 1)
                  .map((q, i) => {
                    if (i < input.index) {
                      return { ...q, status: Question_Status.CLOSED };
                    }
                    return { ...q, status: Question_Status.CLOSED, answer: input.answer };
                  })
              ]
              questionnaire.setValue(newQuestionnaire);
              if (props.template.value.ECCNLocked) {
                onSaveRunner.submitRun({
                  Form: QuestionnaireState.getForm(newQuestionnaire),
                  TemporaryECCN: "",
                  ECCNStatus: ECCN_STATUS.PENDING_EXPORT_COMPLIANCE_REVIEW
                })
              } else {
                onSaveRunner.submitRun({
                  Form: QuestionnaireState.getForm(newQuestionnaire),
                  ECCN: "",
                  ECCNType: ECCN_Type.NO_ECCN,
                  ECCNStatus: ECCN_STATUS.PENDING_EXPORT_COMPLIANCE_REVIEW,
                  TemporaryECCN: ""
                })
              }
            }
          }

          useEffect(() => {
            if (status.value === Status.ReadOnly) {
              questionnaire.setValue([
                ...questionnaire.value.map(q => { return { ...q, status: Question_Status.READ_ONLY } })
              ])
            }
          }, [status.value, loading.value])

          const dispatch = useDispatch()
          const appState = store.getState();
          
          useEffect(() => {
            if (appState.keywordObj.status === "idle") {
              dispatch(fetchKeywords())
            }
          }, [appState.keywordObj.status])

          const onEditQuestion = (index: number) => {
            editModalState.indexOfTargetQuestion.setValue(index);
            editModalState.basicModalState.visible.setValue(true);
          }

          const onRefreshQuestion = (index: number) => {
            refreshModalState.indexOfTargetQuestion.setValue(index);
            refreshModalState.basicModalState.visible.setValue(true);
          }

          const onCancelOperation = (index: number) => {
            editModalState.indexOfTargetQuestion.setValue(-1);
            refreshModalState.indexOfTargetQuestion.setValue(-1);
            questionnaire.setValue(questionnaire.value.map((q, index, array) => {
              if (index === array.length - 1) {
                return { ...q, status: q.answer ? Question_Status.CLOSED : Question_Status.EXPANDED }
              }
              return { ...q, status: Question_Status.CLOSED }
            }))
          }

          return Builder(new QuestionnaireState())
            .init(init)
            .onAnswerQuestion(onAnswerQuestion)
            .onEditQuestion(onEditQuestion)
            .onRefreshQuestion(onRefreshQuestion)
            .onCancelOperation(onCancelOperation)
            .questionnaire(questionnaire)
            .editModalState(editModalState)
            .refreshModalState(refreshModalState)
            .status(status)
            .loading(loading)
            .build();
        }
      }

      export class AccessibilityQuestionnaireState extends BasicContainerState<Item.Data>{
        questionnaire: UIModel.FieldState<{
          question: QuestionModel.Data,
          answer: string | string[],
          status: Question_Status
        }[]>;
        onAnswerQuestion: (input: { index: number, answer: string | string[] }) => void;
        onEditQuestion: (index: number) => void;
        onRefreshQuestion: (index: number) => void;
        onCancelOperation: (index: number) => void;
        editModalState: Modal.EditQuestionModalState;
        refreshModalState: Modal.RefreshQuestionModalState;

        static getAccessibilityQuestionnaireAnswers(
          questionnaire: {
            question: QuestionModel.Data,
            answer: string | string[],
            status: Question_Status
          }[]
        ): Item.Data["AccessibilityQuestionnaireAnswers"] {
          if (!questionnaire) return [];
          return questionnaire.map(q => {
            return {
              question: q.question.Id,
              answer: q.answer,
              version: `${q.question.Version}`
            }
          });
        }

        static use(props: { onSave: (p: Partial<Item.Data>) => Promise<void>, template?: UIModel.FieldState<Item.Data> }) {
          const onSaveRunner = GeneralHooks.useAnyRunner(props.onSave);
          const questionnaire = UIModel.FieldState.use<{
            question: QuestionModel.Data,
            answer: string | string[],
            status: Question_Status
          }[]>({ initialValue: [] });
          const loading = UIModel.FieldState.use({ initialValue: false });
          const editModalState = Modal.EditQuestionModalState.use({
            onCancel: () => {
              editModalState.basicModalState.visible.setValue(false);
              editModalState.indexOfTargetQuestion.setValue(-1);
            },
            onConfirm: () => {
              if (editModalState.indexOfTargetQuestion.value === -1) {
                return;
              }
              questionnaire.setValue(questionnaire.value.map((v, i, a) => {
                if (i === editModalState.indexOfTargetQuestion.value) {
                  return { ...v, status: Question_Status.EDIT_FOCUS }
                }
                return { ...v, status: Question_Status.READ_ONLY };
              }));
              editModalState.indexOfTargetQuestion.setValue(-1);
              editModalState.basicModalState.visible.setValue(false);
            }
          });

          const refreshModalState = Modal.RefreshQuestionModalState.use({
            onCancel: () => {
              refreshModalState.basicModalState.visible.setValue(false);
              refreshModalState.indexOfTargetQuestion.setValue(-1);
            },
            onConfirm: async () => {
              if (refreshModalState.indexOfTargetQuestion.value === -1) return;
              loading.setValue(true);
              const questionData = await getQuestionById(questionnaire.value[
                refreshModalState.indexOfTargetQuestion.value
              ].question.Id) as { data: QuestionModel.Data };
              const question = questionData.data
              questionnaire.setValue(
                [
                  ...questionnaire.value.slice(0, refreshModalState.indexOfTargetQuestion.value),
                  {
                    question: question,
                    answer: question.Type === "MULTI_SELECTION" ?
                      [] : "",
                    status: Question_Status.EXPANDED,
                  }
                ]);
              refreshModalState.indexOfTargetQuestion.setValue(-1);
              refreshModalState.basicModalState.visible.setValue(false);
            },
            item: props.template.value
          });

          const init = async (template: Item.Data) => {
            if (!template || !template.AccessibilityQuestionnaireAnswers) return;
            loading.setValue(true);
            const currentQuestions = await Promise.all(
              template.AccessibilityQuestionnaireAnswers.map(q => getQuestionByIdVersion(q.question, q.version) as { data: QuestionModel.Data })
            ).then(result => result.map(r => r.data));
            const latestQuestions = await Promise.all(
              template.AccessibilityQuestionnaireAnswers.map(q => getQuestionById(q.question) as { data: QuestionModel.Data })
            ).then(result => result.map(r => r.data));
            let hasOutDatedQuestion = false;
            questionnaire.setValue(currentQuestions.map((q, i, array) => {
              if (hasOutDatedQuestion) {
                return {
                  question: q,
                  answer: template.AccessibilityQuestionnaireAnswers[i].answer,
                  status: Question_Status.READ_ONLY
                }
              }
              if (latestQuestions[i].Version !== q.Version) {
                console.debug(`latestVersion=${JSON.stringify(latestQuestions[i], null, 2)}`);
                console.debug(`q=${JSON.stringify(q, null, 2)}`);
                if (q.Id === "1") {
                  return {
                    question: q,
                    answer: template.AccessibilityQuestionnaireAnswers[i].answer,
                    status: template.AccessibilityQuestionnaireAnswers[i].answer ?
                      Question_Status.CLOSED :
                      Question_Status.EXPANDED
                  };
                }
                hasOutDatedQuestion = true;
                return {
                  question: q,
                  answer: template.AccessibilityQuestionnaireAnswers[i].answer,
                  status: Question_Status.ARCHIVED
                }
              }
              return {
                question: q,
                answer: template.AccessibilityQuestionnaireAnswers[i].answer,
                status: template.AccessibilityQuestionnaireAnswers[i].answer ?
                  Question_Status.CLOSED :
                  Question_Status.EXPANDED
              };
            }));
            loading.setValue(false);
          }

          const onAnswerQuestion = async (input: { index: number, answer: string | string[] }) => {
            const answerOption = QuestionModel.Data.getReferenceForAnswer(
              questionnaire.value[input.index].question,
              input.answer
            );
            console.debug(`answerOption=${JSON.stringify(answerOption, null, 2)}`)
            if (answerOption.RefType === "QUESTION") {
              const nextQuestion = await getQuestionById(answerOption.RefIdentifier) as { data: QuestionModel.Data };
              console.debug(`nextQuestion=${JSON.stringify(nextQuestion.data, null, 2)}`)
              loading.setValue(true);
              const newQuestionnaire = [
                ...questionnaire.value.slice(0, input.index + 1).map((q, i) => {
                  if (i < input.index) {
                    return { ...q, status: Question_Status.CLOSED };
                  }
                  if (i === input.index) {
                    return { ...q, status: Question_Status.CLOSED, answer: input.answer };
                  }
                }),
                {
                  question: nextQuestion.data,
                  answer: undefined,
                  status: Question_Status.EXPANDED
                }
              ]
              questionnaire.setValue(newQuestionnaire);
              onSaveRunner.submitRun({
                AccessibilityQuestionnaireAnswers: AccessibilityQuestionnaireState.getAccessibilityQuestionnaireAnswers(newQuestionnaire),
                AccessibilityStatus: "NotReviewed"
              });
              loading.setValue(false);
            }

            if (answerOption.RefType === "ACCESSIBILITYSTATUS") {
              const newQuestionnaire = [
                ...questionnaire.value.slice(0, input.index + 1)
                  .map((q, i) => {
                    if (i < input.index) {
                      return { ...q, status: Question_Status.CLOSED };
                    }
                    return { ...q, status: Question_Status.CLOSED, answer: input.answer };
                  })
              ]
              questionnaire.setValue(newQuestionnaire);
              onSaveRunner.submitRun({
                AccessibilityQuestionnaireAnswers: AccessibilityQuestionnaireState.getAccessibilityQuestionnaireAnswers(newQuestionnaire),
                AccessibilityStatus: answerOption.RefIdentifier as "NotReviewed" | "Reviewing" | "Reviewed"
              })
            }
          }

          const onEditQuestion = (index: number) => {
            editModalState.indexOfTargetQuestion.setValue(index);
            editModalState.basicModalState.visible.setValue(true);
          }

          const onRefreshQuestion = (index: number) => {
            refreshModalState.indexOfTargetQuestion.setValue(index);
            refreshModalState.basicModalState.visible.setValue(true);
          }

          const onCancelOperation = (index: number) => {
            editModalState.indexOfTargetQuestion.setValue(-1);
            refreshModalState.indexOfTargetQuestion.setValue(-1);
            questionnaire.setValue(questionnaire.value.map((q, index, array) => {
              if (index === array.length - 1) {
                return { ...q, status: q.answer ? Question_Status.CLOSED : Question_Status.EXPANDED }
              }
              return { ...q, status: Question_Status.CLOSED }
            }))
          }

          return Builder(new AccessibilityQuestionnaireState())
            .init(init)
            .onAnswerQuestion(onAnswerQuestion)
            .onEditQuestion(onEditQuestion)
            .onRefreshQuestion(onRefreshQuestion)
            .onCancelOperation(onCancelOperation)
            .questionnaire(questionnaire)
            .editModalState(editModalState)
            .refreshModalState(refreshModalState)
            .loading(loading)
            .build();
        }
      }

      export class ClassificationState extends BasicContainerState<Item.Data> {
        ECCN: UIModel.FieldState<string>;
        ECCNType: UIModel.FieldState<ECCN_Type>;
        ECCNStatus: UIModel.FieldState<ECCN_STATUS>;
        ECCNLocked: UIModel.FieldState<boolean>;
        reviewStatus: UIModel.FieldState<REVIEW_STATUS>;
        CFR740_17: UIModel.FieldState<string>;
        manualClassificationDetail: UIModel.FieldState<string>;
        itemClassificationDetails: UIModel.FieldState<string>;
        singaporeStrategicGoodsCodes: UIModel.FieldState<string>;
        dualUse: UIModel.FieldState<"Yes" | "No" | "">;
        itar: UIModel.FieldState<string>;
        adacEnrolled: UIModel.FieldState<"Yes" | "No" | "">;
        onManualSetECCN: (ECCN: string) => void;
        confirmECCNPromotionModalState: Modal.ConfirmECCNPromotionModalState;

        static use(props: {
          onSave: (p: Partial<Item.Data>) => Promise<void>,
          onCancelPromoteECCN: () => void,
          onConfirmPromoteECCN: () => void,
          loadingPromoteECCN: boolean,
          template?: UIModel.FieldState<Item.Data>,
        }) {
          const errorMessage = UIModel.FieldState.use<
            Message[]
          >({ initialValue: [] });
          const ECCN = UIModel.FieldState.use({
            initialValue: ""
          });
          const ECCNType = UIModel.FieldState.use<ECCN_Type>({
            initialValue: ECCN_Type.NO_ECCN,
          });
          const ECCNStatus = UIModel.FieldState.use<ECCN_STATUS>({
            initialValue: ECCN_STATUS.PENDING_QUESTIONNAIRE_COMPLETION
          });
          const ECCNLocked = UIModel.FieldState.use<boolean>({
            initialValue: false
          })
          const reviewStatus = UIModel.FieldState.use<REVIEW_STATUS>({
            initialValue: REVIEW_STATUS.NO_STATUS
          });
          const CFR740_17 = UIModel.FieldState.use<string>({
            initialValue: ""
          });
          const manualClassificationDetail = UIModel.FieldState.use({
            initialValue: ""
          });
          const itemClassificationDetails = UIModel.FieldState.use({
            initialValue: ""
          });
          const singaporeStrategicGoodsCodes = UIModel.FieldState.use({
            initialValue: ""
          });
          const dualUse = UIModel.FieldState.use<"Yes" | "No" | "">({
            initialValue: ""
          });
          const itar = UIModel.FieldState.use({
            initialValue: ""
          });
          const adacEnrolled = UIModel.FieldState.use<"Yes" | "No" | "">({
            initialValue: ""
          });
          const confirmECCNPromotionModalState = Modal.ConfirmECCNPromotionModalState.use({
            onCancel: props.onCancelPromoteECCN,
            onConfirm: props.onConfirmPromoteECCN,
            temporaryECCN: props.template.value.TemporaryECCN,
            eccn: props.template.value.ECCN,
            loading: props.loadingPromoteECCN
          })

          const loading = UIModel.FieldState.use({ initialValue: false });
          const status = UIModel.FieldState.use({ initialValue: Status.Default });
          const onSaveRunner = GeneralHooks.useAnyRunner(props.onSave);

          const onSave = async () => {
            loading.setValue(true);
            onSaveRunner.submitRun(
              {
                ECCN: ECCN.value,
                ECCNType: ECCNType.value,
                ECCNStatus: ECCNStatus.value,
                ECCNLocked: ECCNLocked.value,
                ReviewStatus: reviewStatus.value,
                CFR740: CFR740_17.value,
                ManualClassificationDetails: manualClassificationDetail.value,
                ItemClassificationDetails: itemClassificationDetails.value,
                SingaporeStrategicGoodsCodes: singaporeStrategicGoodsCodes.value,
                DualUse: dualUse.value,
                ITAR: itar.value,
                ADACEnrolled: adacEnrolled.value
              }
            )
          }

          useEffect(() => {
            if (onSaveRunner.status === "Running" || onSaveRunner.status === "New") {
              return;
            }
            loading.setValue(false);
            if (errorMessage.value.length > 0) {
              return;
            }
            status.setValue(Status.Default);
          }, [onSaveRunner.status])

          useEffect(() => {
            if (props.template) {
              init(props.template.value);
            }
          }, []);

          useEffect(() => {
            if (ECCNStatus.value === ECCN_STATUS.ACTIVE) {
              reviewStatus.setValue(REVIEW_STATUS.NO_STATUS);
            }
            if (
              props.template.value.ECCNStatus !== ECCN_STATUS.PENDING_EXPORT_COMPLIANCE_REVIEW &&
              ECCNStatus.value === ECCN_STATUS.PENDING_EXPORT_COMPLIANCE_REVIEW
            ) {
              // Automatically update review status to "Pending" 
              // when eccn status changes "Pending Export Compliance review"
              reviewStatus.setValue(REVIEW_STATUS.PENDING);
            }
          }, [ECCNStatus.value]);



          const onManualSetECCN = (newECCN: string) => {
            ECCN.setValue(newECCN);
            if (newECCN) {
              ECCNType.setValue(ECCN_Type.MANUAL_ASSIGNED);
              ECCNStatus.setValue(ECCN_STATUS.ACTIVE);
            } else {
              ECCNType.setValue(ECCN_Type.NO_ECCN);
              ECCNStatus.setValue(ECCN_STATUS.PENDING_EXPORT_COMPLIANCE_REVIEW);
            }
          }

          const init = (template: Item.Data) => {
            ECCN.setValue(template.ECCN);
            ECCNType.setValue(template.ECCNType);
            ECCNStatus.setValue(template.ECCNStatus);
            ECCNLocked.setValue(template.ECCNLocked)
            reviewStatus.setValue(template.ReviewStatus);
            CFR740_17.setValue(template.CFR740);
            manualClassificationDetail.setValue(template.ManualClassificationDetails);
            itemClassificationDetails.setValue(template.ItemClassificationDetails);
            singaporeStrategicGoodsCodes.setValue(template.SingaporeStrategicGoodsCodes);
            dualUse.setValue(template.DualUse);
            itar.setValue(template.ITAR);
            adacEnrolled.setValue(template.ADACEnrolled);
          }

          const onCancel = () => {
            init(props.template.value);
            errorMessage.setValue([]);
            status.setValue(Status.Default);
          }

          return Builder<ClassificationState>(new ClassificationState())
            .ECCN(ECCN)
            .ECCNType(ECCNType)
            .ECCNStatus(ECCNStatus)
            .ECCNLocked(ECCNLocked)
            .reviewStatus(reviewStatus)
            .CFR740_17(CFR740_17)
            .manualClassificationDetail(manualClassificationDetail)
            .itemClassificationDetails(itemClassificationDetails)
            .singaporeStrategicGoodsCodes(singaporeStrategicGoodsCodes)
            .dualUse(dualUse)
            .itar(itar)
            .adacEnrolled(adacEnrolled)
            .onManualSetECCN(onManualSetECCN)
            .loading(loading)
            .status(status)
            .onSave(onSave)
            .onCancel(onCancel)
            .onEdit(() => status.setValue(Status.Edit))
            .init(init)
            .errorMessage(errorMessage)
            .initialItemState(props.template)
            .confirmECCNPromotionModalState(confirmECCNPromotionModalState)
            .build()
        }
      }

      export class ApprovalState extends BasicContainerState<Item.Data>{
        CCATS: UIModel.FieldState<string>;
        CCATSDate: UIModel.FieldState<string>;
        semiAnnualReporting: UIModel.FieldState<string>;
        semiAnnualReportingDate: UIModel.FieldState<string>;
        ECNType: UIModel.FieldState<string>;
        annualReporting: UIModel.FieldState<string>;
        annualReportingDate: UIModel.FieldState<string>;

        static use(props: { onSave: (p: Partial<Item.Data>) => Promise<void>, template?: UIModel.FieldState<Item.Data> }) {
          const errorMessage = UIModel.FieldState.use<
            Message[]
          >({ initialValue: [] });
          const CCATS = UIModel.FieldState.use({
            initialValue: ""
          });
          const CCATSDate = UIModel.FieldState.use({
            initialValue: ""
          });
          const semiAnnualReporting = UIModel.FieldState.use({
            initialValue: ""
          });
          const semiAnnualReportingDate = UIModel.FieldState.use({
            initialValue: ""
          });
          const annualReporting = UIModel.FieldState.use({
            initialValue: ""
          });
          const encType = UIModel.FieldState.use({
            initialValue: ""
          });
          const annualReportingDate = UIModel.FieldState.use({
            initialValue: ""
          });
          const loading = UIModel.FieldState.use({ initialValue: false });
          const status = UIModel.FieldState.use({ initialValue: Status.Default });
          const onSaveRunner = GeneralHooks.useAnyRunner(props.onSave);

          const onSave = async () => {
            loading.setValue(true);
            onSaveRunner.submitRun(
              {
                CCATS: CCATS.value,
                CCATSDate: CCATSDate.value,
                SubjectToSemiAnnualReporting: semiAnnualReporting.value,
                SemiAnnualReportDate: semiAnnualReportingDate.value,
                SubjectToAnnualReporting: annualReporting.value,
                ENCType: annualReporting.value === "Yes" ? encType.value : '',
                AnnualReportDate: annualReportingDate.value
              }
            )
            const isAnnualReportingUpdated = annualReporting.value !== props.template.value.SubjectToAnnualReporting;
            const isAnnualReportingDateUpdated = annualReportingDate.value !== props.template.value.AnnualReportDate;
            const isEncTypeUpdated = encType.value !== props.template.value.ENCType;

            if (isAnnualReportingUpdated || isAnnualReportingDateUpdated || isEncTypeUpdated) {
              try {
                const annualReport = await createAnnualReport({
                  ENCType: annualReporting.value === "Yes" ? encType.value : '',
                  SubjectToAnnualReporting: annualReporting.value,
                  ItemId: props.template.value.Id,
                  AnnualReportDate: annualReportingDate.value,
                });
                console.log('Annual Report created:', annualReport);
              } catch (error) {
                console.error('Error creating Annual Report:', error);
              }
            }
            loading.setValue(false);
          }

          useEffect(() => {
            if (onSaveRunner.status === "Running" || onSaveRunner.status === "New") {
              return;
            }
            loading.setValue(false);
            if (errorMessage.value.length > 0) {
              return;
            }
            status.setValue(Status.Default);
          }, [onSaveRunner.status])

          useEffect(() => {
            if (props.template) {
              init(props.template.value);
            }
          }, []);

          const init = (template: Item.Data) => {
            CCATS.setValue(template.CCATS);
            CCATSDate.setValue(template.CCATSDate);
            semiAnnualReporting.setValue(template.SubjectToSemiAnnualReporting);
            semiAnnualReportingDate.setValue(template.SemiAnnualReportDate);
            annualReporting.setValue(template.SubjectToAnnualReporting);
            encType.setValue(template.ENCType);
            annualReportingDate.setValue(template.AnnualReportDate);
          }

          const onCancel = () => {
            init(props.template.value);
            errorMessage.setValue([]);
            status.setValue(Status.Default);
          }

          return Builder<ApprovalState>(new ApprovalState())
            .CCATS(CCATS)
            .CCATSDate(CCATSDate)
            .semiAnnualReporting(semiAnnualReporting)
            .semiAnnualReportingDate(semiAnnualReportingDate)
            .annualReporting(annualReporting)
            .ECNType(encType)
            .annualReportingDate(annualReportingDate)
            .loading(loading)
            .status(status)
            .onSave(onSave)
            .onCancel(onCancel)
            .onEdit(() => status.setValue(Status.Edit))
            .init(init)
            .errorMessage(errorMessage)
            .initialItemState(props.template)
            .build()
        }
      }
      export class BaselinExemptionState extends BasicContainerState<Item.Data>{
        exemptFromEscalation: UIModel.FieldState<string>;
        exemptFromBaseline: UIModel.FieldState<string>;
        reasonForBaselineExemption: UIModel.FieldState<string>;


        static use(props: { onSave: (p: Partial<Item.Data>) => Promise<void>, template?: UIModel.FieldState<Item.Data> }) {
          const errorMessage = UIModel.FieldState.use<
            Message[]
          >({ initialValue: [] });
          const exemptFromEscalation = UIModel.FieldState.use({
            initialValue: ""
          });
          const exemptFromBaseline = UIModel.FieldState.use({
            initialValue: ""
          });
          const reasonForBaselineExemption = UIModel.FieldState.use({
            initialValue: ""
          });


          const loading = UIModel.FieldState.use({ initialValue: false });
          const status = UIModel.FieldState.use({ initialValue: Status.Default });
          const onSaveRunner = GeneralHooks.useAnyRunner(props.onSave);

          const onSave = async () => {
            loading.setValue(true);
            onSaveRunner.submitRun(
              {
                ExemptFromBaseline: exemptFromBaseline.value,
                ExemptFromEscalations: exemptFromEscalation.value,
                ReasonForBaselineExemption: reasonForBaselineExemption.value,
              }
            )
          }

          useEffect(() => {
            if (onSaveRunner.status === "Running" || onSaveRunner.status === "New") {
              return;
            }
            loading.setValue(false);
            if (errorMessage.value.length > 0) {
              return;
            }
            status.setValue(Status.Default);
          }, [onSaveRunner.status])

          useEffect(() => {
            if (props.template) {
              init(props.template.value);
            }
          }, []);

          const init = (template: Item.Data) => {
            exemptFromBaseline.setValue(template.ExemptFromBaseline);
            exemptFromEscalation.setValue(template.ExemptFromEscalations);
            reasonForBaselineExemption.setValue(template.ReasonForBaselineExemption);
          }

          const onCancel = () => {
            init(props.template.value);
            errorMessage.setValue([]);
            status.setValue(Status.Default);
          }

          return Builder<BaselinExemptionState>(new BaselinExemptionState())
            .exemptFromBaseline(exemptFromBaseline)
            .exemptFromEscalation(exemptFromEscalation)
            .reasonForBaselineExemption(reasonForBaselineExemption)
            .loading(loading)
            .status(status)
            .onSave(onSave)
            .onCancel(onCancel)
            .onEdit(() => status.setValue(Status.Edit))
            .init(init)
            .errorMessage(errorMessage)
            .initialItemState(props.template)
            .build()
        }
      }
      
      export class AdminDocumentState extends BasicContainerState<Item.Data>{
        adminFiles: UIModel.FieldState<File[]>;
        uploadAsset: (files: Array<File>) => void;
        readAsset: (fileName: string) => void;
        deleteAsset: (fileName: string) => void;
        static use(props: { onSave: (p: Partial<Item.Data>) => Promise<void>, template?: UIModel.FieldState<Item.Data> }) {
          const errorMessage = UIModel.FieldState.use<
            Message[]
          >({ initialValue: [] });
          const adminFiles = UIModel.FieldState.use<File[]>({ initialValue: [], });
          const loading = UIModel.FieldState.use({ initialValue: false });
          const status = UIModel.FieldState.use({ initialValue: Status.Default });
          const onSaveRunner = GeneralHooks.useAnyRunner(props.onSave);

          const appState = store.getState();
          let alias = appState.authenticationObj.userDetails ? appState.authenticationObj.userDetails.alias : '';

          const removeFile = (newFiles, fileName: string) => {
            const index = newFiles.findIndex((file) => file.filename === fileName)
            if (index !== -1) {
              newFiles.splice(index, 1)
            }
          }

          const readAsset = (filename: string) => {
            readFile(props.template.value.Id, filename, ADMIN_DOC).then((result) => {
              window.open(result.data, '_blank');
            });
          }

          const deleteAsset = (fileName: string) => {
            loading.setValue(true)
            deleteFileV4(props.template.value.Id, fileName, ADMIN_DOC)
              .then(() => {
                let newFiles = JSON.parse(JSON.stringify(props.template.value.AdminFiles)) || []
                removeFile(newFiles, fileName)
                onSaveRunner.submitRun({ AdminFiles: newFiles });
                adminFiles.setValue(newFiles);
              }).catch((e) => {
                let newErrorMessage = []
                newErrorMessage.push({ type: 'error', message: 'File deletion failed', field: 'banner' })
                errorMessage.setValue(newErrorMessage);
              });
          }

          const uploadAsset = (filesToUpload: Array<File>) => {
            loading.setValue(true)
            uploadItemFile(props.template.value.Id, filesToUpload, ADMIN_DOC)
              .then((result) => {
                const signedURL = result.data;
                if (!signedURL) {
                  loading.setValue(false);
                  let newErrorMessage = []
                  newErrorMessage.push({ type: 'error', message: `There was an error uploading your file`, field: 'banner' })
                  errorMessage.setValue(newErrorMessage)
                } else {
                  axios(signedURL, {
                    method: 'PUT',
                    data: filesToUpload[0],
                  }).then(res => {
                    if (res.status === 200) {
                      let newFiles = JSON.parse(JSON.stringify(props.template.value.AdminFiles)) || []
                      removeFile(newFiles, filesToUpload[0].name)
                      newFiles.push({ filename: filesToUpload[0].name, key: result.data.key, modifiedAt: new Date().toUTCString(), modifiedBy: alias })
                      console.log('new file', newFiles)
                      onSaveRunner.submitRun({ AdminFiles: newFiles });
                      adminFiles.setValue(newFiles);
                    }
                    loading.setValue(false)
                  }).catch((err) => {
                    let newErrorMessage = []
                    newErrorMessage.push({ type: 'error', message: `File upload failed due to unaccepted file type`, field: 'banner' })
                    errorMessage.setValue(newErrorMessage)
                    loading.setValue(false);
                  });
                }
              }).catch((e) => {
                let newErrorMessage = []
                newErrorMessage.push({ type: 'error', message: `File upload failed'`, field: 'banner' })
                errorMessage.setValue(newErrorMessage)
                loading.setValue(false)
              });
          }


          useEffect(() => {
            if (onSaveRunner.status === "Running" || onSaveRunner.status === "New") {
              return;
            }
            loading.setValue(false);
            if (errorMessage.value.length > 0) {
              return;
            }
            status.setValue(Status.Default);
          }, [onSaveRunner.status])

          useEffect(() => {
            if (props.template) {
              init(props.template.value);
            }
          }, []);

          const init = (template: Item.Data) => {
            adminFiles.setValue(template.AdminFiles);
          }

          const onCancel = () => {
            init(props.template.value);
            errorMessage.setValue([]);
            status.setValue(Status.Default);
          }

          return Builder<AdminDocumentState>(new AdminDocumentState())
            .adminFiles(adminFiles)
            .uploadAsset(uploadAsset)
            .readAsset(readAsset)
            .deleteAsset(deleteAsset)
            .loading(loading)
            .status(status)
            .onCancel(onCancel)
            .onEdit(() => status.setValue(Status.Edit))
            .init(init)
            .errorMessage(errorMessage)
            .initialItemState(props.template)
            .build()
        }
      }
      export class CCATsSupplementState extends BasicContainerState<Item.Data>{
        CCATs: UIModel.FieldState<string[]>;

        static use(props: { onSave: (p: Partial<Item.Data>) => Promise<void>, template?: UIModel.FieldState<Item.Data> }) {
          const errorMessage = UIModel.FieldState.use<
            Message[]
          >({ initialValue: [] });
          const CCATs = UIModel.FieldState.use<Item.Data["CCATs"]>({
            initialValue: ["", "", "", "", "", "", "", "", "", ""]
          });

          const loading = UIModel.FieldState.use({ initialValue: false });
          const status = UIModel.FieldState.use({ initialValue: Status.Default });
          const onSaveRunner = GeneralHooks.useAnyRunner(props.onSave);

          const onSave = async () => {
            loading.setValue(true);
            onSaveRunner.submitRun(
              {
                CCATs: CCATs.value
              }
            )
          }

          useEffect(() => {
            if (onSaveRunner.status === "Running" || onSaveRunner.status === "New") {
              return;
            }
            loading.setValue(false);
            if (errorMessage.value.length > 0) {
              return;
            }
            status.setValue(Status.Default);
          }, [onSaveRunner.status])

          useEffect(() => {
            if (props.template) {
              init(props.template.value);
            }
          }, []);

          const init = (template: Item.Data) => {
            CCATs.setValue(template.CCATs.map(s => s ? s : "") as Item.Data["CCATs"]);
          }

          const onCancel = () => {
            init(props.template.value);
            errorMessage.setValue([]);
            status.setValue(Status.Default);
          }

          return Builder<CCATsSupplementState>(new CCATsSupplementState())
            .CCATs(CCATs)
            .loading(loading)
            .status(status)
            .onSave(onSave)
            .onCancel(onCancel)
            .onEdit(() => status.setValue(Status.Edit))
            .init(init)
            .errorMessage(errorMessage)
            .initialItemState(props.template)
            .build()
        }
      }
    }

    export namespace Banner {
      export namespace Head {
        export class State {
          static use(props: { template?: State }) {
            return new State();
          }
        }
      }
    }

    export namespace Modal {

      export class BasicModalState {
        onConfirm: () => void;
        onCancel: () => void;
        visible: UIModel.FieldState<boolean>;
        loading: UIModel.FieldState<boolean>;
        static use(props: {
          onConfirm: () => void;
          onCancel: () => void;
        }) {
          const visible = UIModel.FieldState.use({ initialValue: false });
          const loading = UIModel.FieldState.use({ initialValue: false });
          return Builder<BasicModalState>(new BasicModalState())
            .onCancel(props.onCancel)
            .onConfirm(props.onConfirm)
            .visible(visible)
            .loading(loading)
            .build()
        }
      }

      export class ConfirmECCNPromotionModalState {
        basicModalState: BasicModalState;
        temporaryECCN: string;
        eccn: string;
        loading: boolean
        static use(props: {
          onCancel: () => void,
          onConfirm: () => void,
          temporaryECCN: string,
          eccn: string,
          loading: boolean
        }) {
          const basicModalState = BasicModalState.use({
            onCancel: props.onCancel,
            onConfirm: props.onConfirm
          });
          return Builder(new ConfirmECCNPromotionModalState())
            .basicModalState(basicModalState)
            .temporaryECCN(props.temporaryECCN)
            .eccn(props.eccn)
            .loading(props.loading)
            .build();
        }
      }

      export class EditQuestionModalState {
        indexOfTargetQuestion: UIModel.FieldState<number>;
        basicModalState: BasicModalState;
        static use(props: {
          onCancel: () => void,
          onConfirm: () => void,
        }) {
          const basicModalState = BasicModalState.use({
            onCancel: props.onCancel,
            onConfirm: props.onConfirm
          });
          const indexOfTargetQuestion = UIModel.FieldState.use({ initialValue: -1 });
          return Builder(new EditQuestionModalState())
            .indexOfTargetQuestion(indexOfTargetQuestion)
            .basicModalState(basicModalState)
            .build();
        }
      }

      export class RefreshQuestionModalState {
        itemId: string;
        itemVersion: number;
        indexOfTargetQuestion: UIModel.FieldState<number>;
        basicModalState: BasicModalState;
        static use(props: {
          item: Item.Data,
          onCancel: () => void,
          onConfirm: () => void,
        }) {
          const basicModalState = BasicModalState.use({
            onCancel: props.onCancel,
            onConfirm: props.onConfirm
          })
          const indexOfTargetQuestion = UIModel.FieldState.use({ initialValue: -1 });

          return Builder(new RefreshQuestionModalState())
            .indexOfTargetQuestion(indexOfTargetQuestion)
            .basicModalState(basicModalState)
            .itemId(props.item.Id)
            .itemVersion(props.item.Version)
            .build();
        }
      }

      export class SoftwareDependenciesState {
        amazonDependency: UIModel.FieldState<Item.AmazonDependency[]>;
        thirdPartyDependency: UIModel.FieldState<Item.ThirdPartyDependency[]>;
        basicModalState: BasicModalState;
        init: (template: Item.Data) => void;
        static use(props: {
          template: UIModel.FieldState<Item.Data>,
          onCancel: () => void,
          onConfirm: () => void,
        }) {
          const basicModalState = BasicModalState.use({
            onCancel: props.onCancel,
            onConfirm: props.onConfirm
          });
          const thirdPartyDependency = UIModel.FieldState.use({ initialValue: [], });
          const amazonDependency = UIModel.FieldState.use({ initialValue: [], });

          useEffect(() => {
            if (props.template) {
              init(props.template.value);
            }
          }, []);

          const init = (template: Item.Data) => {
            const deepCopy = JSON.parse(
              JSON.stringify(template)
            ); // Avoid accidentally changing external data 
            amazonDependency.setValue(deepCopy.AmazonDependencies);
            thirdPartyDependency.setValue(deepCopy.ThirdPartyDependencies);
          }

          return Builder(new SoftwareDependenciesState())
            .basicModalState(basicModalState)
            .amazonDependency(amazonDependency)
            .thirdPartyDependency(thirdPartyDependency)
            .init(init)
            .build();
        }
      }

      export class PerformanceState {
        adjustedPeakPerformance: UIModel.FieldState<Item.Data["AdjustedPeakPerformance"]>;
        performanceResult: UIModel.FieldState<string>;
        basicModalState: BasicModalState;
        init: (template: Item.Data) => void;
        static use(props: {
          template: UIModel.FieldState<Item.Data>,
          onCancel: () => void,
          onConfirm: () => void,
        }) {
          const basicModalState = BasicModalState.use({
            onCancel: props.onCancel,
            onConfirm: props.onConfirm
          });
          const adjustedPeakPerformance = UIModel.FieldState.use<Item.Data["AdjustedPeakPerformance"]>({
            initialValue: {
              ProcessorType: "Vector Processor",
              ProcessorsNumber: "",
              FloatingPoint: "",
              Frequency: "",
            }
          });
          const performanceResult = UIModel.FieldState.use({ initialValue: "" });

          const init = (template: Item.Data) => {
            adjustedPeakPerformance.setValue({
              ...adjustedPeakPerformance.value,
              ...template.AdjustedPeakPerformance
            });
          };

          useEffect(() => {

            // Frequency * (# of Processors * # of Floating Point Operations/cycle)
            // * (.9 for Vector Processors or .3 for Non-vector Processors))/100
            performanceResult.setValue(`${(
              parseFloat(adjustedPeakPerformance.value.Frequency) *
              parseFloat(adjustedPeakPerformance.value.ProcessorsNumber) *
              parseFloat(adjustedPeakPerformance.value.FloatingPoint) *
              (adjustedPeakPerformance.value.ProcessorType === "Vector Processor" ? 0.9 : 0.3)
              / 100
            ).toFixed(4)}`);
          }, [adjustedPeakPerformance.value]);

          useEffect(() => {
            if (props.template.value) {
              init(props.template.value);
            }
          }, [])

          return Builder(new PerformanceState())
            .basicModalState(basicModalState)
            .adjustedPeakPerformance(adjustedPeakPerformance)
            .init(init)
            .performanceResult(performanceResult)
            .build();
        }
      }

      export class DeleteState {
        basicModalState: BasicModalState;
        static use(props: {
          onCancel: () => void,
          onConfirm: () => void,
        }) {
          const basicModalState = BasicModalState.use({
            onCancel: props.onCancel,
            onConfirm: props.onConfirm
          })
          return Builder(new DeleteState())
            .basicModalState(basicModalState)
            .build();
        }
      }

      export class DeprecateState {
        basicModalState: BasicModalState;
        static use(props: {
          onCancel: () => void,
          onConfirm: () => void,
        }) {
          const basicModalState = BasicModalState.use({
            onCancel: props.onCancel,
            onConfirm: props.onConfirm
          })
          return Builder(new DeprecateState())
            .basicModalState(basicModalState)
            .build();
        }
      }

      export class CloneState {
        title: UIModel.FieldState<string>;
        codeName: UIModel.FieldState<string>;
        basicModalState: BasicModalState;
        loading: UIModel.FieldState<boolean>;
        init: (template: Item.Data) => void;
        static use(props: {
          onCancel: () => void,
          onConfirm: () => void,
          template: UIModel.FieldState<Item.Data>,
        }) {
          const basicModalState = BasicModalState.use({
            onCancel: props.onCancel,
            onConfirm: props.onConfirm
          });
          const title = UIModel.FieldState.use<string>({ initialValue: "" });
          const codeName = UIModel.FieldState.use<string>({ initialValue: "" });
          const loading = UIModel.FieldState.use({ initialValue: false });
          const init = (template: Item.Data) => {
            title.setValue("");
            codeName.setValue("");
          };

          useEffect(() => {
            if (props.template) {
              init(props.template.value);
            }
          }, []);

          return Builder(new CloneState())
            .basicModalState(basicModalState)
            .title(title)
            .codeName(codeName)
            .loading(loading)
            .init(init)
            .build();
        }
      }

      export class AssignActionState {
        action: UIModel.FieldState<string>;
        assginee: UIModel.FieldState<string>;
        message: UIModel.FieldState<string>;
        basicModalState: BasicModalState;
        loading: UIModel.FieldState<boolean>;
        init: (template: Item.Data) => void;
        contacts: UIModel.FieldState<{ alias: string, name: string }[]>;
        static use(props: {
          onCancel: () => void,
          onConfirm: () => void,
          template: UIModel.FieldState<Item.Data>,
        }) {
          const basicModalState = BasicModalState.use({
            onCancel: props.onCancel,
            onConfirm: props.onConfirm
          });
          const action = UIModel.FieldState.use<string>({ initialValue: "" });
          const assginee = UIModel.FieldState.use<string>({ initialValue: "" });
          const message = UIModel.FieldState.use<string>({ initialValue: "" });
          const loading = UIModel.FieldState.use({ initialValue: false });
          const contacts = UIModel.FieldState.use<{ alias: string, name: string }[]>({ initialValue: [] });
          const init = (template: Item.Data) => {
            action.setValue("");
            assginee.setValue("");
            message.setValue("");
            contacts.setValue([{
              alias: template.Pm,
              name: template.PmName,
            }, {
              alias: template.BusinessLineAttorney,
              name: template.BusinessLineAttorneyName,
            },
            ...template.OtherContributors.map(
              (contributor) => {
                return {
                  alias: contributor.Alias,
                  name: contributor.Name
                }
              })
            ])
          };

          useEffect(() => {
            if (props.template) {
              init(props.template.value);
            }
          }, []);

          return Builder(new AssignActionState())
            .basicModalState(basicModalState)
            .action(action)
            .assginee(assginee)
            .message(message)
            .loading(loading)
            .contacts(contacts)
            .init(init)
            .build();
        }
      }
    }
  }
}