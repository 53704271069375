
import * as React from 'react'
import { useState } from 'react'
import { Type } from './QuestionnairePane.constants'
import { infoPane } from './QuestionnairePane.InfoPane'
import { termPane } from './technicalTerm.helpPane'
import { Terms } from './technicalTerm'
import { getKeywordById } from '../../../../actions/async.action'
const QuestionnairePane = (setPane, setHide, setOpen) => {
    const [currentTerms, setCurrentTerms] = useState([])

    const reducer = async (load) => {
        const { type, payload } = load;
        switch (type) {
            case Type.Info:
                setHide(false);
                setOpen(true);
                setPane(infoPane(currentTerms))
                break;

            case Type.Term:
                setHide(false);
                setOpen(true);
                const response = await getKeywordById(payload)
                setPane(termPane(response.data.Name, response.data.Description))
                break;

            case Type.UpdateTerms:
                if(JSON.stringify(currentTerms) === JSON.stringify(payload)) return;
                setCurrentTerms(payload)
                break;
            default:
                break;
        }
    }

    return {
        reducer,
        currentTerms
    }
}

export default QuestionnairePane