import { apiGatewayCogntioClient } from "../utils/apigatewayHelper";

export const Get_Help = "Get_Help";

import { store } from "../main";

export function checkAdminStatus() {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };

    return apiClient.invokeApi({}, '/api/employee/is-group-member?groupName=cript-leadership', "GET", headers);
}

export function checkIsALTAR() {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;
    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };

    return apiClient.invokeApi({}, '/api/employee/is-group-member?groupName=altar', "GET", headers);
}