import * as React from 'react';
import { BreadcrumbGroup, Button, Flashbar, FlashbarProps, SpaceBetween, Tabs, Badge, Spinner, Link } from "@amzn/awsui-components-react-v3"
import { Pane } from '../components/shared/HelpPane/HelpPane.constants'
import { Type } from '../components/shared/HelpPane/questionnairePane/QuestionnairePane.constants'
import HistoryTable from "../components/item-history-table"
import ActionTable from '../components/ActionTable'
import { ItemModalComps } from "../components/ItemDetailComps/ItemModalComps"
import { ItemTabs } from "../components/ItemDetailComps/ItemTabs"
import { ECCN_STATUS, ItemDetailModel, UserInfoContext } from '../models/item-detail-model';
import { Builder } from 'builder-pattern';
import { Header } from '@amzn/awsui-components-react-v3';
import { Question_Status } from '../models/question-model';
import { ActionModal } from "./action-modal";

const ItemDetail = (props: {
  helpPane,
  match: {
    params: {
      id: string;
    }
  },
  location: {
    state: {
      isNew?: boolean
    }
  }
}) => {
  const itemDetailState = ItemDetailModel.UI.State.use({ id: props.match.params.id });
  const [activeId, setActiveId] = React.useState("details");
  const [showAssignedActionsModal, setShowAssignedActionsModal] = React.useState(false);
  const tabs = [
    {
      "label": "Overview",
      "id": "details",
      "content": <ItemTabs.Detail {...itemDetailState} />
    },
    {
      "label": "Questionnaire",
      "id": "questionnaire",
      "content": <ItemTabs.Questionnaire {...itemDetailState} />
    },
    {
      "label": "History",
      "id": "history",
      "content": <HistoryTable itemId={itemDetailState.itemState.value.Id} helpPane={props.helpPane} />,
    },
    {
      "label": "Supplements",
      "id": "supplements",
      "content": <ItemTabs.Supplement {...itemDetailState} />,
    },
    {
      "label": "Assigned actions",
      "id": "Assigned actions",
      "content": <ActionTable itemId={itemDetailState.itemState.value.Id} />,
    },
  ]

  const noCCATsTabs = [
    {
      "label": "Overview",
      "id": "details",
      "content": <ItemTabs.Detail {...itemDetailState} />,
    },
    {
      "label": "Questionnaire",
      "id": "questionnaire",
      "content": <ItemTabs.Questionnaire {...itemDetailState} />
    },
    {
      "label": "History",
      "id": "history",
      "content": <HistoryTable itemId={itemDetailState.itemState.value.Id} helpPane={props.helpPane} />,
    },
    {
      "label": "Assigned actions",
      "id": "Assigned actions",
      "content": <ActionTable itemId={itemDetailState.itemState.value.Id} />,
    },
  ]

  const notAuthorizedUserTabs = [
    {
      "label": "Overview",
      "id": "details",
      "content": <ItemTabs.Detail {...itemDetailState} />
    },
  ]

  const notValidResultTabs = [
    {
      "label": "Overview",
      "id": "details",
      "content": <ItemTabs.Detail {...itemDetailState} />
    },
  ]

  const deleteButton = (
    <Button
      loading={itemDetailState.loading.value}
      onClick={() => itemDetailState.deleteModalState.basicModalState.visible.setValue(true)}
    >
      Delete
    </Button>
  )

  const cloneButton = (
    <Button
      loading={itemDetailState.loading.value}
      onClick={() => {
        itemDetailState.cloneModalState.basicModalState.visible.setValue(true)}
      }
    >
      Clone
    </Button>
  )

  const deprecateButton = (
    <Button
      loading={itemDetailState.loading.value}
      disabled={itemDetailState.itemState.value.Deprecated}
      onClick={() => itemDetailState.deprecatedState.basicModalState.visible.setValue(true)}
    >
      Deprecate
    </Button>
  );

  const assignActionButton = (
    <Button
      loading={itemDetailState.loading.value}
      onClick={
        () => itemDetailState.assignActionModalState.basicModalState.visible.setValue(true)}>
      Assign action
    </Button>
  )

  const baselineButton = (
    !itemDetailState.baselineMeta.value.autoBaselineExemption &&
    itemDetailState.itemState.value.ExemptFromBaseline !== "Yes" &&
    <Button
      iconName='status-positive'
      variant='primary'
      loading={itemDetailState.loading.value || itemDetailState.onBaselineRunner.status === "Running"}
      disabled={
        !itemDetailState.baselineMeta.value.activeBaselinePeriod ||
        itemDetailState.baselineMeta.value.currentPeriodBaselined ||
        itemDetailState.onBaselineRunner.status === "Finished" ||
        itemDetailState.itemState.value.ECCNStatus === ECCN_STATUS.PENDING_QUESTIONNAIRE_COMPLETION ||
        !!itemDetailState.questionnaireState.questionnaire.value.find((q) => q.status === Question_Status.ARCHIVED) ||
        itemDetailState.itemState.value.CET?.length < 1 ||
        (
          !itemDetailState.itemState.value.SaaSRestrictions.isCNCSoftware ||
          !itemDetailState.itemState.value.SaaSRestrictions.isDesignAndManufacturingSoftware ||
          !itemDetailState.itemState.value.SaaSRestrictions.isEnterpriseManagementSoftware
        )
      }
      onClick={async () => {
        setShowAssignedActionsModal(true)
      }}
    >
      Baseline
    </Button>
  );

  let buttons = <></>;
  let targetTab = notAuthorizedUserTabs
  const deletePermission = (itemDetailState.isContributor.value && itemDetailState.itemState.value.Deletable) || itemDetailState.isAdmin.value
  if (itemDetailState.isContributor.value) {
    targetTab = itemDetailState.itemState.value && itemDetailState.itemState.value.AssignedCCATs ? tabs : noCCATsTabs;
    buttons = (
      <SpaceBetween
        direction="horizontal"
        size="xs"
      >
        {itemDetailState.itemState.value && cloneButton}
        {itemDetailState.itemState.value && deprecateButton}
        {itemDetailState.itemState.value && deletePermission && deleteButton}
        {itemDetailState.itemState.value && assignActionButton}
        {itemDetailState.itemState.value && baselineButton}
      </SpaceBetween>
    )
  }

  if (itemDetailState.isAdmin.value) {
    targetTab = tabs
    buttons = (
      <>
        <SpaceBetween direction="horizontal" size="xs">
          {itemDetailState.itemState.value && cloneButton}
          {itemDetailState.itemState.value && deprecateButton}
          {itemDetailState.itemState.value && deletePermission && deleteButton}
          {itemDetailState.itemState.value && assignActionButton}
          {itemDetailState.itemState.value && baselineButton}
        </SpaceBetween>
      </>
    )
  }

  React.useEffect(() => {
    if (props.location.state && props.location.state.isNew) {
      itemDetailState.flashbarItems.setValue([
        ...itemDetailState.flashbarItems.value,
        Builder<FlashbarProps.MessageDefinition>()
          .type("success")
          .content("New Item created. Click on the Questionnaire tab to complete the questions required to classify your Item.")
          .dismissible(true)
          .build()
      ])
    }
  }, [props.location.state])


  return (<>
    {showAssignedActionsModal && 
      <ActionModal itemId={props.match.params.id}
        onSubmit={() => {
          itemDetailState.onBaselineRunner.submitRun(
            itemDetailState.itemState.value.Id
          )
          setShowAssignedActionsModal(false)
        }}
        onCancel={() => {
          setShowAssignedActionsModal(false)
        }}
      />
    }
    <div style={{ padding: '2rem' }} >
      <UserInfoContext.Provider value={{
        isAdmin: itemDetailState.isAdmin.value,
        isContributor: itemDetailState.isContributor.value
      }}>
        {itemDetailState.loading.value && <Spinner size={"big"} />}
        <Flashbar items={itemDetailState.flashbarItems.value.map((message, index) => {
          return {
            ...message,
            onDismiss: () => {
              const newValue = [...itemDetailState.flashbarItems.value];
              newValue.splice(index, 1)
              itemDetailState.flashbarItems.setValue([
                ...newValue
              ])
            }
          }
        })
        } />
        <BreadcrumbGroup items={itemDetailState.breadcrumb.value}/>
        <ItemModalComps.PerformanceModal state={itemDetailState.performanceModalState} />
        <ItemModalComps.CloneModal state={itemDetailState.cloneModalState} />
        <ItemModalComps.DeprecateModal state={itemDetailState.deprecatedState} />
        <ItemModalComps.AssignActionModal state={itemDetailState.assignActionModalState} />
        <ItemModalComps.DeleteModal state={itemDetailState.deleteModalState} />
        <>
          <Header
            className="align-items-center"
            actions={
              <SpaceBetween
                direction="horizontal"
                size="xs"
              >
                <div >
                  {buttons}
                </div>
              </SpaceBetween>
            }
          >
            <h1 className='badgeTitle cript-page-header'>
              {itemDetailState.itemState.value.ItemFullName}
              {itemDetailState.itemState.value.Deprecated && 
                <span
                  className="awsui-util-pl-xs"
                  style={{ verticalAlign: "text-bottom" }}
                >
                  <Badge>Deprecated</Badge>
                </span>
              }
              <Link
                variant="info"
                className='pad-left-8px'
                href="javascript:void(0);"
                onFollow={() => props.helpPane.dispatch({ pane: Pane.DetailsPane, type: Type.Info, payload: '' })}
              >
                Info
              </Link>
            </h1>
          </Header>
          <Tabs tabs={targetTab} activeTabId={activeId} onChange={(event) => setActiveId(event.detail.activeTabId)}></Tabs>
        </>
      </UserInfoContext.Provider>
    </div>
  </>)
}
export default ItemDetail
