import * as React from 'react';
import { ColumnLayout, Container, ExpandableSection } from '@amzn/awsui-components-react-v3';

import FileUploader from '../shared/FileUploader';

import '../../styles/form.scss';
import '../../styles/table.scss';

const FileAttachments = ({ filesData, uploadFile, readFile, deleteFile, title }: { filesData: File[], uploadFile: Function, readFile: Function, deleteFile: Function, title }) => {
  return (
    <Container
      header={<h2>{title}</h2>}
    >
      <ColumnLayout>
        <FileUploader
          handleChange={() => { }}
          uploadFile={uploadFile}
          readFile={readFile}
          deleteFile={deleteFile}
          fileMetaData={filesData}
        />
      </ColumnLayout>
    </Container>
  )
}

export default FileAttachments