import {Initialize_CognitoAuth, Set_UserInfo} from "../actions/authentication.action";

const intialState={
    authObj:null,
    userDetails:null
};
export default function authenticationObjReducer(state=intialState,action){
    switch (action.type) {
        case Initialize_CognitoAuth :
            return Object.assign({},state,{authObj:action.payload});

        case Set_UserInfo:
            return Object.assign({},state,{userDetails:action.payload});
        default:
            return state;
    }
}