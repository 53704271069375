import { Button, FormField } from "@amzn/awsui-components-react-v3";
import * as React from "react";

import "../../styles/form.scss";
import "../../styles/table.scss";

type ColumnDefiniton = {
  header: string;
  id: string;
  cell: (item: any) => any;
  errorText?: (item: any, items: any[]) => string | undefined;
  width: string;
};

const RepeaterTable = (props: {
  columnDefinitions: ColumnDefiniton[];
  handleChange: (item: any[]) => void;
  field: string;
  items: any[];
}) => {
  const add = () => {
    let newItem = {};
    props.columnDefinitions.forEach((def) => {
      newItem[def.id] = "";
    });
    props.handleChange([...props.items, newItem]);
  };

  const remove = (index) => {
    console.log(index);
    let temp = [...props.items];
    temp.splice(index, 1);
    props.handleChange(temp);
  };

  const handleChange = (event, index, column: ColumnDefiniton) => {
    let temp = props.items.slice();
    temp[index][column.id] = event.target.value;
    props.handleChange(temp);
  };

  return (
    <div>
      <table id={props.field} className="repeater-table">
        <thead>
          <tr>
            {props.columnDefinitions.map((def) => (
              <th
                style={{
                  width: def.width ? def.width : "auto"
                }}
                key={def.header}
              >
                {def.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.items.map((item, i) => (
            <tr key={i}>
              {props.columnDefinitions.map((def) => (
                <td key={`${def.id}${i}`}>
                  <FormField errorText={def.errorText?.(item, props.items)}>
                    <input
                      value={item[def.id] || ""}
                      onChange={(event) => handleChange(event, i, def)}
                    ></input>
                  </FormField>
                </td>
              ))}
              <td>
                <Button
                  data-id="delete"
                  variant="icon"
                  iconName="close"
                  onClick={() => remove(i)}
                  formAction="none"
                ></Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Button
        data-id="add"
        variant="icon"
        iconName="add-plus"
        onClick={add}
        formAction="none"
      ></Button>
    </div>
  );
};

export default RepeaterTable;
