
import * as React from "react";
import { ColumnLayout, Icon } from '@amzn/awsui-components-react-v3';

export default class UnAuthorized extends React.Component {
  constructor() {
    super(null)
  }

  render() {
    const randomNumber = (Math.floor(Math.random() * 43) + 1);

    return (
      <div style={{
          textAlign: 'center',
          backgroundColor: 'white'
      }}>
        <div className="awsui-util-container">
          <ColumnLayout>
            <h1><Icon size="large" variant="error" name="status-warning"></Icon> Hey, you! Stop right there</h1><p>You are not authorized to access the page. You can go to <a href='#/'>Homepage.</a>, or <a href="https://sim.amazon.com" target="_blank">let us know</a> if you need a hand.</p>
          </ColumnLayout>
        </div>

        <a href="https://www.amazon.com/dogsofamazon" target="_blank">
          <img id="not-found-dog" alt="UnAuthorized" src={`https://images-na.ssl-images-amazon.com/images/G/01/error/${randomNumber}._TTD_.jpg"`} />
        </a>
      </div>
    );
  };
}