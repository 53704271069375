import * as React from "react";
export namespace GeneralHooks {

  export class RunnerState<Input, Data, Error> {
    input: Input;
    data: Data;
    err: Error;
    status: "New" | "Running" | "Finished" | "Error";
    submitRun: (input: Input) => void;
    constructor() {
      return {
        input: undefined,
        data: undefined,
        err: undefined,
        status: "New",
        submitRun: (input: Input) => { }
      }
    }
  }
  export function useAnyRunner<Input, Data, Error>(
    func: (p: Input) => Promise<Data>,
  ): RunnerState<Input, Data, Error> {
    const [runnerState, setRunnerState] = React.useState<
      RunnerState<Input, Data, Error>
    >(new RunnerState());

    React.useEffect(() => {
      if (runnerState.status === "Running") {
        func(runnerState.input)
          .then((result) =>
            setRunnerState({ ...runnerState, data: result, status: "Finished" })
          )
          .catch((error) =>
            setRunnerState({ ...runnerState, data: undefined, status: "Error", err: error })
          );
      }
    }, [runnerState]);



    const submitRun = (input: Input) => {
      setRunnerState({ ...runnerState, input: input, status: "Running" });
    };


    return { ...runnerState, submitRun };
  }
}