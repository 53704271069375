import * as React from 'react'
import { useState, useEffect } from 'react'
import { store } from "../main";
import { getPmItems } from '../actions/async.action'
import { Header, Table } from '@amzn/awsui-components-react-v3';

interface PmItem {
  ECCN: string,
  ItemFullName: string,
  Id: string,
}

const columnDefinitions = [
  {
    id: 'Item full name',
    header: 'Item full name',
    cell: (pmItem: PmItem) => <a href={`#/items/${pmItem.Id}`} >{pmItem.ItemFullName}</a>,
    minWidth:"140px"
  },
  {
    id: 'ECCN',
    header: 'ECCN',
    cell: (pmItem: PmItem) => pmItem.ECCN
  },
]

const PmItemsTable = () => {
  const [items, setItems] = useState<PmItem[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  useEffect(() => {
    // get and set items
    const alias = store.getState().authenticationObj.userDetails.alias
    getPmItems(alias).then((res) => {
        setItems(res.data.sort((itemA, itemB) => {
            if (itemA.ItemFullName > itemB.ItemFullName) {
                return 1;
            }
            return -1;
        }))
        setLoading(false)
    }).catch(() => {
        setItems([])
        setLoading(false)
    })
    
  }, [])
  return <Table
    columnDefinitions={columnDefinitions}
    items={items}
    loading={loading}
    loadingText="Loading my Items"
    header={
      <Header variant="h2">
        My Items
      </Header>
    }
    empty={<div className="awsui-util-t-c">
      <b>No Items</b>
    </div>}
  />
}

export default PmItemsTable