import * as React from 'react'
import { useState, useEffect } from 'react'
import { listProcessedAnnualReportByItemId, getItemHistoryVersionsById, getQuestionById, getQuestionByIdVersion } from '../actions/async.action'
import { Button } from '@amzn/awsui-components-react-v3';
import { formatStringToDate } from '../utils/sharedFunctions'
import { Pane, } from './shared/HelpPane/HelpPane.constants';
import { Type } from './shared/HelpPane/versionHistoryPane/historyPane.constants';
import { CollectionTable } from './common/Table/CollectionTable';
import { Column } from './common/Table/Column';
import { ECNItemTypeDescriptor } from './Itemv2/questionOption.constants';

const tableData = (items: any[]): Column<any>[] => {
  return [
    {
      key: "Version",
      columnDefinition: {
        id: 'Version',
        header: 'Version',
        cell: item => <a href={`#/history/${item.Id}/${item.Version}`} target="_blank">{item.Version}</a>
      },
      values: items.map((item) => item.Version),
      propertyFilter: false,
      visibilityEditable: true
    },
    {
      key: "LastUpdatedDate",
      columnDefinition: {
        id: 'LastUpdatedDate',
        header: 'Updated',
        cell: item => formatStringToDate(item.LastUpdatedDate)
      },
      values: items.map((item) => item.LastUpdatedDate),
      propertyFilter: false,
      visibilityEditable: true
    },
    {
      key: "LastUpdatedBy",
      columnDefinition: {
        id: 'LastUpdatedBy',
        header: 'Updated by',
        cell: item => `${item.LastUpdatedBy}`
      },
      values: items.map((item) => item.LastUpdatedBy),
      propertyFilter: true,
      visibilityEditable: true
    },
    {
      key: "ECCN",
      columnDefinition: {
        id: 'ECCN',
        header: 'ECCN',
        cell: item => `${item.ECCN}`
      },
      values: items.map((item) => item.ECCN),
      propertyFilter: true,
      visibilityEditable: true
    },
    {
      key: "ECCN Type",
      columnDefinition: {
        id: 'ECCN type',
        header: 'ECCN type',
        cell: item => `${item.ECCNType}`
      },
      values: items.map((item) => item.ECCNType),
      propertyFilter: true,
      visibilityEditable: true
    },
    {
      key: "Actions",
      columnDefinition: {
        id: 'Actions',
        header: 'Actions',
        cell: item => {
          const messageList = item.DiffMessage.map((messages) => messages.message)
          return (
            <div>
              {messageList.map((message) => {
                return <div>{message}</div>
              })}
            </div>
          )
        }
      },
      values: items.map((item) => item.DiffMessage),
      propertyFilter: false,
      visibilityEditable: true
    },
  ]
}

const  processedAnnualReportTableData = (items: any[]): Column<any>[] => {
  return [
    {
      key: "AnnualReportDate",
      columnDefinition: {
        id: 'AnnualReportDate',
        header: 'Processed Date',
        cell: item => item.AnnualReportDate
      },
      values: items.map((item) => item.AnnualReportDate).sort(),
      propertyFilter: true,
      visibilityEditable: true
    },
    {
      key: "createdBy",
      columnDefinition: {
        id: 'createdBy',
        header: 'Processed By',
        cell: item => `${item.createdBy}`
      },
      values: items.map((item) => item.createdBy),
      propertyFilter: true,
      visibilityEditable: true
    },
    {
      key: "createdAt",
      columnDefinition: {
        id: 'createdAt',
        header: 'Report Date',
        cell: item => item.createdAt
      },
      values: items.map((item) => item.createdAt).sort(),   
      propertyFilter: false,
      visibilityEditable: true
    },
    {
      key: "ENCType",
      columnDefinition: {
        id: 'ENCType',
        header: 'ENC Types',
        cell: item => item.ENCType,      
     },
      values: items.map((item) => item.ENCType),      
      propertyFilter: true,
      visibilityEditable: true
    },
  ];
};

const addQuestionHyperLink = async (items, openQuestionPanel) => {

  // add question cache to speed up question loading
  const questionCache = {}
  for (const item of items) {
    const diffMessage = item.DiffMessage;
    for (const message of diffMessage) {
      if (message.message.includes('Question')) {
        let targetQuestion
        if (questionCache[message.field]) {
          targetQuestion = questionCache[message.field]
        } else {
          const [questionId, version] = message.field.split("#")
          if (version && Number.isInteger(+version)) {
              targetQuestion = await getQuestionByIdVersion(questionId, version)
          } else {
              targetQuestion = await getQuestionById(questionId)
          }
          questionCache[message.field] = { ...targetQuestion }
        }
        const list = message.message.split(':')
        targetQuestion.data.Header = list[0]
        const hyperLinkedQuestion = <a href="javascript:void(0);"
          onClick={() => openQuestionPanel(targetQuestion.data)}>{list[0]}</a>
        message.message = [hyperLinkedQuestion, ':', list[1]]
      }
    }
  }
  return items
}

const HistoryTable = ({ itemId, helpPane }) => {
  const [historyItems, setHistoryItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [processedAnnualReportItems, setProcessedAnnualReportItems] = useState([]);

  useEffect(() => {
    if (itemId) {
      refresh()
    }
  }, [itemId])

  const openQuestionPanel = (question) => {
    helpPane.dispatch({ pane: Pane.HistoryPane, type: Type.QUESTION, payload: question })
  }

  const encItemTypeValueToLabel = (data) => {
    return data.map((item) => {
      const ecnItem = ECNItemTypeDescriptor.find(option => option.value === item.ENCType);
      return {
        ...item,
        ENCType: ecnItem ? ecnItem.label : item.ENCType, 
      };
    });
  };
  
  const refresh = () => {
    setLoading(true)
    getItemHistoryVersionsById(itemId)
      .then(async (res) => {
        let items = res.data.reverse();
        items = await addQuestionHyperLink(items, openQuestionPanel)
        setHistoryItems(items)
        setLoading(false)
      })

      listProcessedAnnualReportByItemId(itemId)
      .then(async (res) => {
        let items = res.data.reverse();
        items = encItemTypeValueToLabel(items);
        setProcessedAnnualReportItems(items);
        setLoading(false);
      })
  }

  return (
    <div style={{ padding: '1.5rem' }}>
      <CollectionTable
        allItems={historyItems}
        tableData={tableData(historyItems)}
        headerText="Item History"
        headerActions={
          <Button onClick={() => refresh()} iconName="refresh" >Refresh</Button>
        }
        loading={loading}
      />
      <div style={{ marginTop: '1rem' }}>
        <CollectionTable
          allItems={processedAnnualReportItems}
          tableData={processedAnnualReportTableData(processedAnnualReportItems)}
          headerText="Processed AnnualReport History"
          headerActions={
            <Button onClick={() => refresh()} iconName="refresh" >Refresh</Button>
          }
          loading={loading}
        />
      </div>
    </div>
  )
}
export default HistoryTable;