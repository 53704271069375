import {
  AttributeEditor,
  AttributeEditorProps,
  Select,
  SelectProps
} from "@amzn/awsui-components-react-v3";
import * as React from "react";
import { EmployeeSuggestions } from "./EmployeeSuggestions";

interface state {
  existingOtherTechnicalContacts: any;
  roles: SelectProps.Option[];
  maxItems: number;
}

export default class AdditionalContacts extends React.Component<
  {
    items: any[];
    removeButtonText?: string;
    addButtonText?: string;
    customOnChange: (items: any[]) => void;
  },
  state
> {
  constructor(props) {
    super(props);

    this.onAddButtonClickHandler = this.onAddButtonClickHandler.bind(this);
    this.onItemButtonClickHandler = this.onItemButtonClickHandler.bind(this);

    this.state = {
      existingOtherTechnicalContacts: [],
      roles: [
        {
          label: "Project Manager",
          value: "Project Manager"
        },
        {
          label: "Technical Project Manager",
          value: "Technical Project Manager"
        },
        {
          label: "Business Line Lawyer",
          value: "Business Line Lawyer"
        },
        {
          label: "Software Development Manager",
          value: "Software Development Manager"
        },
        {
          label: "Engineer",
          value: "Engineer"
        },
        {
          label: "General Manager",
          value: "General Manager"
        },
        {
          label: "Other",
          value: "Other"
        }
      ],
      maxItems: 10
    };
  }

  definitions: AttributeEditorProps.FieldDefinition<any>[] = [
    {
      label: (isVisible) => <span>Contributor</span>,
      errorText: (item, itemIndex) => {
        if (!item.Alias) return "Required";
        return null;
      },
      control: (item, index) => {
        return (
          <EmployeeSuggestions
            key={item.Alias}
            value={item.Alias}
            onSelect={(selected) => {
              this.updateAdditionalContacts(
                item,
                { Alias: selected.alias, Name: selected.name },
                index
              );
            }}
          />
        );
      }
    },
    {
      label: () => <span>Role</span>,
      errorText: (item, itemIndex) => {
        if (!item.Role) return "Required";
        return null;
      },
      control: (item, index) => {
        return (
          <Select
            placeholder="Select"
            options={this.state.roles}
            selectedOption={this.state.roles.find((e) => e.value === item.Role)}
            filteringType="auto"
            ariaRequired={true}
            onChange={(event) => {
              this.updateAdditionalContacts(
                item,
                { Role: event.detail.selectedOption.value },
                index
              );
            }}
          ></Select>
        );
      }
    }
  ];

  updateAdditionalContacts(item, newInfo, index) {
    const items = this.props.items.slice(0);
    const updatedItem = { ...item, ...newInfo };
    items.splice(index, 1, updatedItem);

    this.updateAdditionalContactsState(items);
  }

  updateAdditionalContactsState(items) {
    let updatedContacts = [];

    items.forEach((item) => {
      updatedContacts.push(item);
    });

    const result = [];
    const map = new Map();
    for (const item of updatedContacts) {
      const roleValue = item.Role;
      if (!map.has(`${item.Alias}-${roleValue}`)) {
        map.set(`${item.Alias}-${roleValue}`, true);
        result.push({
          Alias: item.Alias,
          Role: roleValue,
          Name: item.Name
        });
      }
    }
    this.props.customOnChange(result);
  }

  onAddButtonClickHandler() {
    const items = [...this.props.items, {}];
    this.updateAdditionalContactsState(items);
  }

  onItemButtonClickHandler({ detail: { itemIndex } }) {
    const items = this.props.items.slice();
    items.splice(itemIndex, 1);
    this.updateAdditionalContactsState(items);
  }

  getAdditionalInfo() {
    if (this.props.items.length === this.state.maxItems) {
      return `You have reached the limit of ${this.state.maxItems} contributor(s).`;
    }
    return `You can add ${
      this.state.maxItems - this.props.items.length
    } more contributor(s).`;
  }

  render() {
    return (
      <AttributeEditor
        disableAddButton={this.props.items.length === this.state.maxItems}
        additionalInfo={this.getAdditionalInfo()}
        removeButtonText={this.props.removeButtonText || "Remove"}
        addButtonText={this.props.addButtonText || "Add Contributor"}
        empty="No Contributors found"
        definition={this.definitions}
        onAddButtonClick={this.onAddButtonClickHandler}
        onRemoveButtonClick={this.onItemButtonClickHandler}
        items={this.props.items}
      />
    );
  }
}
