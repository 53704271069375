import * as React from 'react'
import { Column } from './../../../components/common/Table/Column';
import { TextLink } from '../../../components/shared/TextLink';
import { TruncatedText } from '../../../components/shared/TruncatedText';
import { formatStringToDate } from '../../../utils/sharedFunctions';
import { getSaasRestrictionsDisplayValue } from "../../../components/ItemDetailComps/ItemContainer"
export const tableData = (items: any[]): Column<any>[] => {

    return [
        {
            key: 'ItemFullName',
            columnDefinition: {
                id: 'ItemFullName',
                header: "Name",
                cell: item => <a href={`#/items/${item.Id}`} > {item.ItemFullName} </a>,
                sortingField: 'ItemFullName',
                width: 365
            },
            values: items.map((item) => item.ItemFullName).sort(),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: "CodeName",
            columnDefinition: {
                id: 'CodeName',
                header: 'Internal code name',
                cell: item => <a href={`#/items/${item.Id}`} > {item.CodeName} </a>,
                sortingField: 'CodeName'
            },
            values: items.map((item) => item.CodeName).sort(),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'Description',
            columnDefinition: {
                id: 'Description',
                header: 'Description',
                cell: item => TruncatedText(item.Description),
            },
            values: items.map((item) => item.Description).sort(),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'OnlineDocumentation',
            columnDefinition: {
                id: 'OnlineDocumentation',
                header: 'Documentation',
                cell: item => TextLink(item.OnlineDocumentation),
            },
            values: items.map((item) => item.OnlineDocumentation).sort(),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'Pm',
            columnDefinition: {
                id: 'Pm',
                header: "Product Manager",
                cell: item => <a href={`https://phonetool.amazon.com/users/${item.Pm}`} target="_blank" > {item.Pm} </a>,
                sortingField: 'Pm'
            },
            values: items.map((item) => item.Pm),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'BusinessLineAttorney',
            columnDefinition: {
                id: 'BusinessLineAttorney',
                header: 'Business line lawyer',
                cell: item => <a href={`https://phonetool.amazon.com/users/${item.BusinessLineAttorney}`} target="_blank" > {item.BusinessLineAttorney} </a>,
                sortingField: 'BusinessLineAttorney'
            },
            values: items.map((item) => item.BusinessLineAttorney),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'ItemType',
            columnDefinition: {
                id: 'ItemType',
                header: 'Item type',
                cell: item => item.ItemType,
                sortingField: 'ItemType'
            },
            values: items.map((item) => item.ItemType).sort(),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'ECCN',
            columnDefinition: {
                id: 'ECCN',
                header: 'ECCN',
                cell: item => item.ECCN || item.ECCNStatus || "Pending questionnaire completion",
                sortingField: 'ECCN'
            },
            values: items.map(item => item.ECCN).sort(),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'ECCNStatus',
            columnDefinition: {
                id: 'ECCNStatus',
                header: 'ECCN status',
                cell: item => item.ECCNStatus || "Pending questionnaire completion",
                sortingField: 'ECCNStatus'
            },
            values: items.map(item => item.ECCNStatus || "Pending questionnaire completion").sort(),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'LastUpdatedDate',
            columnDefinition: {
                id: 'LastUpdatedDate',
                header: 'Last modified',
                cell: item => formatStringToDate(item.LastUpdatedDate),
                sortingField: 'LastUpdatedDate'
            },
            values: items.map(item => item.LastUpdatedDate),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'LastUpdatedBy',
            columnDefinition: {
                id: 'LastUpdatedBy',
                header: 'Last modified by',
                cell: item => item.LastUpdatedBy,
                sortingField: 'LastUpdatedBy'
            },
            values: items.map(item => item.LastUpdatedBy).sort(),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'CreatedDate',
            columnDefinition: {
                id: 'CreatedDate',
                header: 'Created',
                cell: item => formatStringToDate(item.CreatedDate),
                sortingField: 'CreatedDate'
            },
            values: items.map(item => item.CreatedDate),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'CreatedBy',
            columnDefinition: {
                id: 'CreatedBy',
                header: 'Created by',
                cell: item => item.CreatedBy,
                sortingField: 'CreatedBy'
            },
            values: items.map(item => item.CreatedBy).sort(),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'LastBaselinedDate',
            columnDefinition: {
                id: 'LastBaselinedDate',
                header: 'Last baselined',
                cell: item => formatStringToDate(item.LastBaselinedDate),
                sortingField: 'LastBaselinedDate'
            },
            values: items.map(item => item.LastBaselinedDate),
            propertyFilter: true,
            visibilityEditable: true
        },
        {
            key: 'LastBaselinedBy',
            columnDefinition: {
                id: 'LastBaselinedBy',
                header: 'Last baselined by',
                cell: item => item.LastBaselinedBy,
                sortingField: 'LastBaselinedBy'
            },
            values: items.map(item => item.LastBaselinedBy).sort(),
            propertyFilter: true,
            visibilityEditable: true
        },
    ]
}