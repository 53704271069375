import * as React from 'react'
import { useState, useEffect } from 'react'
import { getActionByItemId, updateAction, submitComment } from '../actions/async.action'
import {
  Button,
  FormField,
  SpaceBetween,
  Popover,
  Textarea,
  Flashbar,
  FlashbarProps,
  Modal
} from '@amzn/awsui-components-react-v3';
import { formatStringToDate } from '../utils/sharedFunctions';
import { ACTION_STATUS } from '../models/item-detail-model';
import { GeneralHooks } from "../hooks/general-hooks";
import { UIModel } from '../models/ui-model';
import { Builder } from 'builder-pattern';
import { CollectionTable } from "./common/Table/CollectionTable"
import { Column } from './common/Table/Column';

interface Comment {
  alias: string;
  timestamp: string;
  content: string;
}

const COMMENT_MAX_LENGTH = 200;

const tableData = (items: any[]): Column<any>[] => {
  return [
    {
      key: "Action",
      columnDefinition: {
        id: 'Action',
        header: 'Action',
        cell: actionItem => actionItem.Action
      },
      values: items.map((actionItem) => actionItem.Action),
      propertyFilter: false,
      visibilityEditable: true
    },
    {
      key: "Assignee",
      columnDefinition: {
        id: 'Assignee',
        header: 'Assignee',
        cell: actionItem => <a href={`https://phonetool.amazon.com/users/${actionItem.Assignee}`} target="_blank" > {actionItem.Assignee}</a>
      },
      values: items.map((actionItem) => actionItem.Assignee),
      propertyFilter: false,
      visibilityEditable: true
    },
    {
      key: "AssignedBy",
      columnDefinition: {
        id: 'Assigned by',
        header: 'Assigned by',
        cell: actionItem => <a href={`https://phonetool.amazon.com/users/${actionItem.AssignedBy}`} target="_blank" > {actionItem.AssignedBy}</a>
      },
      values: items.map((actionItem) => actionItem.AssignedBy),
      propertyFilter: false,
      visibilityEditable: true
    },
    {
      key: "Message",
      columnDefinition: {
        id: 'Message',
        header: 'Message',
        cell: actionItem => actionItem.Message
      },
      values: items.map((actionItem) => actionItem.Message),
      propertyFilter: false,
      visibilityEditable: true
    },
    {
      key: "Comments",
      columnDefinition: {
        id: 'Comments',
        header: 'Comments',
        cell: actionItem => makeComments(actionItem.Comments)
      },
      values: items.map((actionItem) => actionItem.Comments),
      propertyFilter: false,
      visibilityEditable: true
    },
    {
      key: "Status",
      columnDefinition: {
        id: 'Status',
        header: 'Status',
        cell: actionItem => actionItem.Status
      },
      values: items.map((actionItem) => actionItem.Status),
      propertyFilter: false,
      visibilityEditable: true
    },
    {
      key: "Assigned",
      columnDefinition: {
        id: 'Assigned',
        header: 'Assigned',
        cell: actionItem => formatStringToDate(actionItem.AssignedDate)
      },
      values: items.map((actionItem) => actionItem.AssignedDate),
      propertyFilter: false,
      visibilityEditable: true
    }
  ]
}

const ActionTable = ({ itemId }) => {
  const [actionItems, setActionItems] = useState([])
  const [workingComment, setWorkingComment] = useState("")

  // canceling or completing
  const [operationing, setOperationing] = useState(false)
  const [showCompleteModal, setShowCompleteModal] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showCommentModal, setShowCommentModal] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])

  const flashbarItems = UIModel.FieldState.use<Array<FlashbarProps.MessageDefinition>>(
    { initialValue: [] }
  );

  const getActionItemsRunner = GeneralHooks.useAnyRunner<string, {data: any[]}, any>(getActionByItemId);
  const updateActionItemRunner = GeneralHooks.useAnyRunner<{action: any, actionId: string}, {data: {actionItem: any}}, any>(updateAction);
  const submitCommentRunner = GeneralHooks.useAnyRunner<{commentString: string; actionId: string}, {data: {actionItem: any}}, any>(submitComment);

  useEffect(() => {
    if (itemId) {
      refresh()
    }
  }, [itemId])


  const refresh = () => {
    getActionItemsRunner.submitRun(itemId)
  }

  const onCancel = async () => {
    setShowCancelModal(false)

    setOperationing(true)
    selectedItems[0].Status = ACTION_STATUS.CANCELED
    updateActionItemRunner.submitRun({
      action: selectedItems[0],
      actionId: selectedItems[0].ActionId
    })
  }

  const onComplete = async () => {
    setShowCompleteModal(false)
    setOperationing(true)
    selectedItems[0].Status = ACTION_STATUS.COMPLETED
    updateActionItemRunner.submitRun({
      action: selectedItems[0],
      actionId: selectedItems[0].ActionId
    })
  }

  const openCompleteModal = () => {
    setShowCompleteModal(true)
  }

  const openCancelModal = () => {
    setShowCancelModal(true)
  }

  const onSubmitComment = async () => {
    setShowCommentModal(false)
    setOperationing(true)
    submitCommentRunner.submitRun({commentString: workingComment, actionId: selectedItems[0].ActionId})
  }

  useEffect(() => {
    if (submitCommentRunner.err) {
      flashbarItems.setValue([
        ...flashbarItems.value,
        Builder<FlashbarProps.MessageDefinition>()
          .type("error")
          .content("Failed to submit comment. Please try again")
          .dismissible(true)
          .build()
      ]);
    } else if (submitCommentRunner.data) {
      setOperationing(false)
      setWorkingComment("")
      refresh()
    }
  }, [submitCommentRunner.data])

  useEffect(() => {
    if (updateActionItemRunner.err) {
      flashbarItems.setValue([
        ...flashbarItems.value,
        Builder<FlashbarProps.MessageDefinition>()
          .type("error")
          .content("Failed to update assigned action. Please try again")
          .dismissible(true)
          .build()
      ]);
    } else if (updateActionItemRunner.data) {
      setOperationing(false)
      refresh()
    }
  }, [updateActionItemRunner.data])

  useEffect(() => {
    if (getActionItemsRunner.err) {
      flashbarItems.setValue([
        ...flashbarItems.value,
        Builder<FlashbarProps.MessageDefinition>()
          .type("error")
          .content("Failed to load assigned actions")
          .dismissible(true)
          .build()
      ]);
    } else if (getActionItemsRunner.data) {
      let items = getActionItemsRunner.data.data.sort((actionA, actionB) => {
        let aDate = new Date(actionA["AssignedDate"]).getTime();
        aDate = isNaN(aDate) ? 0 : aDate;
        let bDate = new Date(actionB["AssignedDate"]).getTime();
        bDate = isNaN(bDate) ? 0 : bDate;
        return bDate - aDate;
      })
      setActionItems(items)
      setOperationing(false)
    }
  }, [getActionItemsRunner.data])

  const onCloseCommentModal = () => {
    setShowCommentModal(false)
    setWorkingComment("")
  }

  return (
    <div style={{ padding: '1.5rem' }}>
      <Modal
        onDismiss={() => setShowCompleteModal(false)}
        visible={showCompleteModal}
        header="Are you sure?"
        size='medium'
        footer={
          <span className="awsui-util-f-r">
            <Button onClick={() => {
              setShowCompleteModal(false)
            }} variant='link'>Cancel</Button>
            <Button onClick={() => {
              onComplete()
            }} variant='primary'>Confirm complete</Button>
          </span>
        }
      >
        <p>This operation cannot be undone.</p>
      </Modal>
      <Modal
        onDismiss={() => setShowCancelModal(false)}
        visible={showCancelModal}
        header="Are you sure?"
        size='medium'
        footer={
          <span className="awsui-util-f-r">
            <Button onClick={() => {
              setShowCancelModal(false)
            }} variant='link'>Cancel</Button>
            <Button onClick={() => {
              onCancel()
            }} variant='primary'>Confirm cancel</Button>
          </span>
        }
      >
        <p>This operation cannot be undone.</p>
      </Modal>
      <Modal
        onDismiss={() => onCloseCommentModal()}
        visible={showCommentModal}
        header="Leave a comment"
        size='medium'
        footer={
          <span className="awsui-util-f-r">
            <Button
              onClick={() => {
                onCloseCommentModal()
              }}
              variant='link'
            >Cancel</Button>
            <Button
              disabled={ 
                workingComment.length > COMMENT_MAX_LENGTH ||
                !workingComment.length
              }
              onClick={() => {
                setShowCommentModal(false)
                onSubmitComment()
              }}
              variant='primary'
            >Submit</Button>
          </span>
        }
      >
        <FormField
          errorText={
            workingComment.length > COMMENT_MAX_LENGTH &&
            `Comment cannot be more than ${COMMENT_MAX_LENGTH} characters`
          }
        >
          <Textarea
            value={workingComment}
            onChange={(e) => setWorkingComment(e.detail.value)}
          />
        </FormField>
      </Modal>
      <Flashbar
        items={flashbarItems.value}
      ></Flashbar>
      <CollectionTable
        allItems={actionItems}
        tableData={tableData(actionItems)}
        headerText="Assigned actions"
        headerActions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              onClick={() => refresh()}
              iconName="refresh"
            >Refresh</Button>
            <Button
              loading={operationing}
              disabled={!!!selectedItems[0] || selectedItems[0].Status !== 'Pending'}
              variant='normal'
              onClick={() => {
                setShowCancelModal(true)
              }}
            >Cancel</Button>
            <Button
              loading={operationing}
              disabled={
                !!!selectedItems[0] ||
                selectedItems[0].Status !== 'Pending'
              }
              variant='normal'
              onClick={() => setShowCommentModal(true)}
            >Comment</Button>
            <Button
              loading={operationing}
              disabled={!!!selectedItems[0] || selectedItems[0].Status !== 'Pending'}
              variant='primary'
              onClick={() => {
                setShowCompleteModal(true)
              }}
            >Complete</Button>
          </SpaceBetween>
        }
        selectionType="single"
        onSelect={(selected) => setSelectedItems(selected)}
        selected={selectedItems}
      />
    </div>
  )
}

const formatComment = (comment: Comment) => {
  return <p>{`${new Date(comment.timestamp).toLocaleString()} - ${comment.alias} commented "${comment.content}"`}</p>
}

const makeComments = (comments: Comment[]) => {
  return (
    <div>
      {comments?.slice(0, 3).map((c) => (formatComment(c)))}
      {comments?.length > 3 && (
        <Popover
          size='large'
          content={comments.map((c) => (formatComment(c)))}
        >
          ... Show all
        </Popover>
      )}
    </div>
  )
}

export default ActionTable;