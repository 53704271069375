import * as React from "react";
import { useEffect } from 'react'
import { getTimezoneIndependentDate } from '../../utils/sharedFunctions';
import { Spinner } from '@amzn/awsui-components-react-v3';
import { BaselinePeriod } from "../../content/Landing";

const timelineData = [
    {
        text: 'Baseline period opens',
        date: '-',
    },
    {
        text: 'Manager escalation',
        date: '-',
    },
    {
        text: 'VP escalation',
        date: '-',
    },
    {
        text: 'Baseline period closes',
        date: '-',
    },
]
const TimelineItem = ({ data }) => (
    <div className="timeline-item">
        <div className="timeline-item-content">
            <p>{data.text}</p>
            <time>{data.date}</time>
            <span className="circle" />
        </div>
    </div>
);

const options = { year: "numeric", month: 'long', day: 'numeric' };

const formatDate = (utcDate: Date) => {
    return new Date(getTimezoneIndependentDate(utcDate)).toLocaleDateString('en-US', options);
}

const Timeline = (props: {
    loading: boolean;
    baselinePeriod?: BaselinePeriod;
}) => {

    useEffect(() => {
        if (props.baselinePeriod) {
            const newDate = { ...timelineData }
            newDate[0].date = formatDate(props.baselinePeriod.StartDate)
            newDate[1].date = formatDate(props.baselinePeriod.EscalationDateManager)
            newDate[2].date = formatDate(props.baselinePeriod.EscalationDateVP)
            newDate[3].date = formatDate(props.baselinePeriod.EndDate)
        }
    }, [props.baselinePeriod]);

    if (props.loading) {
        return <Spinner />
    }

    return (
        timelineData.length > 0 && (
            <div className="timeline-container">
                {timelineData.map((data, idx) => (
                    <TimelineItem data={data} key={idx} />
                ))}
            </div>
        )
    );
}

export default Timeline;


