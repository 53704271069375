import { Type } from './detailsPane.constants'
import { infoPane } from './detailsPane.info'
import { exemptionInfoPane } from './detailsPane.baseline.exemption'
const DetailsPane = (setPane, setHide, setOpen) => {

    const reducer = (load) => {
        const { type } = load;
        switch (type) {
            case Type.Info:

                setHide(false);
                setOpen(true);
                setPane(infoPane())
                break;

            case Type.Init:

                setHide(false);
                setOpen(false);
                setPane(infoPane())
                break;

            case Type.BaselineExemption:

                setHide(false);
                setOpen(true);
                setPane(exemptionInfoPane())
                break;
            default:
                break;
        }
    }

    return {
        reducer,
    }
}

export default DetailsPane