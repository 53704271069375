import * as React from "react";
import {
  Autosuggest,
  Button,
  ColumnLayout,
  ButtonDropdown,
  FormField,
  Input,
  Modal,
  Spinner,
  Textarea
} from '@amzn/awsui-components-react-v3';
import { ItemDetailModel, UserInfoContext } from "../../models/item-detail-model";
import { Item } from "../../models/item-model";
import { ItemContainer } from "./ItemContainer";

export const REQUIRED_MESSAGE = 'Required';

export namespace ItemModalComps {
  export const ConfirmECCNPromotionModal = (props: {
    state: ItemDetailModel.UI.Modal.ConfirmECCNPromotionModalState
  }) => {
    return (
      props.state.basicModalState.visible.value && <Modal
        onDismiss={() => props.state.basicModalState.onCancel()}
        visible={props.state.basicModalState.visible.value}
        header="Are you sure?"
        footer={
          <span className="awsui-util-f-r">
            <Button variant="link" onClick={() => props.state.basicModalState.onCancel()}>
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={props.state.loading}
              onClick={() => {
                props.state.basicModalState.onConfirm()}
              }>
              Yes
            </Button>
          </span>
        }
      >
        Promoting the ECCN will update the Item's ECCN from "{`${props.state.eccn}`}" to "{`${props.state.temporaryECCN}`}"
      </Modal>
    )
  }

  export const EditQuestionModal = (props: {
    state: ItemDetailModel.UI.Modal.EditQuestionModalState
  }) => {
    return (
      props.state.basicModalState.visible.value && <Modal
        onDismiss={() => props.state.basicModalState.onCancel()}
        visible={props.state.basicModalState.visible.value}
        header="Are you sure?"
        footer={
          <span className="awsui-util-f-r">
            <Button variant="link" onClick={() => props.state.basicModalState.onCancel()}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => props.state.basicModalState.onConfirm()}>
              Yes
            </Button>
          </span>
        }
      >
        Editing the answer of this question might discard any subsequent answers and the invalidation of your ECCN, if one is assigned. Click Yes to edit the question.
      </Modal>
    )
  }

  export const EditAccessibilityQuestionModal = (props: {
    state: ItemDetailModel.UI.Modal.EditQuestionModalState
  }) => {
    return (
      props.state.basicModalState.visible.value && <Modal
        onDismiss={() => props.state.basicModalState.onCancel()}
        visible={props.state.basicModalState.visible.value}
        header="Are you sure?"
        footer={
          <span className="awsui-util-f-r">
            <Button variant="link" onClick={() => props.state.basicModalState.onCancel()}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => props.state.basicModalState.onConfirm()}>
              Yes
            </Button>
          </span>
        }
      >
        Editing the answer of this question might discard any subsequent answers. Click Yes to edit the question.
      </Modal>
    )
  }

  export const RefreshQuestionModal = (props: {
    state: ItemDetailModel.UI.Modal.RefreshQuestionModalState
  }) => {
    return (props.state.basicModalState.visible.value && <Modal
      onDismiss={() => props.state.basicModalState.visible.setValue(false)}
      visible={props.state.basicModalState.visible.value}
      header="Refresh question"
      footer={
        <span className="awsui-util-f-r">
          <Button variant="link" onClick={() => props.state.basicModalState.visible.setValue(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => props.state.basicModalState.onConfirm()}>
            Confirm
          </Button>
        </span>
      }
    >
      <p>This question has been updated, and you will need to refresh this question to the newest version. Note that:</p>
      <p>• Once you refresh the question, any subsequent answers will be discarded, and your ECCN, if one is assigned, will be invalidated. </p>
      <p>• After saving the answer for this question, you will need to complete the questionnaire again. </p>
      <p>• <b>A snapshot containing the Item's current questionnaire answers can be found <a href={`#/history/${props.state.itemId}/${props.state.itemVersion}`} target="_blank">here.</a></b>  It may be helpful to consult the snapshot to complete the questionnaire again.</p>
      <p>• You can also view previous answers to any question in the History tab of this Item. </p>
    </Modal>)
  }

  export const RefreshAccessibilityQuestionModal = (props: {
    state: ItemDetailModel.UI.Modal.RefreshQuestionModalState
  }) => {
    return (props.state.basicModalState.visible.value && <Modal
      onDismiss={() => props.state.basicModalState.visible.setValue(false)}
      visible={props.state.basicModalState.visible.value}
      header="Refresh question"
      footer={
        <span className="awsui-util-f-r">
          <Button variant="link" onClick={() => props.state.basicModalState.visible.setValue(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => props.state.basicModalState.onConfirm()}>
            Confirm
          </Button>
        </span>
      }
    >
      <p>This question has been updated, and you will need to refresh this question to the newest version. Note that:</p>
      <p>• Once you refresh the question, any subsequent answers will be discarded. </p>
      <p>• After saving the answer for this question, you will need to complete the accessibility questionnaire again. </p>
      <p>• You can view previous answers to any question in the History tab of this Item. </p>
    </Modal>)
  }

  export const SoftwareDependencyModal = (props: {
    state: ItemDetailModel.UI.Modal.SoftwareDependenciesState
  }) => {
    return (props.state.basicModalState.visible.value &&
      <Modal
        onDismiss={() => props.state.basicModalState.visible.setValue(false)}
        visible={props.state.basicModalState.visible.value}
        header="Software dependencies"
        size='max'
        footer={
          <span className="awsui-util-f-r">
            <Button onClick={() => {
              props.state.basicModalState.onCancel()
            }} variant='link'>Cancel</Button>
            <Button onClick={() => {
              props.state.basicModalState.onConfirm()
            }} variant='primary'>Save</Button>
          </span>
        }
      >
        <h4>Provide information about any Amazon or third-party services or products your Item uses</h4><br />
        <ItemContainer.RelatedAmazonDependenciesTable
          onChange={
            (amazonDependencies: Item.AmazonDependency[]) => props.state.amazonDependency.setValue(amazonDependencies)
          }
          amazonDependencies={props.state.amazonDependency.value} />
        <ItemContainer.ThirdPartyDependenciesTable
          onChange={(
            thirdPartyDependencies: Item.ThirdPartyDependency[]) => props.state.thirdPartyDependency.setValue(thirdPartyDependencies)
          }
          thirdPartyDependencies={props.state.thirdPartyDependency.value} />
      </Modal>
    )
  }

  export const AssignActionModal = (props: {
    state: ItemDetailModel.UI.Modal.AssignActionState
  }) => {
    const userInfoContext = React.useContext(UserInfoContext);

    const adminAction = [
      {
        "text": "Review questionnaire",
        "id": "Review questionnaire",
        "disabled": false
      },
      {
        "text": "Complete CCATs supplement",
        "id": "Complete CCATs supplement",
        "disabled": false
      },
      {
        "text": "Update CCATs supplement",
        "id": "Update CCATs supplement",
        "disabled": false
      },
      {
        "text": "Upload supporting document",
        "id": "Upload supporting document",
        "disabled": false
      },
      {
        "text": "Other",
        "id": "Other",
        "disabled": false
      },
    ];

    const action = [
      {
        "text": "Review questionnaire",
        "id": "Review questionnaire",
        "disabled": false
      },
      {
        "text": "Upload supporting document",
        "id": "Upload supporting document",
        "disabled": false
      },
      {
        "text": "Other",
        "id": "Other",
        "disabled": false
      },
    ];


    return (props.state.basicModalState.visible.value &&
      <Modal
        onDismiss={() => props.state.basicModalState.onCancel()}
        visible={props.state.basicModalState.visible.value}
        header="Assign action"
        size='medium'
        footer={
          <span className="awsui-util-f-r">
            <Button onClick={() => {
              props.state.basicModalState.onCancel()
            }} variant='link'>Cancel</Button>
            <Button onClick={() => {
              props.state.basicModalState.onConfirm()
            }} variant='primary'>Assign</Button>
          </span>
        }
      >
        {props.state.loading.value && <Spinner />}
        <div>
          <ColumnLayout>
            <div>
              <FormField
                errorText={!props.state.action.value ? REQUIRED_MESSAGE : ""}
              >
                <p>Action: </p>
                <ButtonDropdown
                  items={userInfoContext.isAdmin ? adminAction : action}
                  onItemClick={(event) => {
                    props.state.action.setValue(event.detail.id);
                  }}
                >{props.state.action.value ? props.state.action.value : "Select action"}</ButtonDropdown>
              </FormField>
            </div>
            <FormField
              errorText={!props.state.assginee.value ? REQUIRED_MESSAGE : ""}
            >
              <p>Assignee: </p>
              <Autosuggest
                enteredTextLabel={(value) => `Search by ${value}`}
                placeholder="Select one assignee"
                empty="No contacts of the item"
                options={props.state.contacts.value.map((contact) => {
                  return {
                    value: contact.alias,
                    labelTag: contact.name,
                    iconNameUrl: `https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${contact.alias}`,
                    iconNameAlt: contact.alias,
                  }
                })}
                value={props.state.assginee.value}
                onChange={(event) => {
                  props.state.assginee.setValue(event.detail.value);
                }}
              />
            </FormField>
            <div>
              <p>Message: </p>
              <Textarea
                value={props.state.message.value}
                placeholder="Additional message sent to assignee"
                onChange={event => props.state.message.setValue(event.detail.value)}
              />
            </div>
          </ColumnLayout>
        </div>
      </Modal>
    )
  }

  export const CloneModal = (props: {
    state: ItemDetailModel.UI.Modal.CloneState
  }) => {
    return (props.state.basicModalState.visible.value &&
      <Modal
        onDismiss={() => props.state.basicModalState.visible.setValue(false)}
        visible={props.state.basicModalState.visible.value}
        header="Clone Item"
        footer={
          <span className="awsui-util-f-r">
            <Button variant="link" onClick={() => props.state.basicModalState.onCancel()}>
              Cancel
            </Button>
            <Button variant="primary"
              disabled={ props.state.title.value ? false : true}
              onClick={() => props.state.basicModalState.onConfirm()}>
              Clone
            </Button>
          </span > 
        }
      >
        <br />
        <div>
          <ColumnLayout>
            <div>
              <label className="required">New Item Full Name</label>
              <Input
                value={`${props.state.title.value}`}
                onChange={(event) => {
                  props.state.title.setValue(event.detail.value)
                }}></Input>
            </div>
            <div>
              <p>New Internal Code Name</p>
              <Input
                value={`${props.state.codeName.value}`}
                onChange={(event) => {
                  props.state.codeName.setValue(event.detail.value)
                }}></Input>
            </div>
          </ColumnLayout>
        </div>
      </Modal >
    )
  }

  export const DeprecateModal = (props: {
    state: ItemDetailModel.UI.Modal.DeprecateState
  }) => {
    return (props.state.basicModalState.visible.value &&
      <Modal
        onDismiss={() => props.state.basicModalState.visible.setValue(false)}
        visible={props.state.basicModalState.visible.value}
        header="Are you sure?"
        footer={
          <span className="awsui-util-f-r">
            <Button variant="link" onClick={() => props.state.basicModalState.onCancel()}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => props.state.basicModalState.onConfirm()}>
              Yes
            </Button>
          </span >
        }
      >
        Deprecating an item is an irreversible operation.
      </Modal >
    )
  }


  export const DeleteModal = (props: {
    state: ItemDetailModel.UI.Modal.DeleteState
  }) => {
    return (props.state.basicModalState.visible.value &&
      <Modal
        onDismiss={() => props.state.basicModalState.visible.setValue(false)}
        visible={props.state.basicModalState.visible.value}
        header="Are you sure?"
        footer={
          <span className="awsui-util-f-r">
            <Button variant="link" onClick={() => props.state.basicModalState.onCancel()}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => props.state.basicModalState.onConfirm()}>
              Yes
            </Button>
          </span >
        }
      >
        Deleting an item is an irreversible operation.
      </Modal >
    )
  }

  export const PerformanceModal = (props: {
    state: ItemDetailModel.UI.Modal.PerformanceState
  }) => {
    const options = [
      {
        text: "Vector Processor",
        id: "Vector Processor",
        disabled: false,
      },
      {
        text: "Non-vector Processor",
        id: "Non-vector Processor",
        disabled: false,
      }
    ]

    return (props.state.basicModalState.visible.value &&
      <Modal
        onDismiss={() => props.state.basicModalState.visible.setValue(false)}
        visible={props.state.basicModalState.visible.value}
        header="Adjusted peak performance"
        size='medium'
        footer={
          < span className="awsui-util-f-r" >
            <Button onClick={() => {
              props.state.basicModalState.onCancel()
            }} variant='link'>Cancel</Button>
            <Button onClick={() => {
              props.state.basicModalState.onConfirm()
            }} variant='primary'>Save</Button>
          </span >
        }
      >
        <h4>An Adjusted Peak Performance (APP) calculation will be necessary to complete this survey.  To determine your APP, please provide the following details</h4><br />
        <div>
          <ColumnLayout>
            <div>
              <p>Number of processors</p>
              <Input type='number'
                value={`${props.state.adjustedPeakPerformance.value.ProcessorsNumber}`}
                onChange={(event) => {
                  props.state.adjustedPeakPerformance.setValue({
                    ...props.state.adjustedPeakPerformance.value,
                    ProcessorsNumber: event.detail.value
                  })
                }}></Input>
            </div>
            <div>
              <p>What type of processor is this?</p>
              <ButtonDropdown items={options} onItemClick={(event) => {
                props.state.adjustedPeakPerformance.setValue({
                  ...props.state.adjustedPeakPerformance.value,
                  ProcessorType: event.detail.id
                })
              }}>
                {props.state.adjustedPeakPerformance.value.ProcessorType}
              </ButtonDropdown>
            </div>

            <div>
              <p>Number of 64-bit or larger Floating Point Operations performed per cycle</p>
              <Input type='number'
                value={`${props.state.adjustedPeakPerformance.value.FloatingPoint}`}

                onChange={(event) => {
                  props.state.adjustedPeakPerformance.setValue({
                    ...props.state.adjustedPeakPerformance.value,
                    FloatingPoint: event.detail.value
                  })
                }}></Input>
            </div>

            <div>
              <p>Frequency provided in GHz </p>
              <Input type='number'
                value={`${props.state.adjustedPeakPerformance.value.Frequency}`}
                onChange={(event) => {
                  props.state.adjustedPeakPerformance.setValue({
                    ...props.state.adjustedPeakPerformance.value,
                    Frequency: event.detail.value
                  })
                }}></Input>
            </div>
            <div>
              <p>
                Adjusted peak performance: {props.state.performanceResult.value}
              </p>
            </div>
          </ColumnLayout>
        </div>
      </Modal >
    )
  }
}