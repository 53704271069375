import * as React from 'react';
import { useEffect, useState } from 'react';
import { Autosuggest } from '@amzn/awsui-components-react-v3';
import { getAllItem } from '../../actions/async.action';
import { GeneralHooks } from "../../hooks/general-hooks";
import { Item } from '../../models/item-model';

interface ItemAutoSuggestProps {
  onFocus?: () => void;
  onSelect?: (selectedId: string) => void;
}

export const ItemAutoSuggest = (props: ItemAutoSuggestProps) => {
  const [value, setValue] = useState("");
  const [filteredItems, setFiltereditems] = useState([])
  const [statusType, setStatusType] = useState<any>("")  //type workaround

  const onGetAllItemsRunner = GeneralHooks.useAnyRunner<
  {},
  {data: Item.Data[]},
    any
  >(getAllItem);

  const init = () => {
    onGetAllItemsRunner.submitRun({});
  }

  useEffect(() => {
    init();
  }, [])
  

  useEffect(() => {
    if (onGetAllItemsRunner.status === "Running" || onGetAllItemsRunner.status === "New") {
      setStatusType("loading")
      return;
    }
    if (onGetAllItemsRunner.status === "Error") {
      setStatusType("error")
    }
    if (onGetAllItemsRunner.status === "Finished") {
      setStatusType("finished")
      setFiltereditems(onGetAllItemsRunner.data.data)
    }
  }, [onGetAllItemsRunner.status])

  useEffect(() => {
    if (onGetAllItemsRunner.data) {
      setFiltereditems(onGetAllItemsRunner.data.data.filter((item) => {
        return item.ItemFullName.toLowerCase().includes(value)
          || item.CodeName.toLowerCase().includes(value)
          || item.Pm.toLowerCase().includes(value)
          || item.BusinessLineAttorney.toLowerCase().includes(value)
          || item.OtherContributors.find((contact) => contact.Alias.toLowerCase().includes(value))
      }))
    }
  }, [value])

  return <>
    <Autosuggest
      placeholder={"Search"}
      empty="No services found, start typing to search for a service"
      statusType={statusType}
      errorText={"Error fetching Items, try again"}
      loadingText={"Fetching Items"}
      options={filteredItems.map((item) => {
        return { value: item.ItemFullName + ' (' + item.CodeName + ')' }
      })}
      value={value}
      enteredTextLabel={() => value}
      filteringType="manual"
      onFocus={() => {
        if (props.onFocus) {
          props.onFocus();
        }
      }}
      onChange={(event) => {
        setValue(event.detail.value.toLowerCase())
      }}
      onSelect={(event) => {
        let selectedId = onGetAllItemsRunner.data.data.find(item => {
          let CodeNameFromDisplay = event.detail.value.split(' (')[1].split(')')[0];
          return item.CodeName === CodeNameFromDisplay;
        }).Id;
        setValue("")
        if (props.onSelect) {
          props.onSelect(selectedId)
        }
      }}
    ></Autosuggest>
  </>
}