import { Link } from "@amzn/awsui-components-react-v3";
import * as React from "react";

export const TeamLink = (props: { teamName: string}) => {
  if (props.teamName) {
    return (
      <div>
        <Link
          external
          href={`https://permissions.amazon.com/a/team/${props.teamName}`}
        >
          {props.teamName}
        </Link>
      </div>
    )
  } else {
    return null
  }
}