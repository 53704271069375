import * as React from 'react';
import { Button, Flashbar, Spinner, Modal, Checkbox } from "@amzn/awsui-components-react-v3"
import { getActionByItemId, getPendingAssignedActionsForItem } from "../actions/async.action";

export const ActionModal = (props: {
    itemId: string;
    onSubmit: () => void;
    onCancel: () => void;
}) => {
    const [actionMap, setActionMap] = React.useState<Record<string, {text: string; checked: boolean}>>({})
    const [loading, setLoading] = React.useState<boolean>(false)
    const [showFlashBar, setShowFlashBar] = React.useState(false);

    async function init() {
        setLoading(true)
        const actionsResponse = await getActionByItemId(props.itemId) as { data: any[]};
        if (actionsResponse?.data?.length) {
            let actions = {}
            actionsResponse.data.map(a => {
                if (a.Status === 'Pending') {
                    actions[a.ActionId] = {
                        text: a.Message,
                        checked: false
                    }
                }
            })
            setActionMap(actions);
            if (!Object.keys(actions).length) {
                props.onSubmit();
            }
            setLoading(false)
        } else {
            setLoading(false)
            props.onSubmit();
        }
    }
    
    React.useEffect(() => {
        init();
    }, []);

    async function submit() {
        setLoading(true)
        const pendingActions = await getPendingAssignedActionsForItem(props.itemId);
        setLoading(false);
        if (pendingActions?.data?.length) {
            setShowFlashBar(true);
        } else {
            props.onSubmit();
        }
    }

    return <>
        <Modal
            visible={true}
            onDismiss={() => props.onCancel()}
            header="Complete Pending Action Items to Baseline"
            children={<>
                {loading ? (
                    <Spinner />
                ) : (<>
                    {showFlashBar && (
                        <Flashbar 
                            items={[
                                {type: "error",
                                content: `You are required to complete the below action items to successfully baseline this Item.`
                                }
                            ]}
                        />
                    )}
                    {Object.keys(actionMap).map(k  => {
                        return <Checkbox
                            onChange={(e) => setActionMap({
                                ...actionMap,
                                [k]: {
                                    ...actionMap[k],
                                    checked: e.detail.checked
                                }
                            })}
                            checked={(actionMap[k].checked)}>{actionMap[k].text}
                        </Checkbox>
                    })}</>
                )}
            </>}
            footer={<>
                <Button
                    onClick={() => {
                        props.onCancel()
                    }}
                >Cancel</Button>
                <Button
                    variant={'primary'}
                    disabled={loading ||
                        !!Object.keys(actionMap).find(k => actionMap[k].checked === false)
                    }
                    onClick={() => {
                        submit();
                    }}
                >Submit Baseline</Button>
            </>}
        />
    </>
}