import { apiGatewayCogntioClient } from "../utils/apigatewayHelper";
import { store } from "../main";

export enum UserActivityAction {
  SEARCH_HOMEPAGE = "search homepage",
  SEARCH_ALL_ITEMS = "search all items",
  SEARCH_ITEM_COMPLIANCE = "search item compliance",
  OPEN_ITEM_DETAIL = "open item detail"
}

export function saveUserActivity(params: {
  action: UserActivityAction;
  itemId: string;
}) {
    const appState = store.getState();
    const token = appState.authenticationObj.userDetails.jwtToken;

    let apiClient = apiGatewayCogntioClient();
    let headers = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
            Authorization: token
        }
    };
    const userActivityData = {
      alias: appState.authenticationObj.userDetails.alias,
      action: params.action,
      itemId: params.itemId
    };
    return apiClient.invokeApi({}, '/api/userActivity/', "POST", headers, userActivityData);
}


