import * as React from "react";
import App from './App';
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import thunk from 'redux-thunk';
import rootReducer from "../src/reducers";

const storeWithMiddleware = applyMiddleware(thunk)(createStore);
export const store = storeWithMiddleware(rootReducer);

export const Main = () => (
    <div className="awsui">
        <Provider store={store}>
            <App />
        </Provider>
    </div>
)