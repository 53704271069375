import axios from 'axios';

export const getAppPoolConfig = () => {

    if (process.env.STAGE !== "any") {
        return new Promise(function (resolve, reject) {
            const config = require(`../../configuration/stage_config/${process.env.STAGE}/config.json`);
            resolve(config);
        });
    } else {
        // non-localhost stages; i.e., beta, gamma, prod
        const _configPath = `${location.origin}/config/config.json`;
        return new Promise(function (resolve, reject) {
            axios(_configPath).then((res) => {
                console.log('res.data', res.data)
                resolve(res.data);
            });
        });
    }

};