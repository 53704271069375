import * as React from "react";
import { Header, SpaceBetween } from '@amzn/awsui-components-react-v3';

interface Props {
  title?: string;
  counter?: string;
  info?: JSX.Element;
  actionButtons: any;
  description?: string;
}

export class TableHeader extends React.Component<Props, {}> {
  render() {
    return (
      <div>
        <Header variant="h2"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              {this.props.actionButtons}
            </SpaceBetween>
          }
        >
          {this.props.title}{" "}
          {this.props.counter ? (
            <span
              id="custom-action-stripe-counter"
              className="awsui-util-header-counter">
              {this.props.counter}
            </span>
          ) : null}
        </Header>
        <div className="awsui-util-container-header-description">
          {this.props.description}
        </div>
      </div>
    );
  }
}
